import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

// material-ui
import { Typography, Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import useAuth from "../../../hooks/useAuth";
import useScriptRef from "../../../hooks/useScriptRef";
import AnimateButton from "../../../components/@extended/AnimateButton";
import { alertMessage } from "../../pages-helpers/AlertMessage";

export default function ForgotPaswordForm() {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { forgotPassword } = useAuth();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Forgot Password</Typography>
            <Typography component={Link} to={"/login"} variant="body1" sx={{ textDecoration: "none" }} color="primary">
              Back to Login
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              email: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, "Email Invalid")
                .required("Email is required")
                .max(40),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                await forgotPassword(values.email).then(
                  () => {
                    setStatus({ success: true });
                    setSubmitting(false);
                    alertMessage(t("forgotPassword.CheckMailMessage ", "Check mail for reset password link"), "success");

                    setTimeout(() => {
                      navigate("/check-mail-message", { replace: true });
                    }, 1500);
                  },
                  (err) => {
                    alertMessage(t("general.SomethingWentWrong ", "Something went wrong"), "error", err);
                  }
                );
              } catch (err: any) {
                alertMessage(t("general.SomethingWentWrong ", "Something went wrong"), "error", err);
                if (scriptedRef.current) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate={true} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email-forgot">Email Address</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        id="email-forgot"
                        type="email"
                        value={values.email}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        inputProps={{}}
                      />
                    </Stack>
                    {touched.email && errors.email && (
                      <FormHelperText error id="helper-text-email-forgot">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item xs={12} sx={{ mb: -2 }}>
                    <Typography variant="caption">Do not forgot to check SPAM box.</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                        Send Password Reset Email
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}
