import React from "react";
import { Box, Divider, Grid, InputLabel, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FieldArray } from "formik";
import PurchaseOrderStatusChip from "../../components/PurchaseOrderStatusChip";
import { LocationType, UnitOfMeasureType, PurchaseOrderLineType } from "../../AllTypes";
import useTranslation from "../../hooks/useTranslation";
import { useUserPermissions } from "../../utils/userPermissions";
import { formatNo } from "utils/utility";

interface PurchaseOrderSummaryProps {
  values: any;
  location: LocationType | undefined;
  isCentral: boolean;
  unitsOfMeasure: UnitOfMeasureType[];
}

const PurchaseOrderSummary: React.FC<PurchaseOrderSummaryProps> = ({ values, location, isCentral, unitsOfMeasure }) => {
  const { t } = useTranslation();
  const permissions = useUserPermissions();

  return (
    <>
      <Grid item sm={6}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" spacing={0} sx={{ pb: 1 }}>
            <Stack direction="row" spacing={1}>
              <InputLabel>{t("general.company", "Company")}:&nbsp;</InputLabel>
              {location?.companyName}
            </Stack>
            <Stack direction="row" spacing={1}>
              <InputLabel>{t("general.location", "Location")}:&nbsp;</InputLabel>
              {location?.name}
            </Stack>
            <Stack direction="row" spacing={1} sx={{ pt: 1 }} alignItems="center">
              <InputLabel>{t("general.status", "Status")}:</InputLabel>
              <PurchaseOrderStatusChip statusName={values?.statusDisplayName} isCentral={isCentral}></PurchaseOrderStatusChip>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid item container sm={6} spacing={1}>
        <Grid item sm={6}>
          <Stack spacing={1}>
            <InputLabel>{t("purchaseOrder.poNumber", "PO Number")}:</InputLabel>
            {values.purchaseOrderNumber}
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack direction="column" spacing={1}>
            <InputLabel>{t("purchaseOrder.soNumber", "SO Number")}:</InputLabel>
            {values?.salesOrderNumber}
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack spacing={1}>
            <InputLabel>{t("purchaseOrder.shipVia", "Ship Via")}:</InputLabel>
            {values.shipVia}
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack direction="column" spacing={1}>
            <InputLabel>{t("purchaseOrder.collectAccountNumber", "Collect Account #")}:</InputLabel>
            {values?.collectAccountNumber}
          </Stack>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <FieldArray name="lines">
          {() => (
            <>
              <Box
                sx={{
                  overflowX: "auto",
                  border: "1px solid #eee",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <InputLabel>{t("purchaseOrder.componentProduct", "Component / Product")}</InputLabel>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("purchaseOrder.code", "Code")}</InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("purchaseOrder.description", "Description")}</InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("purchaseOrder.quantity", "Quantity")}</InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("purchaseOrder.unit", "Unit")}</InputLabel>
                        </Stack>
                      </TableCell>
                      {permissions.canModifyPOPrices() && (
                        <>
                          <TableCell sx={{ verticalAlign: "top" }}>
                            <Stack spacing={1}>
                              <InputLabel>{t("purchaseOrder.price", "Price")}</InputLabel>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top" }}>
                            <Stack spacing={1}>
                              <InputLabel>{t("purchaseOrder.total", "Total")}</InputLabel>
                            </Stack>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.lines.map((line: PurchaseOrderLineType, index: number) => {
                      let componentType = line.productId
                        ? t("formulation.Product", "Product")
                        : line.componentId
                        ? t("formulation.Component", "Component")
                        : "N/A";
                      let unitName = unitsOfMeasure.find((u) => u.id === line.unitOfMeasureId)?.name;
                      return (
                        <TableRow key={index}>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>{componentType}</TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={1}>{line.code}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={1}>{line.description}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={0}>{line.quantity}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={0}>{unitName}</Stack>
                          </TableCell>
                          {permissions.canModifyPOPrices() && (
                            <>
                              <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "right" }}>
                                {line.unitaryPrice && <Stack spacing={0}>${formatNo(line.unitaryPrice, 2, 2)}</Stack>}
                              </TableCell>
                              <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "right" }}>
                                {line.unitaryPrice && line.quantity && (
                                  <Stack spacing={0}>${formatNo(line.unitaryPrice * line.quantity, 2, 2)}</Stack>
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item sm={6}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="locationId">{t("purchaseOrder.billingAddress", "Billing Address")}:</InputLabel>
                    {/* render break lines */}
                    {values.billingAddress.split("\n").map((line: string, index: number) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <Stack spacing={1}>
                    <InputLabel>{t("purchaseOrder.shippingAddress", "Shipping Address")}:</InputLabel>
                    {/* render break lines */}
                    {values.shippingAddress.split("\n").map((line: string, index: number) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="notes">{t("purchaseOrder.notes", "Notes")}:</InputLabel>
                    {values.notes}
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </FieldArray>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default PurchaseOrderSummary;
