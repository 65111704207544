import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { formatDate } from "../../utils/formatDate";
import useTranslation from "../../hooks/useTranslation";
import DisplayTable from "../../components/DisplayTable";
import { ColumnDef } from "@tanstack/react-table";

interface Props {
  closeModal: (modified?: boolean, skipConfirmClose?: boolean) => void;
  userActionType: "formula" | "weight" | "stockRemoval";
  userActionDetails?: {
    dateStart: string;
    dateEnd: string;
    companyId: string;
    locationId: string;
    formulaId?: number;
    componentId?: number;
    weightRange?: string;
  };
}

type UserActionRecordType = {
  dateTime: Date;
  formulaName?: string;
  formulaId?: number;
  seriesName?: string;
  seriesId?: number;
  weight?: string;
  userId: number;
  userName: string;
  usedWeight?: number;
  estimatedDeductedWeight?: number;
};

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

const UserActionDetailsModal: React.FC<Props> = ({ closeModal, userActionType, userActionDetails }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formulaActionRecords, setFormulaActionRecords] = useState<UserActionRecordType[]>();
  const [weightActionRecords, setWeightActionRecords] = useState<UserActionRecordType[]>();
  const [stockRemovalActionRecords, setStockRemovalActionRecords] = useState<UserActionRecordType[]>();
  useEffect(() => {
    setLoading(true);

    var urlParams = `x=1`;
    if (userActionDetails && userActionDetails?.companyId !== "0") {
      urlParams += `&companyId=${userActionDetails.companyId}`;
    }
    if (userActionDetails && userActionDetails?.locationId !== "0") {
      urlParams += `&locationId=${userActionDetails.locationId}`;
    }
    if (userActionDetails && userActionDetails?.dateStart) {
      urlParams += `&startDate=${userActionDetails.dateStart}`;
    }
    if (userActionDetails && userActionDetails?.dateEnd) {
      urlParams += `&endDate=${userActionDetails.dateEnd}`;
    }
    if (userActionDetails && userActionDetails?.formulaId) {
      urlParams += `&formulaId=${userActionDetails.formulaId}`;
    }
    if (userActionDetails && userActionDetails?.weightRange) {
      urlParams += `&weightRange=${userActionDetails.weightRange}`;
    }
    if (userActionDetails && userActionDetails?.componentId) {
      urlParams += `&componentId=${userActionDetails.componentId}`;
    }

    let url = "";
    if (userActionType === "formula") {
      url = `/api/userActionRecord/GetSelectedFormulaActionDetails?${urlParams}`;
    } else if (userActionType === "weight") {
      url = `/api/userActionRecord/GetSelectedWeightActionDetails?${urlParams}`;
    } else if (userActionType === "stockRemoval") {
      url = `/api/userActionRecord/GetStockRemovalActionDetails?${urlParams}`;
    } else {
      return;
    }

    axiosServices
      .get(url)
      .then((res) => {
        if (userActionType === "formula") {
          setFormulaActionRecords(res.data);
        } else if (userActionType === "weight") {
          setWeightActionRecords(res.data);
        } else if (userActionType === "stockRemoval") {
          setStockRemovalActionRecords(res.data);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userActionDetails, userActionType]);

  const formulaActionsColumns = useMemo<ColumnDef<UserActionRecordType>[]>(
    () => [
      { header: "Date Time", accessorKey: "dateTime", dataType: "date", accessorFn: (row) => formatDate(row.dateTime, "PPp") },
      { header: "Formula Name", accessorKey: "formulaName", dataType: "text" },
      { header: "Series Name", accessorKey: "seriesName", dataType: "text" },
      { header: "User Name", accessorKey: "userName", dataType: "text" },
    ],
    []
  );

  const weightActionsColumns = useMemo<ColumnDef<UserActionRecordType>[]>(
    () => [
      { header: "Date Time", accessorKey: "dateTime", dataType: "date", accessorFn: (row) => formatDate(row.dateTime, "PPp") },
      { header: "Weight", accessorKey: "weight", dataType: "text" },
      { header: "User Name", accessorKey: "userName", dataType: "text" },
    ],
    []
  );

  const stockRemovalActionsColumns = useMemo<ColumnDef<UserActionRecordType>[]>(
    () => [
      { header: "Date Time", accessorKey: "dateTime", dataType: "date", accessorFn: (row) => formatDate(row.dateTime, "PPp") },
      { header: "Formula Name", accessorKey: "formulaName", dataType: "text" },
      { header: "Used Weight", accessorKey: "usedWeight", dataType: "number", accessorFn: (row) => formatNo(row.usedWeight || 0, 1) },
      { header: "Component Name", accessorKey: "componentName", dataType: "text" },
      {
        header: "Estimated Deducted Weight",
        accessorKey: "estimatedDeductedWeight",
        dataType: "number",
        accessorFn: (row) => formatNo(row.estimatedDeductedWeight || 0, 1),
      },
      { header: "User Name", accessorKey: "userName", dataType: "text" },
    ],
    []
  );

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        maxWidth: "1200px",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" component="span">
            User Action Details
          </Typography>
        </DialogTitle>
        <Tooltip title={t("general.close", "Close")}>
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal()} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent sx={{ py: 0, px: 0 }}>
        <Stack direction="row" spacing={0}>
          <Grid container spacing={2} sx={{ py: 2, px: 4 }}>
            <Grid item xs={12}>
              {loading && <CircularWithPath />}
              {!loading && userActionType === "formula" && formulaActionRecords && (
                <DisplayTable
                  columns={formulaActionsColumns}
                  data={formulaActionRecords}
                  showAddBtn={false}
                  defaultSorting={[{ id: "dateTime", desc: true }]}
                />
              )}
              {!loading && userActionType === "weight" && weightActionRecords && (
                <DisplayTable
                  columns={weightActionsColumns}
                  data={weightActionRecords}
                  showAddBtn={false}
                  defaultSorting={[{ id: "dateTime", desc: true }]}
                />
              )}
              {!loading && userActionType === "stockRemoval" && stockRemovalActionRecords && (
                <DisplayTable
                  columns={stockRemovalActionsColumns}
                  data={stockRemovalActionRecords}
                  showAddBtn={false}
                  defaultSorting={[{ id: "dateTime", desc: true }]}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2.5, px: 4, justifyContent: "space-between" }}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Box>
  );
};

export default UserActionDetailsModal;
