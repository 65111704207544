import { useEffect, useState, SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import useScriptRef from "../../../hooks/useScriptRef";
import IconButton from "../../../components/@extended/IconButton";
import AnimateButton from "../../../components/@extended/AnimateButton";

import { strengthColor, strengthIndicator } from "../../../utils/password-strength";

// types
import { StringColorProps } from "../../../types/password";

// assets
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import { VerifyTokenEndpoints } from "../../../AllLables";
import axios from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import useAuth from "../../../hooks/useAuth";
import useTranslation from "../../../hooks/useTranslation";

export default function ResetPasswordForm({ token }: { token: string }) {
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>();
  const { resetPassword } = useAuth();

  const [level, setLevel] = useState<StringColorProps>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault();
  };

  const redirectToLogin = useCallback(
    (msg: string) => {
      alertMessage(msg, "error");
      setTimeout(() => {
        navigate("/login", { replace: true });
      }, 1500);
    },
    [navigate]
  );

  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const verifyToken = useCallback(
    async (value: string) => {
      try {
        const res = await axios.get(VerifyTokenEndpoints.query(value));
        return res.data as string;
      } catch (err: any) {
        redirectToLogin(err);
      }
    },
    [redirectToLogin]
  );

  useEffect(() => {
    changePassword("");
    if (token) {
      verifyToken(token).then((resEmail) => {
        setEmail(resEmail);
      });
    }
  }, [email, navigate, redirectToLogin, token, verifyToken]);

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required(t("resetPassword.passwordRequired", "Password is required")),
        confirmPassword: Yup.string()
          .required(t("resetPassword.confirmPasswordRequired", "Confirm Password is required"))
          .test(
            "confirmPassword",
            t("resetPassword.passwordsMustMatch", "Both Passwords must match!"),
            (confirmPassword, yup) => yup.parent.password === confirmPassword
          ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPassword(token, values.password).then(
            () => {
              setStatus({ success: true });
              setSubmitting(false);
              alertMessage(t("resetPassword.success", "Password reset successful"), "success");
              navigate("/thank-you-completion", { replace: true });
            },
            (err) => {
              alertMessage(t("general.error", "Something went wrong"), "error", err);
            }
          );
        } catch (err: any) {
          alertMessage(t("general.error", "Something went wrong"), "error");
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {email && (
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="reset-email">{t("general.email", "Email")}</InputLabel>
                  <OutlinedInput fullWidth id="reset-email" type="email" name="reset-email" onBlur={handleBlur} value={email} readOnly={true} />
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="password-reset">{t("resetPassword.password", "Password")}</InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  id="password-reset"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    changePassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color="secondary"
                      >
                        {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={t("resetPassword.enterPassword", "Enter password")}
                />
              </Stack>
              {touched.password && errors.password && (
                <FormHelperText error id="helper-text-password-reset">
                  {errors.password}
                </FormHelperText>
              )}
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box sx={{ bgcolor: level?.color, width: 85, height: 8, borderRadius: "7px" }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="confirm-password-reset">{t("resetPassword.confirmPassword", "Confirm Password")}</InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  id="confirm-password-reset"
                  type="password"
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t("resetPassword.enterConfirmPassword", "Enter confirm password")}
                />
              </Stack>
              {touched.confirmPassword && errors.confirmPassword && (
                <FormHelperText error id="helper-text-confirm-password-reset">
                  {errors.confirmPassword}
                </FormHelperText>
              )}
            </Grid>

            {errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <AnimateButton>
                <Button disableElevation disabled={isSubmitting || !token} fullWidth size="large" type="submit" variant="contained" color="primary">
                  {t("resetPassword.resetPassword", "Reset Password")}
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
