import React, { MouseEvent, useEffect } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

// types

// assets
import { Backdrop, Box, Chip, Fade, Grid, Modal, Stack, Typography, Button, TextField, InputAdornment } from "@mui/material";
import { useMemo, useState } from "react";
import { DeleteFilled, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import AddComponentCategoryModal from "./AddComponentCategoryModal";
import { ComponentCategoryType } from "../../AllTypes";
import DisplayTable from "../../components/DisplayTable";
import MainLayout from "../main-layout";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import ConfirmAction from "../pages-helpers/ConfirmAction";

export default function Ink() {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [componentCategories, setComponentCategories] = useState<ComponentCategoryType[]>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const emptyState: ComponentCategoryType = { id: 0, name: "", isActive: true };
  const [selectedItem, setSelectedItem] = useState<ComponentCategoryType>(emptyState);
  const [itemDelete, setItemDelete] = useState<ComponentCategoryType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  // function afterDelete() {
  //   setDeleteAlert(false);
  //   setTimeout(setDataFunction, 500);
  // }

  function setDataFunction() {
    axiosServices
      .get("/api/componentCategory?includeInactive=true")
      .then((res) => {
        setComponentCategories(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error");
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    maxWidth: "500px",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const columns = useMemo<ColumnDef<ComponentCategoryType>[]>(
    () => [
      {
        header: t("general.name", "Name"),
        accessorKey: "name",
        dataType: "text",
      },
      {
        header: t("general.status", "Status"),
        accessorKey: "isActive",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: (cell) => {
          switch (cell.getValue()) {
            case false:
              return <Chip color="error" label={t("general.inactive", "Inactive")} size="small" variant="light" />;
            case true:
              return <Chip color="success" label={t("general.active", "Active")} size="small" variant="light" />;
            default:
              return <Chip color="info" label={t("general.pending", "Pending")} size="small" variant="light" />;
          }
        },
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t("general.edit", "Edit")}>
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as ComponentCategoryType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("general.delete", "Delete")}>
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as ComponentCategoryType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    [t]
  );

  function handleOpenAndEditing() {
    setSelectedItem(emptyState);
    setIsEditing(false);
    handleOpen();
  }

  return (
    <MainLayout>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddComponentCategoryModal
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  componentCategoryId={selectedItem.id}
                  toggleRefreshData={toggleRefreshData}
                  closeModal={() => setOpen(false)}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>

      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">{t("sidebar.componentCategories", "Component Categories")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing}>
                {t("componentCategory.AddComponentCategory", "Add Component Category")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!componentCategories && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {componentCategories && (
        <Stack direction="column" spacing={1}>
          <TextField
            variant="outlined"
            value={search}
            sx={{ maxWidth: "250px" }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("general.search", "Search...")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <DisplayTable columns={columns} data={componentCategories} search={search} modalToggler={handleOpenAndEditing} showAddBtn={true} />
        </Stack>
      )}

      <ConfirmAction
        title={t("alertItemDelete.deleteAlert", "Delete Alert")}
        confirmMessage={t("alertItemDelete.confirmDeleteComponentCategory", "Are you sure you want to delete this component category?")}
        open={deleteAlert}
        onConfirm={() => {
          axiosServices
            .delete(`/api/componentCategory/${itemDelete?.id}`)
            .then(() => {
              alertMessage(t("alertItemDelete.componentCategoryDeleteSuccess", "Component category deleted successfully."), "success");
              setDeleteAlert(false);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error", err);
            });
        }}
        onClose={() => setDeleteAlert(false)}
        icon={<DeleteFilled></DeleteFilled>}
        color="error"
      />
    </MainLayout>
  );
}
