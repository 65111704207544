// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  TextField,
  InputAdornment,
  Backdrop,
  Box,
  Fade,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
  Chip,
  ButtonGroup,
} from "@mui/material";
import { formatDate } from "../../utils/formatDate";

import SearchOutlined from "@ant-design/icons/SearchOutlined";

import React, { Fragment, useEffect, useMemo, useState } from "react";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { UserProfile } from "../../types/auth";
import { LocationType, SelectCompanyType, PurchaseOrderType, PurchaseOrderStatusType } from "../../AllTypes";
import AddPurchaseOrderModal from "./AddPurchaseOrderModal";
import MainLayout from "../main-layout";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Cancel,
  CancelOutlined,
  Description,
  DescriptionOutlined,
  DetailsOutlined,
  QuestionAnswer,
  QuestionMark,
  ViewListOutlined,
  Warning,
} from "@mui/icons-material";
import ConfirmAction from "../pages-helpers/ConfirmAction";
import { set } from "date-fns";
import { Divider } from "@mui/material";
import PurchaseOrderStatusChip from "../../components/PurchaseOrderStatusChip";
import AtlasModal from "../../components/AtlasModal";
import useTranslation from "../../hooks/useTranslation";

export default function PurchaseOrderCentral() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [refreshData, setRefreshData] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderType>();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(-1);
  const [selectedLocationId, setSelectedLocationId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState(0);
  const [search, setSearch] = useState("");
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [confirmCancelId, setConfirmCancelId] = useState<number | null>(null);

  const [includeApproved, setIncludeApproved] = useState(true);
  const [includeConfirmed, setIncludeConfirmed] = useState(false);
  const [includeCancelled, setIncludeCancelled] = useState(false);

  function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
    return formatter.format(pct);
  }

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  let toggleRefreshData = () => setRefreshData(!refreshData);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId || 0);
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId || 0);
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    if (selectedCompanyId === -1) {
      setLocations([]);
      setSelectedLocationId(-1);
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [selectedCompanyId]);

  useEffect(() => {
    searchPurchaseOrders();
  }, [refreshData]);

  function searchPurchaseOrders() {
    setLoading(true);
    axiosServices
      .get(
        `/api/purchaseOrder?locationId=${selectedLocationId || -1}&companyId=${
          selectedCompanyId || -1
        }&includeApproved=${includeApproved}&includeConfirmed=${includeConfirmed}&includeCancelled=${includeCancelled}&includeOther=false`
      )
      .then((res) => {
        setPurchaseOrders(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  const columns = useMemo<ColumnDef<PurchaseOrderType>[]>(
    () => [
      {
        header: t("general.company", "Company"),
        accessorKey: "locationCompanyName",
        dataType: "text",
      },
      {
        header: t("purchaseOrder.poNumber", "PO Number"),
        accessorKey: "purchaseOrderNumber",
        dataType: "text",
      },
      {
        header: t("general.status", "Status"),
        accessorKey: "statusDisplayName",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <PurchaseOrderStatusChip statusName={row.original.statusDisplayName} isCentral={true}></PurchaseOrderStatusChip>;
        },
      },
      {
        header: t("purchaseOrder.requestDate", "Request Date"),
        accessorKey: "requestDate",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return formatDate(row.original.requestDate, "PP");
        },
      },
      {
        header: t("general.location", "Location"),
        accessorKey: "locationName",
        dataType: "text",
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={t("purchaseOrder.viewPurchaseOrder", "View PO")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddModal(row.original.id);
                  }}
                >
                  <DescriptionOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("purchaseOrder.cancelPurchaseOrder", "Cancel Purchase Order")}>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmCancelId(row.original.id);
                    setShowConfirmAction(true);
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("purchaseOrder.testPdf", "Test Html")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const id = row.original.id;

                    axiosServices
                      .get(`/api/purchaseOrder/${id}/emailTemplate/Approved?pdf=false`)
                      .then((res) => {
                        const html = res.data;
                        // open a new tab with the html
                        const newWindow = window.open("", "_blank");
                        newWindow?.document.write(html);
                      })
                      .catch((err) => {
                        alertMessage("Something went wrong", "error", err);
                      });
                  }}
                >
                  <ViewListOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("purchaseOrder.testPdf", "Test Pdf")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const id = row.original.id;

                    axiosServices
                      .get(`/api/purchaseOrder/${id}/emailTemplate/Approved?pdf=true`)
                      .then((res) => {
                        // download and show pdf with a name like purchase-order-test.pdf
                        const blob = new Blob([res.data], { type: "application/pdf" });
                        const url = URL.createObjectURL(blob);
                        const fileName = `purchase-order-test.pdf`;
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = fileName;
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      })
                      .catch((err) => {
                        alertMessage("Something went wrong", "error", err);
                      });
                  }}
                >
                  <ViewListOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    []
  );

  function openAddModal(purchaseOrderId: number) {
    setPurchaseOrderId(purchaseOrderId);
    setOpenModal(true);
  }

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">Company</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(+e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          <MenuItem key="-1" value="-1">
            All companies
          </MenuItem>
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">Company</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">Location</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(+e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          <MenuItem key="-1" value="-1">
            All locations
          </MenuItem>
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">Location</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  function confirmCloseModal() {
    setShowConfirmCloseModal(true);
  }
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  return (
    <MainLayout>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{t("purchaseOrder.centralTitle", "Purchase Order Central")}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CompanyInput></CompanyInput>
        </Grid>
        <Grid item sm={6} xs={12}>
          <LocationInput></LocationInput>
        </Grid>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
              <Stack direction="row" spacing={1}>
                <Stack spacing={1}>
                  <InputLabel>Status</InputLabel>
                  <ButtonGroup>
                    {/* TODO: create theme for custom color #509bc5 secondary or #95afe8 or #476bba main */}
                    {/* TODO: style with no elevation */}
                    <Button variant={includeApproved ? "contained" : "outlined"} color="primary" onClick={() => setIncludeApproved(!includeApproved)}>
                      Pending
                    </Button>
                    <Button
                      variant={includeConfirmed ? "contained" : "outlined"}
                      color="primary"
                      onClick={() => setIncludeConfirmed(!includeConfirmed)}
                    >
                      Confirmed
                    </Button>
                    <Button
                      variant={includeCancelled ? "contained" : "outlined"}
                      color="primary"
                      onClick={() => setIncludeCancelled(!includeCancelled)}
                    >
                      Cancelled
                    </Button>
                    {/* <Button variant={includeOther ? "contained" : "outlined"} color="primary" onClick={() => setIncludeOther(!includeOther)}>
                      All Other
                    </Button> */}
                  </ButtonGroup>
                </Stack>
              </Stack>
              <Button onClick={searchPurchaseOrders} variant="contained">
                Search
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        {/* <Grid item sm={12}>
          <Stack direction="row" spacing={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={!selectedLocationId}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(true);
              }}
              startIcon={<PlusOutlined />}
            >
              Add Purchase Order
            </Button>
          </Stack>
        </Grid> */}
        {purchaseOrders && (
          <Grid item sm={12}>
            <DisplayTable
              defaultSorting={[{ id: "requestDate", desc: true }]}
              columns={columns}
              data={purchaseOrders}
              search={search}
              showAddBtn={true}
            />
          </Grid>
        )}
      </Grid>
      {loading && !purchaseOrders && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loading && !purchaseOrders && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">Select a location to view purchase orders.</Typography>
        </Box>
      )}
      <AtlasModal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            confirmCloseModal();
          }
        }}
      >
        <AddPurchaseOrderModal
          closeModal={(modified?: boolean, skipConfirmClose?: boolean) => {
            if (modified) {
              toggleRefreshData();
              setOpenModal(false);
            } else if (skipConfirmClose) {
              setOpenModal(false);
            } else {
              confirmCloseModal();
            }
          }}
          purchaseOrderId={purchaseOrderId}
          locationId={purchaseOrderId ? null : selectedLocationId}
          isCentral={true}
        />
      </AtlasModal>
      {/* <Box>
        <Modal
          open={openModal}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              confirmCloseModal();
            }
            // setOpenModal(false);
            // searchPurchaseOrders();
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={openModal}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddPurchaseOrderModal
                  closeModal={(modified?: boolean, skipConfirmClose?: boolean) => {
                    if (modified) {
                      toggleRefreshData();
                      setOpenModal(false);
                    } else if (skipConfirmClose) {
                      setOpenModal(false);
                    } else {
                      confirmCloseModal();
                    }
                  }}
                  purchaseOrderId={purchaseOrderId}
                  locationId={purchaseOrderId ? null : selectedLocationId}
                  isCentral={true}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box> */}

      <ConfirmAction
        title={t("purchaseOrder.cancelPurchaseOrder", "Cancel Purchase Order")}
        confirmMessage={t("purchaseOrder.confirmCancelMessage", "Are you sure you want to cancel this purchase order?")}
        open={showConfirmAction}
        onConfirm={() => {
          axiosServices
            .post(`/api/purchaseOrder/${confirmCancelId}/cancel`)
            .then(() => {
              alertMessage(t("purchaseOrder.cancelSuccess", "Purchase order cancelled successfully."), "success");
              setShowConfirmAction(false);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
            });
        }}
        onClose={() => setShowConfirmAction(false)}
        icon={<Cancel></Cancel>}
        color="error"
      />
      <ConfirmAction
        title={t("general.confirmClose", "Confirm Close")}
        confirmMessage={t("purchaseOrder.confirmClose", "Are you sure you want discard changes and close?")}
        open={showConfirmCloseModal}
        onConfirm={() => {
          setOpenModal(false);
          setShowConfirmCloseModal(false);
        }}
        onClose={() => setShowConfirmCloseModal(false)}
        icon={<Warning></Warning>}
        color="warning"
        confirmButtonText={t("purchaseOrder.discardChanges", "Discard Changes")}
      />
    </MainLayout>
  );
}
