import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import {
  LocationType,
  PurchaseOrderLineType,
  UnitOfMeasureType,
  ProductType,
  PurchaseOrderStatusHistoryRecordType,
  ComponentType,
} from "../../AllTypes";
import HighlightedText from "../../components/HighlightedText";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { useUserPermissions } from "../../utils/userPermissions";
import { AddOutlined, Check, EmailOutlined, Redo, Save, Send, Warning } from "@mui/icons-material";
import { formatDate } from "../../utils/formatDate";
import PurchaseOrderStatusChip from "../../components/PurchaseOrderStatusChip";
import PurchaseOrderSummary from "./PurchaseOrderSummary";
import useTranslation from "../../hooks/useTranslation";
import { formatNo } from "utils/utility";

interface Props {
  closeModal: (modified?: boolean, skipConfirmClose?: boolean) => void;
  purchaseOrderId?: number | null;
  clonePurchaseOrderId?: number | null;
  locationId?: number | null;
  isCentral: boolean;
}

interface ProductComponentOption {
  value: string;
  text: string;
  description: string;
  defaultUnitOfMeasureId?: string;
  type: "component" | "product";
  unitOfMeasureIds?: [number];
}

const ItemSchema = Yup.object().shape({
  quantity: Yup.number().required("Quantity is required").positive("Quantity must be a positive number."),
  unitOfMeasureId: Yup.string().required("Unit of Measure is required"),
  unitaryPrice: Yup.number().min(0, "Price cannot be negative."),
});

const enableAutoSave = false;

const AddPurchaseOrderModal: React.FC<Props> = ({ closeModal, purchaseOrderId, clonePurchaseOrderId, locationId: defaultLocationId, isCentral }) => {
  const { t } = useTranslation();
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [products, setProducts] = useState<ProductComponentOption[]>([]);
  const [components, setComponents] = useState<ProductComponentOption[]>();
  const [location, setLocation] = useState<LocationType>();
  const [pcOptions, setPcOptions] = useState<ProductComponentOption[]>([]);
  const [locations, setLocations] = useState<LocationType[]>();
  const [canApprove, setCanApprove] = useState<boolean>(false);
  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const [submitAction, setSubmitAction] = useState<"" | "autoSave" | "requestApproval" | "directApprove" | "approve" | "reject" | "confirm">("");
  const [action, setAction] = useState<"view" | "requestApproval" | "directApprove" | "approveOrReject" | "confirm">("view");
  const [title, setTitle] = useState("Add Purchase Order");
  const [locationId, setLocationId] = useState<number | null | undefined>(defaultLocationId);
  const [statusHistory, setStatusHistory] = useState<PurchaseOrderStatusHistoryRecordType[]>([]);
  // const [confirmationMode, setConfirmationMode] = useState<boolean>(false);
  const [mode, setMode] = useState<"confirmation" | "edition" | "viewOnly" | null>(null);
  // const [onlyView, setOnlyView] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [saveAction, setSaveAction] = useState<"Not saved" | "Pending save" | "Saving..." | "Saved">("Not saved");

  const permissions = useUserPermissions();
  const [skipConfirmClose, setSkipConfirmClose] = useState(true);

  useEffect(() => {
    console.log({ canApprove: permissions.canApprovePurchaseOrders(), canConfirm: permissions.canConfirmPurchaseOrders() });
    setCanConfirm(permissions.canConfirmPurchaseOrders());
    setCanApprove(permissions.canApprovePurchaseOrders());
  }, [permissions]);

  useEffect(() => {
    if (mode === "edition") {
      setSkipConfirmClose(false);
    }
  }, [mode]);

  useEffect(() => {
    setLoading(true);
    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function getAddress(location: LocationType) {
    // return `${location?.address}${location?.address2 ? "\n" + location.address2 : ""}\n${location?.city}, ${location?.state} ${location?.zip}${
    //   location?.country ? "\n" + location.country : ""
    // }`;
    return `${location?.address}${location?.address2 ? " " + location.address2 : ""}\n${location?.city}, ${location?.state} ${location?.zip}`;
  }

  const fetchComponents = (inputValue: string) => {
    setLoadingComponents(true);

    axiosServices
      // .get(`/api/component?companyId=${location?.companyId}&search=${inputValue}&limit=30`)
      .get(`/api/component?companyId=${location?.companyId}`)
      .then((res) => {
        let options = res.data.map(
          (cc: ComponentType) =>
            ({
              text: cc.name,
              value: cc.id?.toString(),
              description: cc.description,
              type: "component",
              defaultUnitOfMeasureId: cc.defaultUnitOfMeasureId?.toString(),
              unitOfMeasureIds: [...new Set(cc.unitsOfMeasure?.map((u) => u.unitOfMeasureId))],
            } as ProductComponentOption)
        );
        setComponents(options);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoadingComponents(false);
      });

    axiosServices
      // .get(`/api/product?search=${inputValue}&limit=30`)
      .get(`/api/product`)
      .then((res) => {
        let options = res.data.map(
          (cc: ProductType) =>
            ({
              text: cc.code,
              value: cc.id.toString(),
              description: cc.description,
              type: "product",
              defaultUnitOfMeasureId: cc.defaultUnitOfMeasureId?.toString(),
              unitOfMeasureIds: [...new Set(cc.unitsOfMeasure?.map((u) => u.unitOfMeasureId))],
            } as ProductComponentOption)
        );
        setProducts(options);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoadingComponents(false);
      });
  };

  useEffect(() => {
    console.log({ products, components });
    if (!products || !components) {
      return;
    }

    setPcOptions([...components, ...products]);
  }, [products, components]);

  // Handle input change to trigger the fetch
  const handleComponentInputChange = (event: React.SyntheticEvent, value: string) => {
    // fetchComponents(value);
  };

  const initialValues = {
    id: 0,
    billingAddress: "",
    shippingAddress: "",
    billingAddressLocationId: 0,
    shippingAddressLocationId: 0,
    purchaseOrderNumber: "",
    shipVia: "",
    collectAccountNumber: "",
    salesOrderNumber: "",
    status: 1,
    statusName: "Draft",
    statusDisplayName: "Draft",
    // totalPrice: 0,
    locationId: defaultLocationId,
    notes: "",
    lines: [
      {
        id: 0,
        componentId: null,
        productId: null,
        code: "",
        description: "",
        quantity: 0,
        unitOfMeasureId: null,
        unitOfMeasureIds: [],
        unitaryPrice: 0,
      },
    ],
  };

  useEffect(() => {
    if (!locationId) {
      setLocation(undefined);
      return;
    }

    axiosServices
      .get(`/api/Location/${locationId}`)
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, [locationId]);

  useEffect(() => {
    if (!location) {
      return;
    }
    fetchComponents("");
  }, [location]);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      purchaseOrderNumber: Yup.string().required(t("purchaseOrder.poNumberRequired", "PO Number is required")),
      shipVia: Yup.string().required(t("purchaseOrder.shipViaRequired", "Shipment method is required")),
      collectAccountNumber: Yup.string().when("shipVia", {
        is: (shipVia: string) => shipVia === "Fedex Collect",
        then: (schema) => schema.required(t("purchaseOrder.collectAccountNumberRequired", "Collect account number is required")),
        otherwise: (schema) => schema,
      }),
      lines: Yup.array().of(ItemSchema),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log({ onSubmit: submitAction });
      if (submitAction === "autoSave") {
        setSaveAction("Saving...");
        axiosServices
          .post(`/api/purchaseOrder`, values)
          .then((res) => {
            if (!values.id) {
              setFieldValue("id", res.data.id);
              setFieldValue("status", res.data.status);
              setFieldValue("statusName", res.data.statusName);
              setFieldValue("statusDisplayName", res.data.statusDisplayName);
              setFieldValue("createdById", res.data.createdById);
              setFieldValue("dateCreated", res.data.dateCreated);
            }
            setSaveAction("Saved");
          })
          .catch((err) => {
            alertMessage("Something went wrong", "error", err);
          })
          .finally(() => {
            setSubmitting(false);
          });

        return;
      }

      // if (!confirmationMode) {
      if (mode === "edition") {
        setMode("confirmation");
        // setConfirmationMode(true);
        setSubmitting(false);
        return;
      }

      let url = "";
      let successfulMessage = "";
      if (submitAction === "requestApproval") {
        url = `/api/purchaseOrder?submit=true`;
        successfulMessage = t("purchaseOrder.submittedForApproval", "Purchase Order submitted for approval");
      } else if (submitAction === "directApprove") {
        url = `/api/purchaseOrder?submit=true`;
        successfulMessage = t("purchaseOrder.savedAndApproved", "Purchase Order saved and approved");
      } else if (submitAction === "approve") {
        url = `/api/purchaseOrder/${purchaseOrderId}/approve`;
        successfulMessage = t("purchaseOrder.approved", "Purchase Order approved");
      } else if (submitAction === "reject") {
        url = `/api/purchaseOrder/${purchaseOrderId}/reject`;
        successfulMessage = t("purchaseOrder.rejected", "Purchase Order rejected");
      } else if (submitAction === "confirm") {
        url = `/api/purchaseOrder/${purchaseOrderId}/confirm`;
        successfulMessage = t("purchaseOrder.confirmed", "Purchase Order confirmed");
      } else {
        url = `/api/purchaseOrder/`;
        successfulMessage = t("purchaseOrder.modified", "Purchase Order modified successfully");
      }

      axiosServices
        .post(url, values)
        .then((res) => {
          alertMessage(successfulMessage, "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
          setMode("edition");
        })
        .finally(() => {
          setSubmitting(false);
          // setConfirmationMode(false);
        });
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setValues, setFieldValue } = formik;

  function handleSubmitAction(_submitAction: "" | "autoSave" | "requestApproval" | "directApprove" | "approve" | "reject" | "confirm") {
    console.log({ handleSubmitAction: _submitAction });
    if (submitAction === _submitAction) {
      handleSubmit();
    } else {
      setSubmitAction(_submitAction);
    }
  }

  useEffect(() => {
    if (!submitAction) return;
    handleSubmit();
  }, [submitAction, handleSubmit]);

  useEffect(() => {
    if (!location) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${location.companyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);
        if (locations?.length) {
          let id = locations[0].id;
          let address = getAddress(locations[0]);
          setFieldValue(`billingAddressLocationId`, id);
          setFieldValue(`shippingAddressLocationId`, id);
          setFieldValue(`billingAddress`, address);
          setFieldValue(`shippingAddress`, address);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, [location, setLocations, setFieldValue]);

  useEffect(() => {
    if (!purchaseOrderId && !clonePurchaseOrderId) {
      return;
    }

    if (purchaseOrderId && clonePurchaseOrderId) {
      alertMessage("Invalid parameters", "error");
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/purchaseOrder/${purchaseOrderId || clonePurchaseOrderId}`)
      .then((res) => {
        let po = res.data;
        po.lines = po.lines.map((l: any) => {
          return {
            ...l,
            component: l.componentId
              ? { value: l.componentId.toString(), text: l.componentName, description: l.componentDescription }
              : l.productId
              ? { value: l.productId.toString(), text: l.productCode, description: l.productDescription }
              : { value: 0, text: "", description: "" },
            unitOfMeasureIds: [
              ...new Set(
                l.componentId
                  ? l.componentUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
                  : l.productUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
              ),
            ],
          };
        });

        if (clonePurchaseOrderId) {
          po.purchaseOrderNumber = "";
          po.status = 1;
          po.statusName = "Draft";
          po.statusDisplayName = "Draft";
          po.id = 0;
          po.statusHistory = [];
          po.lines = po.lines.map((l: PurchaseOrderLineType) => {
            l.id = 0;
            return l;
          });
        }

        setValues(po);
        setStatusHistory(po.statusHistory);
        setLocationId(po.locationId);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [purchaseOrderId, clonePurchaseOrderId]);

  useEffect(() => {
    if (purchaseOrderId && !values?.id) {
      // PO has not loaded yet
      return;
    }
    console.log({ statusName: values?.statusName, canApprove, canConfirm, isCentral, autoSave });

    if ((values?.statusName === "Draft" || values?.statusName === "Rejected") && !canApprove && !isCentral) {
      setMode("edition");
      setAction("requestApproval");
      setAutoSave(true);
    } else if ((values?.statusName === "Draft" || values?.statusName === "Rejected") && canApprove && !isCentral) {
      setMode("edition");
      setAction("directApprove");
      setAutoSave(true);
    } else if (values?.statusName === "PendingApproval" && canApprove && !isCentral) {
      setMode("viewOnly");
      setAction("approveOrReject");
      setAutoSave(false);
    } else if (values?.statusName === "Approved" && canConfirm && isCentral) {
      setMode("confirmation");
      setAction("confirm");
      setAutoSave(false);
    } else {
      setMode("viewOnly");
      setAction("view");
      setAutoSave(false);
    }

    // TODO: check if this gets fired on ANY change of the PO (values) - it probably should not
  }, [purchaseOrderId, values?.statusName, canApprove, canConfirm, isCentral]);

  useEffect(() => {
    if (!purchaseOrderId) {
      setTitle(t("purchaseOrder.addTitle", "Add Purchase Order"));
    } else if (action === "view") {
      setTitle(t("purchaseOrder.viewTitle", "View Purchase Order"));
    } else if (action === "requestApproval") {
      setTitle(t("purchaseOrder.editTitle", "Edit Purchase Order"));
    } else if (action === "approveOrReject" || action === "directApprove") {
      setTitle(t("purchaseOrder.approveTitle", "Approve Purchase Order"));
    } else if (action === "confirm") {
      setTitle(t("purchaseOrder.confirmTitle", "Confirm Purchase Order"));
    }
  }, [purchaseOrderId, action, t]);

  // Auto-save logic with useEffect
  useEffect(() => {
    if (!autoSave || !enableAutoSave) return;

    console.log({ values, autoSave });
    const timeout = setTimeout(() => {
      setSaveAction("Pending save");
      handleSubmitAction("autoSave");
    }, 1000); // Save after 1 second of inactivity

    return () => clearTimeout(timeout); // Clear timeout if component re-renders (i.e., user types again)
  }, [values, autoSave]);

  function resendNotificationEmail() {
    axiosServices
      .post(`/api/purchaseOrder/${purchaseOrderId}/resendNotificationEmail`)
      .then((res) => {
        alertMessage("Notification email resent", "success");
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }

  function updateComponentUnitsOfMeasure(index: number, componentId: number) {
    axiosServices
      .get(`/api/purchaseOrder/component/${componentId}?companyId=${location?.companyId}`)
      .then((res) => {
        let component = res.data as ComponentType;
        if (component.unitsOfMeasure) {
          setFieldValue(
            `lines.${index}.unitOfMeasureIds`,
            component.unitsOfMeasure.map((u) => u.unitOfMeasureId)
          );
        }
        if (component.defaultUnitOfMeasureId) {
          setFieldValue(`lines.${index}.unitOfMeasureId`, component.defaultUnitOfMeasureId);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }

  function updateProductUnitsOfMeasure(index: number, productId: number) {
    axiosServices
      .get(`/api/purchaseOrder/product/${productId}`)
      .then((res) => {
        let product = res.data as ProductType;
        if (product.unitsOfMeasure) {
          setFieldValue(
            `lines.${index}.unitOfMeasureIds`,
            product.unitsOfMeasure.map((u) => u.unitOfMeasureId)
          );
        }
        if (product.defaultUnitOfMeasureId) {
          setFieldValue(`lines.${index}.unitOfMeasureId`, product.defaultUnitOfMeasureId);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }

  function handleProductComponentChange(index: number, newValue: ProductComponentOption | null) {
    console.log({ newValue });
    if (newValue?.type === "product") {
      setFieldValue(`lines.${index}.productId`, newValue?.value);
      setFieldValue(`lines.${index}.componentId`, null);
      updateProductUnitsOfMeasure(index, +newValue.value);
    } else if (newValue?.type === "component") {
      setFieldValue(`lines.${index}.productId`, null);
      setFieldValue(`lines.${index}.componentId`, newValue?.value);
      updateComponentUnitsOfMeasure(index, +newValue.value);
    }

    if (newValue?.value) {
      setFieldValue(`lines.${index}.code`, newValue.text);
      setFieldValue(`lines.${index}.description`, newValue.description);
    } else {
      setFieldValue(`lines.${index}.code`, "");
      setFieldValue(`lines.${index}.description`, "");
    }
  }

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        maxWidth: "1200px",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {title}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal(false, skipConfirmClose)} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ py: 0, px: 0 }}>
            <Stack direction="row" spacing={0}>
              <Grid container spacing={2} sx={{ py: 2, px: 4 }}>
                {/* {!confirmationMode && ( */}
                {mode === "edition" && (
                  <>
                    <Grid item sm={6}>
                      <Stack direction="row" justifyContent="space-between">
                        <Stack direction="column" spacing={0} sx={{ pb: 1 }}>
                          <Stack direction="row" spacing={1}>
                            <InputLabel>Company:&nbsp;</InputLabel>
                            {location?.companyName}
                          </Stack>
                          <Stack direction="row" spacing={1}>
                            <InputLabel>Location:&nbsp;</InputLabel>
                            {location?.name}
                          </Stack>
                          <Stack direction="row" spacing={1} sx={{ pt: 1 }} alignItems="center">
                            <InputLabel>Status:</InputLabel>
                            <PurchaseOrderStatusChip statusName={values?.statusDisplayName} isCentral={isCentral}></PurchaseOrderStatusChip>
                          </Stack>
                          {values?.salesOrderNumber && (
                            <Stack direction="row" spacing={1}>
                              <InputLabel>SO Number:&nbsp;</InputLabel>
                              {values?.salesOrderNumber}
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item container sm={6} spacing={1}>
                      <Grid item sm={4}>
                        <InputLabel>{t("purchaseOrder.poNumber", "PO Number")}</InputLabel>
                        <TextField
                          fullWidth
                          placeholder={t("purchaseOrder.poNumberPlaceholder", "Enter PO Number")}
                          {...getFieldProps(`purchaseOrderNumber`)}
                          helperText={<ErrorMessage name={`purchaseOrderNumber`} component="div" className="error" />}
                        />
                      </Grid>
                      <Grid item sm={4}>
                        <InputLabel>{t("purchaseOrder.shipVia", "Ship Via")}</InputLabel>
                        <Select
                          fullWidth
                          {...getFieldProps(`shipVia`)}
                          value={values.shipVia || ""}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value !== "Fedex Collect") {
                              setFieldValue(`collectAccountNumber`, "");
                            }
                          }}
                        >
                          <MenuItem value="">&nbsp;</MenuItem>
                          <MenuItem value="LTL">LTL</MenuItem>
                          <MenuItem value="Fedex Ground">Fedex Ground</MenuItem>
                          <MenuItem value="Local Delivery">Local Delivery</MenuItem>
                          <MenuItem value="Fedex Collect">Fedex Collect</MenuItem>
                        </Select>
                        <FormHelperText>
                          <ErrorMessage name={`shipVia`} component="div" className="error" />
                        </FormHelperText>
                      </Grid>
                      {values.shipVia === "Fedex Collect" && (
                        <Grid item sm={4}>
                          <InputLabel>{t("purchaseOrder.collectAccountNumber", "Collect Account #")}</InputLabel>
                          <TextField
                            fullWidth
                            placeholder={t("purchaseOrder.collectAccountNumber", "Collect Account #")}
                            {...getFieldProps(`collectAccountNumber`)}
                            helperText={<ErrorMessage name={`collectAccountNumber`} component="div" className="error" />}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item sm={12}>
                      <FieldArray name="lines">
                        {({ push, remove }) => (
                          <>
                            <Box
                              sx={{
                                overflowX: "auto",
                                border: "1px solid #eee",
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                      <InputLabel>Component / Product</InputLabel>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "150px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Code</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Description</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "80px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Quantity</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "150px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Unit</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    {permissions.canModifyPOPrices() && (
                                      <>
                                        <TableCell sx={{ verticalAlign: "top", width: "70px" }}>
                                          <Stack spacing={1}>
                                            <InputLabel>Price</InputLabel>
                                          </Stack>
                                        </TableCell>
                                        <TableCell sx={{ verticalAlign: "top", width: "70px" }}>
                                          <Stack spacing={1}>
                                            <InputLabel>Total</InputLabel>
                                          </Stack>
                                        </TableCell>
                                      </>
                                    )}
                                    <TableCell sx={{ verticalAlign: "top", width: "70px" }}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.lines.map((line, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        {components && (
                                          <Autocomplete
                                            fullWidth
                                            sx={{ minWidth: "220px" }}
                                            id={`lines.${index}.componentId`}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(label) => (label.value ? `${label.text} - ${label.description}` : "")}
                                            options={pcOptions}
                                            groupBy={(option) => option.type}
                                            loading={loadingComponents}
                                            disableClearable={false}
                                            // value={selectedComponents[index]}
                                            {...getFieldProps(`lines.${index}.component`)}
                                            onInputChange={handleComponentInputChange}
                                            // onFocus={() => fetchComponents("")}
                                            onChange={(_e, newValue) => {
                                              handleChange(_e);
                                              handleProductComponentChange(index, newValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                                id={`componentId-${index}`}
                                                placeholder={`No Component / Product`}
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <>
                                                      {loadingComponents ? <CircularProgress color="inherit" size={20} /> : null}
                                                      {params.InputProps.endAdornment}
                                                    </>
                                                  ),
                                                }}
                                              />
                                            )}
                                            renderOption={(props, option, state) => (
                                              <li {...props}>
                                                <HighlightedText
                                                  text={`${option.text} - ${option.description}`}
                                                  highlight={state.inputValue}
                                                ></HighlightedText>
                                              </li>
                                            )}
                                          />
                                        )}
                                      </TableCell>

                                      <TableCell sx={{ verticalAlign: "top", p: 1, maxWidth: "200px" }}>
                                        <Stack spacing={1}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            sx={{ minWidth: "120px" }}
                                            disabled
                                            placeholder="Code"
                                            {...getFieldProps(`lines.${index}.code`)}
                                            helperText={<ErrorMessage name={`lines.${index}.code`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={1}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            disabled
                                            sx={{ minWidth: "120px" }}
                                            placeholder="Description"
                                            {...getFieldProps(`lines.${index}.description`)}
                                            helperText={<ErrorMessage name={`lines.${index}.description`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={0}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            type="number"
                                            sx={{ minWidth: "120px" }}
                                            placeholder="Quantity"
                                            {...getFieldProps(`lines.${index}.quantity`)}
                                            helperText={<ErrorMessage name={`lines.${index}.quantity`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={0}>
                                          <Select
                                            size="small"
                                            fullWidth
                                            sx={{ minWidth: "120px" }}
                                            id="unitOfMeasureId"
                                            placeholder="Unit of Measure"
                                            {...getFieldProps(`lines.${index}.unitOfMeasureId`)}
                                            value={line.unitOfMeasureId || 0}
                                          >
                                            {line.unitOfMeasureIds?.length > 0 &&
                                              unitsOfMeasure?.length > 0 && [
                                                ...line.unitOfMeasureIds?.map((unitOfMeasureId) => {
                                                  let unit = unitsOfMeasure.find((u) => u.id === unitOfMeasureId);
                                                  return (
                                                    <MenuItem key={unit?.id} value={unit?.id}>
                                                      {unit?.name}
                                                    </MenuItem>
                                                  );
                                                }),
                                              ]}
                                          </Select>
                                          {touched?.lines?.[index]?.unitOfMeasureId && !values?.lines?.[index]?.unitOfMeasureId && (
                                            <FormHelperText error>Unit of Measure is required</FormHelperText>
                                          )}
                                        </Stack>
                                      </TableCell>
                                      {permissions.canModifyPOPrices() && (
                                        <>
                                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                            <Stack spacing={0}>
                                              <TextField
                                                size="small"
                                                fullWidth
                                                type="number"
                                                sx={{ minWidth: "120px" }}
                                                placeholder="Price"
                                                {...getFieldProps(`lines.${index}.unitaryPrice`)}
                                                helperText={<ErrorMessage name={`lines.${index}.unitaryPrice`} component="div" className="error" />}
                                              />
                                            </Stack>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              verticalAlign: "top",
                                              pt: 1.8,
                                              textAlign: "right",
                                            }}
                                          >
                                            <Stack spacing={1}>
                                              ${formatNo(values.lines[index].unitaryPrice * values.lines[index].quantity, 2, 2)}
                                            </Stack>
                                          </TableCell>
                                        </>
                                      )}
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack direction="row" spacing={1}>
                                          <IconButton color="error" onClick={() => remove(index)}>
                                            <DeleteOutlined />
                                          </IconButton>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <TableFooter>
                                  <TableRow>
                                    <TableCell colSpan={8} sx={{ p: 1 }}>
                                      <Stack spacing={1}>
                                        <ButtonGroup>
                                          <Button
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                              push({
                                                id: 0,
                                                componentId: 0,
                                                code: "",
                                                description: "",
                                                quantity: 0,
                                                pricePerPackaginQuantity: 0,
                                                totalPrice: 0,
                                              })
                                            }
                                            startIcon={<AddOutlined />}
                                          >
                                            Add Line
                                          </Button>
                                        </ButtonGroup>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </Box>

                            <Grid container spacing={2} sx={{ mt: 1 }}>
                              <Grid item sm={6}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="locationId">Billing Address</InputLabel>
                                  <Select
                                    id="billingAddressLocationId"
                                    placeholder="Billing Address Location"
                                    fullWidth
                                    input={<OutlinedInput />}
                                    {...getFieldProps(`billingAddressLocationId`)}
                                    onChange={(e) => {
                                      let locationId = e.target.value;
                                      setFieldValue(`billingAddressLocationId`, locationId);

                                      let location = locations?.find((l) => l.id === locationId);
                                      if (location) {
                                        setFieldValue(`billingAddress`, getAddress(location));
                                      }
                                    }}
                                  >
                                    {!locations && (
                                      <Box sx={{ p: 5 }}>
                                        <Stack direction="row" justifyContent="center">
                                          <CircularWithPath />
                                        </Stack>
                                      </Box>
                                    )}
                                    {locations &&
                                      locations?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <TextField disabled fullWidth multiline placeholder="Billing Address" {...getFieldProps(`billingAddress`)} />
                                </Stack>
                              </Grid>
                              <Grid item sm={6}>
                                <Stack spacing={1}>
                                  <InputLabel>Shipping Address</InputLabel>
                                  <Select
                                    id="shippingAddressLocationId"
                                    placeholder="Shipping Address Location"
                                    fullWidth
                                    input={<OutlinedInput />}
                                    {...getFieldProps(`shippingAddressLocationId`)}
                                    onChange={(e) => {
                                      let locationId = e.target.value;
                                      setFieldValue(`shippingAddressLocationId`, locationId);

                                      let location = locations?.find((l) => l.id === locationId);
                                      if (location) {
                                        setFieldValue(`shippingAddress`, getAddress(location));
                                      }
                                    }}
                                  >
                                    {!locations && (
                                      <Box sx={{ p: 5 }}>
                                        <Stack direction="row" justifyContent="center">
                                          <CircularWithPath />
                                        </Stack>
                                      </Box>
                                    )}
                                    {locations &&
                                      locations?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  <TextField disabled fullWidth multiline placeholder="Shipping Address" {...getFieldProps(`shippingAddress`)} />
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <Stack spacing={1}>
                                  <InputLabel htmlFor="notes">Notes</InputLabel>
                                  <TextField
                                    fullWidth
                                    multiline
                                    placeholder="Notes"
                                    {...getFieldProps(`notes`)}
                                    error={Boolean(touched.notes && errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                  ></TextField>
                                </Stack>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {(mode === "confirmation" || mode === "viewOnly") && (
                  <PurchaseOrderSummary values={values} location={location} isCentral={isCentral} unitsOfMeasure={unitsOfMeasure} />
                )}
              </Grid>
              {purchaseOrderId && statusHistory && (
                <>
                  <Divider orientation="vertical" flexItem></Divider>
                  <Grid container sx={{ pt: 2, px: 0, maxWidth: "300px" }}>
                    <Grid item sm={12}>
                      <Stack spacing={2}>
                        <Typography variant="h5" sx={{ textAlign: "center", px: 2 }}>
                          Status History
                        </Typography>
                        <Table>
                          <TableBody>
                            {statusHistory.map((status, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {!!status.emailSentTo && (
                                    <Stack direction="column" spacing={1}>
                                      <Stack direction="row" spacing={1}>
                                        <EmailOutlined></EmailOutlined>
                                        <span>{status.emailSentTo}</span>
                                      </Stack>
                                      <Typography variant="caption" color="gray" align="right">
                                        {formatDate(status.dateChanged, "PPp")}
                                      </Typography>
                                    </Stack>
                                  )}
                                  {!!status.statusDisplayName && (
                                    <Stack direction="column" spacing={1}>
                                      {/* {status.changedByFullName} */}
                                      <Stack direction="row" spacing={1}>
                                        <span>Status changed to</span>
                                        <PurchaseOrderStatusChip
                                          statusName={status.statusDisplayName}
                                          isCentral={isCentral}
                                        ></PurchaseOrderStatusChip>
                                      </Stack>
                                      <Typography variant="caption" color="gray" align="right">
                                        {formatDate(status.dateChanged, "PPp")} by <b>{status.changedByFullName}</b>
                                      </Typography>
                                    </Stack>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                            {statusHistory.length > 0 && ["Rejected", "Approved", "PendingApproval", "Confirmed"].includes(values?.statusName) && (
                              <TableRow>
                                <TableCell>
                                  <Stack direction="row" spacing={1}>
                                    <Button variant="text" onClick={resendNotificationEmail} startIcon={<Send></Send>}>
                                      Resend Notification Email
                                    </Button>
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: 2.5, px: 4, justifyContent: "space-between" }}>
            {mode === "edition" && enableAutoSave && (
              <Stack direction="row" alignItems="center">
                {/* <FormControlLabel control={<Switch onChange={(e) => setAutoSave(e.target.checked)} checked={autoSave} />} label="Auto Save" /> */}
                <Chip
                  label={saveAction}
                  variant="filled"
                  size="small"
                  color={saveAction === "Saved" ? "success" : saveAction === "Pending save" ? "warning" : "default"}
                  icon={saveAction === "Saved" ? <Check></Check> : saveAction === "Pending save" ? <Warning></Warning> : <Save></Save>}
                />
              </Stack>
            )}
            <span></span>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              {mode === "viewOnly" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Close
                </Button>
              )}
              {mode === "edition" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Cancel
                </Button>
              )}
              {/* {mode === "edition" && values?.statusName !== "Draft" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Save as Draft
                </Button>
              )} */}
              {mode === "confirmation" && (
                <Button color="secondary" onClick={() => setMode("edition")}>
                  Back to Edit
                </Button>
              )}
              {action === "requestApproval" && (
                <Button onClick={() => handleSubmitAction("requestApproval")} variant="contained" disabled={isSubmitting}>
                  {t("purchaseOrder.requestApproval", "Request Approval")}
                </Button>
              )}
              {action === "directApprove" && (
                <Button onClick={() => handleSubmitAction("directApprove")} variant="contained" disabled={isSubmitting} endIcon={<Send></Send>}>
                  Approve & Send PO
                </Button>
              )}
              {action === "approveOrReject" && (
                <>
                  <Button onClick={() => handleSubmitAction("reject")} variant="contained" color="error" disabled={isSubmitting}>
                    Reject
                  </Button>
                  <Button
                    onClick={() => handleSubmitAction("approve")}
                    variant="contained"
                    color="success"
                    disabled={isSubmitting}
                    endIcon={<Send></Send>}
                  >
                    Approve & Send PO
                  </Button>
                </>
              )}
              {/* {action === "confirm" && mode !== "edition" && (
                <Button onClick={() => setMode("edition")} color="secondary" disabled={isSubmitting}>
                  Back to Edit
                </Button>
              )} */}
              {action === "confirm" && (
                <>
                  <Button onClick={() => handleSubmitAction("confirm")} variant="contained" disabled={isSubmitting}>
                    Confirm
                  </Button>
                </>
              )}
            </Stack>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default AddPurchaseOrderModal;
