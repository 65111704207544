// import {  useState} from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, Tooltip, Typography } from "@mui/material";

import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { FormulaComponentItem } from "../../../types/formulation";
import React, { useState } from "react";
import { GetFormulaType, FormulaCreationType } from "../../../AllTypes";
import axiosServices from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import useAuth from "../../../hooks/useAuth";
import useTranslation from "../../../hooks/useTranslation";

import { SelectSeriesType } from "../../../AllTypes";

interface Props {
  closeModal: () => void;
  data: FormulaComponentItem[];
  series: SelectSeriesType;
  formula: GetFormulaType | null;
  weight: number;
  // formulasBySeries:CustomFormulaType
}

export default function ConfirmDeductFromInventory({
  closeModal,
  data,
  series,
  formula,
  weight,
}: // formulasBySeries
Props) {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  const deductFormula = async () => {
    try {
      if (!formula) {
        alertMessage(t("formulation.noFormulaSelected", "No Formula Selected"), "error");
        return;
      }
      const formulaCreation: FormulaCreationType = {
        formulaId: formula.id as number,
        weightInGrams: weight,
        components: data.map((d) => ({
          componentId: d.componentId,
          formulaId: d.componentId ? undefined : d.formulaId,
          weightInGrams: d.amountInGrams,
        })),
      };

      if (!currentUser?.locationId) {
        alertMessage(t("inventory.cannotDeductWithoutLocation", "Cannot deduct inventory without a location."), "warning");
        return;
      }
      await axiosServices.post(`/api/formula/deduct?locationId=${currentUser.locationId}`, formulaCreation);

      alertMessage(t("inventory.inventoryDeductedSuccessfully", "Inventory successfully deducted."), "info");
      closeModal();
    } catch (err) {
      alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
    }
  };

  return (
    <Box sx={{ maxHeight: "90vh", width: "450px", maxWidth: "90vw" }} mx={{ width: "40vw" }}>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" fontWeight="bold" component="span">
            {t("inventory.confirmInventoryDeduction", "Confirm Inventory Deduction")}
          </Typography>
        </DialogTitle>

        <Tooltip title={t("general.close", "Close")}>
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={3} sx={{ pb: 0 }}>
              <span>
                {t("inventory.confirmDeductionMessage", "Are you sure you want to deduct the components for {weight}g of formula {formulaName}?", {
                  weight,
                  formulaName: formula?.name,
                })}
              </span>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ width: 1, p: 4 }}>
              <Button fullWidth onClick={closeModal} color="secondary" variant="outlined">
                {t("general.cancel", "Cancel")}
              </Button>
              <Button fullWidth color="primary" variant="contained" onClick={deductFormula} autoFocus>
                {t("inventory.deductComponents", "Deduct Components")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2.5 }}></DialogActions> */}
    </Box>
  );
}
