import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, LeftOutlined, MenuOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, Stack, CssBaseline, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { ListItemButton } from "@mui/material";
import LangTranslation from "./lang-translate";
import UserProfile from "./user-profile";
import { Divider } from "@mui/material";
import { APP_BAR_HEIGHT, DRAWER_WIDTH, ThemeMode } from "../../config";
import ThemeToggler from "./sidebar-helpers/ThemeToggler";
import { getRoleAccessMenu } from "../../utils/userPermissions";
import { useAppSelector } from "../../redux/hook";
import { UserTypeRedux } from "../../types/user";
import DefaultSeriesSelector from "./DefaultSeriesSelector";
import { Tooltip } from "@mui/material";
import UserFeedback from "./sidebar-helpers/UserFeedback";
import useTranslation from "../../hooks/useTranslation";
// import Typography from '../../themes/typography';

interface NavigationItem {
  label: string;
  url?: string;
  access: boolean;
  children?: NavigationItem[];
}

const drawerWidth = DRAWER_WIDTH;
const appBarHeight = APP_BAR_HEIGHT;

// export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
//   open?: boolean;
// }>(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create('margin', {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   }),
// }));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // bgcolor: theme.palette.mode === ThemeMode.DARK ? 'divider' : 'white',
  height: APP_BAR_HEIGHT,
  backgroundColor: theme.palette.mode === ThemeMode.LIGHT ? "white" : "black",
  alignContent: "center",
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function Sidebar() {
  const { t } = useTranslation();

  // { children }: {children: React.ReactNode;}
  const pathname = useLocation().pathname;

  const [open, setOpen] = React.useState(true);
  React.useEffect(() => {
    setOpen(true);
  }, []);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);
  const roleAuth = getRoleAccessMenu(user?.userLevelName);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  console.log({ user });

  const handleSubMenuToggle = (label: string) => {
    setOpenSubMenu((prevOpenSubMenu) => ({
      ...prevOpenSubMenu,
      [label]: !prevOpenSubMenu[label],
    }));
  };

  const [openSubMenu, setOpenSubMenu] = React.useState<{ [key: string]: boolean }>({
    // Inks: pathname.includes("/inks"),
    // Admin: pathname.includes("/admin")
    Inks: true,
    Admin: true,
  });
  let navLinks: NavigationItem[] = [
    // { label: 'Dashboard', url: '/' },
    { label: t("sidebar.formulation", "Formulation"), url: "/", access: roleAuth.formulation.access },
    // { label: t("sidebar.formulationDispenser", "Formulation 2"), url: "/formulation/dispenser", access: roleAuth.formulation.dispenser.access },
    { label: t("sidebar.formulaRecycle", "Formula Recycle"), url: "/formulation/recycle", access: roleAuth.formulation.recycle.access },
    { label: t("sidebar.purchaseOrders", "Purchase Orders"), url: "/purchaseOrders", access: roleAuth.purchaseOrders.access },
    {
      label: t("sidebar.inventory", "Inventory"),
      access: roleAuth.inventory.access,
      children: [
        { label: t("sidebar.stockLevels", "Stock Levels"), url: "/inventory/stockLevels", access: roleAuth.inventory.stockLevels.access },
        { label: t("sidebar.stockEntries", "Stock Entries"), url: "/inventory/stockEntries", access: roleAuth.inventory.stockEntries.access },
        { label: t("sidebar.componentCosts", "Component Costs"), url: "/inventory/componentCosts", access: roleAuth.inventory.componentCosts.access },
        {
          label: t("sidebar.inventoryTransactions", "Inventory Transactions"),
          url: "/inventory/inventoryTransactions",
          access: roleAuth.inventory.inventoryTransactions.access,
        },
      ],
    },
    {
      label: t("sidebar.inks", "Inks"),
      children: [
        { label: t("sidebar.series", "Series"), url: "/inks/series", access: roleAuth.inks.series.access },
        { label: t("sidebar.component", "Component"), url: "/inks/component", access: roleAuth.inks.component.access },
      ],
      access: roleAuth.inks.access,
    },
    { label: t("sidebar.inkUsageCalculator", "Ink Usage Calculator"), url: "/ink-usage-calculator", access: roleAuth.inkcal.access },
    {
      label: t("sidebar.admin", "Admin"),
      children: [
        {
          label: t("sidebar.purchaseOrdersCentral", "Purchase Orders Central"),
          url: "/admin/purchaseOrderCentral",
          access: roleAuth.admin.purchaseOrderCentral.access,
        },
        // { label: t("sidebar.quotesCentral", "Quotes Central"), url: "/admin/quoteCentral", access: roleAuth.admin.quoteCentral.access },
        { label: t("sidebar.companies", "Companies"), url: "/admin/company", access: roleAuth.admin.company.access },
        { label: t("sidebar.locations", "Locations"), url: "/admin/location", access: roleAuth.admin.location.access },
        { label: t("sidebar.users", "Users"), url: "/admin/user", access: roleAuth.admin.user.access },
        { label: t("sidebar.products", "Products"), url: "/admin/product", access: roleAuth.admin.product.access },
        { label: t("sidebar.unitsOfMeasure", "Units of Measure"), url: "/admin/unitOfMeasure", access: roleAuth.admin.unitOfMeasure.access },
        // categories submenu
        {
          label: t("sidebar.categories", "Categories"),
          children: [
            {
              label: t("sidebar.productCategories", "Product Categories"),
              url: "/admin/productCategory",
              access: roleAuth.admin.productCategory.access,
            },
            {
              label: t("sidebar.productSubcategories", "Product Subcategories"),
              url: "/admin/productSubcategory",
              access: roleAuth.admin.productSubcategory.access,
            },
            {
              label: t("sidebar.componentCategories", "Component Categories"),
              url: "/admin/componentCategory",
              access: roleAuth.admin.componentCategory.access,
            },
            {
              label: t("sidebar.componentSubcategories", "Component Subcategories"),
              url: "/admin/componentSubcategory",
              access: roleAuth.admin.componentSubcategory.access,
            },
          ],
          access: roleAuth.admin.categories.access,
        },
        { label: t("sidebar.userActions", "User Actions"), url: "/admin/userActions", access: roleAuth.admin.userActions.access },
      ],
      access: roleAuth.admin.access,
    },
  ];

  if (
    user?.userLevelName !== "SuperAdmin" &&
    user?.userLevelName !== "Admin" &&
    user?.userLevelName !== "CustomerServiceAgent" &&
    user?.userLevelName !== "SalesPerson" &&
    !user?.featureFlags?.includes("purchaseOrders")
  ) {
    navLinks = navLinks.filter((item) => item.label !== "Purchase Orders");
    navLinks = navLinks.filter((item) => item.label !== "Purchase Orders Central");
  }

  const NestedListItems: React.FC<{ items: NavigationItem[]; level: number }> = ({ items, level }) => {
    return (
      <>
        {items.map((item) => (
          <React.Fragment key={item.label}>
            {item.children && item.children.length > 0 ? (
              <>
                {item.access && (
                  <ListItemButton onClick={() => handleSubMenuToggle(item.label)}>
                    {/* <ListItemIcon></ListItemIcon> */}
                    <ListItemText primary={item.label} />
                    {openSubMenu[item.label] ? <UpOutlined /> : <DownOutlined />}
                  </ListItemButton>
                )}

                <Collapse in={openSubMenu[item.label]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ pl: 1 }}>
                    <NestedListItems items={item.children} level={level + 1} />
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                {item.access && (
                  <ListItemButton
                    onClick={() => {
                      if (item.url) {
                        navigate(item.url);
                      }

                      if (isMobile) {
                        handleDrawerToggle();
                      }
                    }}
                    sx={{
                      borderRight: pathname === item.url! ? "2px solid" : "",
                      borderRightColor: pathname === item.url! ? "primary.main" : "",
                      bgcolor: pathname === item.url! ? "grey.200" : "",
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        // sx={{ height: APP_BAR_HEIGHT, bgcolor: (theme.palette.mode === ThemeMode.LIGHT && 'secondary.lighter')  }}
      >
        <Toolbar>
          <Stack sx={{ width: "100%" }} direction="row" alignItems="center" justifyContent="space-between">
            <Tooltip title="aaa">
              <IconButton
                onClick={handleDrawerToggle}
                sx={{
                  ...(!open && { display: "none" }),
                }}
              >
                <MenuOutlined />
              </IconButton>
            </Tooltip>
            <Stack direction="row" alignItems="center" justifyContent="space-around">
              <Tooltip title="Open Menu">
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    ":hover": {
                      color: "grey",
                    },
                  }}
                >
                  <MenuOutlined />
                </IconButton>
              </Tooltip>
              <DefaultSeriesSelector />
              <ThemeToggler />
              <LangTranslation />
              <UserFeedback />
              <UserProfile />
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: isMobile ? "100vw" : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isMobile ? "100vw" : drawerWidth,
            boxSizing: "border-box",
          },
        }}
        anchor={isMobile ? "top" : "left"}
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <DrawerHeader sx={{ p: 0 }}>
          <img
            style={{ height: "auto", width: "100%", maxWidth: "300px" }}
            src={"/images/it-logo.png"}
            alt={t("general.logoAlt", "IT-Logo")}
            onClick={handleDrawerToggle}
          />
        </DrawerHeader>
        <List>
          <NestedListItems items={navLinks} level={0} />
        </List>
        <Divider />
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" color="gray">
            {user?.userLevelDisplayName || <i>{t("sidebar.noUserLevel", "No user level")}</i>}
          </Typography>
          <Typography variant="body2" color="gray">
            {t("sidebar.company", "Company")}: {user?.companyName || <i>{t("sidebar.noCompany", "No company")}</i>}
          </Typography>
          <Typography variant="body2" color="gray">
            {t("sidebar.location", "Location")}: {user?.locationName || <i>{t("sidebar.noLocation", "No location")}</i>}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}
