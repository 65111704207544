// material-ui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// project import
import AuthWrapper from "../../../sections/auth/AuthWrapper";
import ResetPasswordForm from "./ResetPasswordForm";
import { useSearchParams } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

// ================================|| RESET PASSWORD ||================================ //

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack sx={{ mb: { xs: -0.5, sm: 0.5 } }} spacing={1}>
            <Typography variant="h3">{t("resetPassword.title", "Reset Password")}</Typography>
            <Typography color="secondary">{t("resetPassword.instructions", "Please choose your new password")}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <ResetPasswordForm token={token || ""} />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
