import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { LocationType, QuoteLineType, UnitOfMeasureType, ProductType, ComponentType, CompanyType, SelectCompanyType } from "../../AllTypes";
import HighlightedText from "../../components/HighlightedText";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { useUserPermissions } from "../../utils/userPermissions";
import { AddOutlined, Check, EmailOutlined, Redo, Save, Send, Warning } from "@mui/icons-material";
import { formatDate } from "../../utils/formatDate";
import QuoteSummary from "./QuoteSummary";
import useTranslation from "../../hooks/useTranslation";
import { totalmem } from "os";
import useAuth from "hooks/useAuth";

interface Props {
  closeModal: (modified?: boolean, skipConfirmClose?: boolean) => void;
  quoteId?: number | null;
  cloneQuoteId?: number | null;
  companyId?: number | null;
  isCentral: boolean;
}

interface ProductComponentOption {
  value: string;
  text: string;
  description: string;
  defaultUnitOfMeasureId?: string;
  type: "component" | "product";
  unitOfMeasureIds?: [number];
}

const ItemSchema = Yup.object().shape({
  quantity: Yup.number().required("Quantity is required").positive("Quantity must be a positive number."),
  minOrderQuantity: Yup.number().required("Min Order Quantity is required").positive("Min Order Quantity must be a positive number."),
  unitOfMeasureId: Yup.string().required("Unit of Measure is required"),
});

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

const AddQuoteModal: React.FC<Props> = ({ closeModal, quoteId, cloneQuoteId, companyId: defaultCompanyId, isCentral }) => {
  const { t } = useTranslation();
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [products, setProducts] = useState<ProductComponentOption[]>([]);
  const [components, setComponents] = useState<ProductComponentOption[]>();
  // const [location, setLocation] = useState<LocationType>();
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [pcOptions, setPcOptions] = useState<ProductComponentOption[]>([]);
  // const [locations, setLocations] = useState<LocationType[]>();
  const [submitAction, setSubmitAction] = useState<"" | "saveAsQuoteRequest" | "saveAsQuote">("");
  const [action, setAction] = useState<"view" | "edit">("view");
  const [mode, setMode] = useState<"confirmation" | "edition" | "viewOnly" | null>(null);
  const [title, setTitle] = useState("Add Quote");

  const permissions = useUserPermissions();
  const { user: currentUser } = useAuth();
  const [skipConfirmClose, setSkipConfirmClose] = useState(true);

  useEffect(() => {
    if (mode === "edition") {
      setSkipConfirmClose(false);
    }
  }, [mode]);

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // function getAddress(location: LocationType) {
  //   return `${location?.address}${location?.address2 ? "\n" + location.address2 : ""}\n${location?.city}, ${location?.state} ${location?.zip}${
  //     location?.country ? "\n" + location.country : ""
  //   }`;
  // }

  const fetchComponents = (inputValue: string) => {
    setLoadingComponents(true);

    axiosServices
      // .get(`/api/component?companyId=${location?.companyId}&search=${inputValue}&limit=30`)
      .get(`/api/component?companyId=${values?.companyId}`)
      .then((res) => {
        let options = res.data.map(
          (cc: ComponentType) =>
            ({
              text: cc.name,
              value: cc.id?.toString(),
              description: cc.description,
              type: "component",
              defaultUnitOfMeasureId: cc.defaultUnitOfMeasureId?.toString(),
              unitOfMeasureIds: [...new Set(cc.unitsOfMeasure?.map((u) => u.unitOfMeasureId))],
            } as ProductComponentOption)
        );
        setComponents(options);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoadingComponents(false);
      });

    axiosServices
      // .get(`/api/product?search=${inputValue}&limit=30`)
      .get(`/api/product`)
      .then((res) => {
        let options = res.data.map(
          (cc: ProductType) =>
            ({
              text: cc.code,
              value: cc.id.toString(),
              description: cc.description,
              type: "product",
              defaultUnitOfMeasureId: cc.defaultUnitOfMeasureId?.toString(),
              unitOfMeasureIds: [...new Set(cc.unitsOfMeasure?.map((u) => u.unitOfMeasureId))],
            } as ProductComponentOption)
        );
        setProducts(options);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoadingComponents(false);
      });
  };

  useEffect(() => {
    console.log({ products, components });
    if (!products || !components) {
      return;
    }

    setPcOptions([...components, ...products]);
  }, [products, components]);

  // Handle input change to trigger the fetch
  const handleComponentInputChange = (event: React.SyntheticEvent, value: string) => {
    // fetchComponents(value);
  };

  const initialValues = {
    id: 0,
    // billingAddress: "",
    // shippingAddress: "",
    // billingAddressLocationId: 0,
    // shippingAddressLocationId: 0,
    // quoteNumber: "",
    // shipVia: "",
    // collectAccountNumber: "",
    // salesOrderNumber: "",
    // status: 1,
    // statusName: "Draft",
    // statusDisplayName: "Draft",
    // totalPrice: 0,
    preparedFor: "",
    salesRepresentativeId: null,
    salesRepresentativeFullName: "",
    companyId: defaultCompanyId && defaultCompanyId > 0 ? defaultCompanyId : null,
    // notes: "",
    lines: [
      {
        id: 0,
        componentId: null,
        productId: null,
        code: "",
        description: "",
        quantity: 0,
        minOrderQuantity: 0,
        unitaryPrice: 0,
        totalPrice: 0,
        unitOfMeasureId: null,
        unitOfMeasureIds: [],
      },
    ],
  };

  useEffect(() => {
    fetchComponents("");
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      companyId: Yup.number().positive("Customer is required").required("Customer is required"),
      lines: Yup.array().of(ItemSchema),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log({ onSubmit: submitAction });

      // if (!confirmationMode) {
      if (mode === "edition") {
        setMode("confirmation");
        // setConfirmationMode(true);
        setSubmitting(false);
        return;
      }

      let url = "";
      let successfulMessage = "";
      if (submitAction === "saveAsQuoteRequest") {
        url = `/api/quote/request`;
        successfulMessage = t("quote.requestSavedMessage", "Quote request saved successfully");
      } else if (submitAction === "saveAsQuote") {
        url = `/api/quote/quote`;
        successfulMessage = t("quote.savedMessage", "Quote saved successfully");
      } else {
        setSubmitting(false);
        return;
      }

      axiosServices
        .post(url, values)
        .then((res) => {
          alertMessage(successfulMessage, "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
          setMode("edition");
        })
        .finally(() => {
          setSubmitting(false);
          // setConfirmationMode(false);
        });
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setValues, setFieldValue } = formik;

  // TODO: simplify this if there are no other status / actions
  function handleSubmitAction(_submitAction: "" | "saveAsQuoteRequest" | "saveAsQuote") {
    console.log({ handleSubmitAction: _submitAction });
    if (submitAction === _submitAction) {
      handleSubmit();
    } else {
      setSubmitAction(_submitAction);
    }
  }

  useEffect(() => {
    if (!submitAction) return;
    handleSubmit();
  }, [submitAction, handleSubmit]);

  useEffect(() => {
    if (!quoteId && !cloneQuoteId) {
      return;
    }

    if (quoteId && cloneQuoteId) {
      alertMessage("Invalid parameters", "error");
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/quote/${quoteId || cloneQuoteId}`)
      .then((res) => {
        let quote = res.data;
        quote.lines = quote.lines.map((l: any) => {
          return {
            ...l,
            component: l.componentId
              ? { value: l.componentId.toString(), text: l.componentName, description: l.componentDescription }
              : l.productId
              ? { value: l.productId.toString(), text: l.productCode, description: l.productDescription }
              : { value: 0, text: "", description: "" },
            unitOfMeasureIds: [
              ...new Set(
                l.componentId
                  ? l.componentUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
                  : l.productUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
              ),
            ],
          };
        });

        if (cloneQuoteId) {
          quote.quoteNumber = "";
          quote.id = 0;
          quote.lines = quote.lines.map((l: QuoteLineType) => {
            l.id = 0;
            return l;
          });
        }

        setValues(quote);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [quoteId, cloneQuoteId]);

  useEffect(() => {
    if (quoteId && !values?.id) {
      // Quote has not loaded yet
      return;
    }

    setMode("edition");
    setAction("edit");
  }, [quoteId, values, isCentral]);

  useEffect(() => {
    if (!quoteId) {
      setTitle(t("quote.addTitle", "Add Quote"));
    } else if (action === "view") {
      setTitle(t("quote.viewTitle", "View Quote"));
    } else if (action === "edit") {
      setTitle(t("quote.editTitle", "Edit Quote"));
    }
  }, [quoteId, action]);

  function updateComponentUnitsOfMeasure(index: number, componentId: number) {
    axiosServices
      .get(`/api/quote/component/${componentId}?companyId=${values.companyId}`)
      .then((res) => {
        let component = res.data as ComponentType;
        if (component.unitsOfMeasure) {
          setFieldValue(
            `lines.${index}.unitOfMeasureIds`,
            component.unitsOfMeasure.map((u) => u.unitOfMeasureId)
          );
        }
        if (component.defaultUnitOfMeasureId) {
          setFieldValue(`lines.${index}.unitOfMeasureId`, component.defaultUnitOfMeasureId);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }

  function updateProductUnitsOfMeasure(index: number, productId: number) {
    axiosServices
      .get(`/api/quote/product/${productId}`)
      .then((res) => {
        let product = res.data as ProductType;
        if (product.unitsOfMeasure) {
          setFieldValue(
            `lines.${index}.unitOfMeasureIds`,
            product.unitsOfMeasure.map((u) => u.unitOfMeasureId)
          );
        }
        if (product.defaultUnitOfMeasureId) {
          setFieldValue(`lines.${index}.unitOfMeasureId`, product.defaultUnitOfMeasureId);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }

  function handleProductComponentChange(index: number, newValue: ProductComponentOption | null) {
    console.log({ newValue });
    if (newValue?.type === "product") {
      setFieldValue(`lines.${index}.productId`, newValue?.value);
      setFieldValue(`lines.${index}.componentId`, null);
      updateProductUnitsOfMeasure(index, +newValue.value);
    } else if (newValue?.type === "component") {
      setFieldValue(`lines.${index}.productId`, null);
      setFieldValue(`lines.${index}.componentId`, newValue?.value);
      updateComponentUnitsOfMeasure(index, +newValue.value);
    }

    if (newValue?.value) {
      setFieldValue(`lines.${index}.code`, newValue.text);
      setFieldValue(`lines.${index}.description`, newValue.description);
    } else {
      setFieldValue(`lines.${index}.code`, "");
      setFieldValue(`lines.${index}.description`, "");
    }
  }

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        maxWidth: "1200px",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {title}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal(false, skipConfirmClose)} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ py: 0, px: 0 }}>
            <Stack direction="row" spacing={0}>
              <Grid container spacing={2} sx={{ py: 2, px: 4 }}>
                {/* {!confirmationMode && ( */}
                {mode === "edition" && (
                  <>
                    <Grid item container spacing={2} sm={12}>
                      <Grid item sm={4}>
                        <Stack direction="column" spacing={1}>
                          <InputLabel>Customer:&nbsp;</InputLabel>
                          <Select
                            id="companyId"
                            fullWidth
                            input={<OutlinedInput />}
                            {...getFieldProps(`companyId`)}
                            value={values.companyId}
                            onChange={(e) => {
                              let id = e.target.value;
                              setFieldValue("companyId", id);

                              let company = companies.find((c) => c.value === id);

                              if (company) {
                                setSelectedCompanyName(company.text);
                                return;
                              }
                            }}
                            error={touched.companyId && !!errors.companyId}
                          >
                            {!companies && (
                              <Box sx={{ p: 5 }}>
                                <Stack direction="row" justifyContent="center">
                                  <CircularWithPath />
                                </Stack>
                              </Box>
                            )}
                            {companies &&
                              companies?.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              ))}
                          </Select>
                          {touched.companyId && !values.companyId && <FormHelperText error>Customer is required</FormHelperText>}
                        </Stack>
                      </Grid>
                      <Grid item sm={4}>
                        <Stack direction="column" spacing={1}>
                          <InputLabel>Prepared For:&nbsp;</InputLabel>
                          <TextField
                            fullWidth
                            placeholder={t("quote.preparedForPlaceholder", "Customer First & Last Name")}
                            {...getFieldProps(`preparedFor`)}
                            helperText={<ErrorMessage name={`preparedFor`} component="div" className="error" />}
                          />
                        </Stack>
                      </Grid>
                      <Grid item sm={4}>
                        <Stack direction="column" spacing={1}>
                          <InputLabel>{t("quote.salesRepresentative", "Sales Representative:")}</InputLabel>
                          <OutlinedInput
                            fullWidth
                            id="salesRepresentative"
                            value={values.id ? values?.salesRepresentativeFullName : `${currentUser?.firstName} ${currentUser?.lastName}`}
                            name="salesRepresentative"
                            readOnly={true}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid item sm={12}>
                      <FieldArray name="lines">
                        {({ push, remove }) => (
                          <>
                            <Box
                              sx={{
                                overflowX: "auto",
                                border: "1px solid #eee",
                              }}
                            >
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                      <InputLabel>Component / Product</InputLabel>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "150px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Code</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Description</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "150px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Unit</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "80px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Quantity</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    <TableCell sx={{ verticalAlign: "top", width: "80px" }}>
                                      <Stack spacing={1}>
                                        <InputLabel>Min Order Quantity</InputLabel>
                                      </Stack>
                                    </TableCell>
                                    {permissions.canModifyQuotePrices() && (
                                      <>
                                        <TableCell sx={{ verticalAlign: "top", width: "70px" }}>
                                          <Stack spacing={1}>
                                            <InputLabel>Price</InputLabel>
                                          </Stack>
                                        </TableCell>
                                        <TableCell sx={{ verticalAlign: "top", width: "70px" }}>
                                          <Stack spacing={1}>
                                            <InputLabel>Total</InputLabel>
                                          </Stack>
                                        </TableCell>
                                      </>
                                    )}
                                    <TableCell sx={{ verticalAlign: "top", width: "70px" }}></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values.lines.map((line, index) => (
                                    <TableRow key={index}>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        {components && (
                                          <Autocomplete
                                            fullWidth
                                            sx={{ minWidth: "220px" }}
                                            id={`lines.${index}.componentId`}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            getOptionLabel={(label) => (label.value ? `${label.text} - ${label.description}` : "")}
                                            options={pcOptions}
                                            groupBy={(option) => option.type}
                                            loading={loadingComponents}
                                            disableClearable={false}
                                            // value={selectedComponents[index]}
                                            {...getFieldProps(`lines.${index}.component`)}
                                            onInputChange={handleComponentInputChange}
                                            // onFocus={() => fetchComponents("")}
                                            onChange={(_e, newValue) => {
                                              handleChange(_e);
                                              handleProductComponentChange(index, newValue);
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                                id={`componentId-${index}`}
                                                placeholder={`No Component / Product`}
                                                InputProps={{
                                                  ...params.InputProps,
                                                  endAdornment: (
                                                    <>
                                                      {loadingComponents ? <CircularProgress color="inherit" size={20} /> : null}
                                                      {params.InputProps.endAdornment}
                                                    </>
                                                  ),
                                                }}
                                              />
                                            )}
                                            renderOption={(props, option, state) => (
                                              <li {...props}>
                                                <HighlightedText
                                                  text={`${option.text} - ${option.description}`}
                                                  highlight={state.inputValue}
                                                ></HighlightedText>
                                              </li>
                                            )}
                                          />
                                        )}
                                      </TableCell>

                                      <TableCell sx={{ verticalAlign: "top", p: 1, maxWidth: "200px" }}>
                                        <Stack spacing={1}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            sx={{ minWidth: "120px" }}
                                            disabled
                                            placeholder="Code"
                                            {...getFieldProps(`lines.${index}.code`)}
                                            helperText={<ErrorMessage name={`lines.${index}.code`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={1}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            disabled
                                            sx={{ minWidth: "120px" }}
                                            placeholder="Description"
                                            {...getFieldProps(`lines.${index}.description`)}
                                            helperText={<ErrorMessage name={`lines.${index}.description`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={0}>
                                          <Select
                                            size="small"
                                            fullWidth
                                            sx={{ minWidth: "120px" }}
                                            id="unitOfMeasureId"
                                            placeholder="Unit of Measure"
                                            {...getFieldProps(`lines.${index}.unitOfMeasureId`)}
                                            value={line.unitOfMeasureId || 0}
                                          >
                                            {line.unitOfMeasureIds?.length > 0 &&
                                              unitsOfMeasure?.length > 0 && [
                                                ...line.unitOfMeasureIds?.map((unitOfMeasureId) => {
                                                  let unit = unitsOfMeasure.find((u) => u.id === unitOfMeasureId);
                                                  return (
                                                    <MenuItem key={unit?.id} value={unit?.id}>
                                                      {unit?.name}
                                                    </MenuItem>
                                                  );
                                                }),
                                              ]}
                                          </Select>
                                          {touched?.lines?.[index]?.unitOfMeasureId && !values?.lines?.[index]?.unitOfMeasureId && (
                                            <FormHelperText error>Unit of Measure is required</FormHelperText>
                                          )}
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={0}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            type="number"
                                            sx={{ minWidth: "120px" }}
                                            placeholder="Quantity"
                                            {...getFieldProps(`lines.${index}.quantity`)}
                                            helperText={<ErrorMessage name={`lines.${index}.quantity`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack spacing={0}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            type="number"
                                            sx={{ minWidth: "120px" }}
                                            placeholder="Min Order Quantity"
                                            {...getFieldProps(`lines.${index}.minOrderQuantity`)}
                                            helperText={<ErrorMessage name={`lines.${index}.minOrderQuantity`} component="div" className="error" />}
                                          />
                                        </Stack>
                                      </TableCell>
                                      {permissions.canModifyQuotePrices() && (
                                        <>
                                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                            <Stack spacing={0}>
                                              <TextField
                                                size="small"
                                                fullWidth
                                                type="number"
                                                sx={{ minWidth: "120px" }}
                                                placeholder="Price"
                                                {...getFieldProps(`lines.${index}.unitaryPrice`)}
                                                helperText={<ErrorMessage name={`lines.${index}.unitaryPrice`} component="div" className="error" />}
                                              />
                                            </Stack>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              verticalAlign: "top",
                                              pt: 1.8,
                                              textAlign: "right",
                                            }}
                                          >
                                            <Stack spacing={1}>
                                              ${formatNo(values.lines[index].unitaryPrice * values.lines[index].quantity, 2, 2)}
                                            </Stack>
                                          </TableCell>
                                        </>
                                      )}

                                      <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                                        <Stack direction="row" spacing={1}>
                                          <IconButton color="error" onClick={() => remove(index)}>
                                            <DeleteOutlined />
                                          </IconButton>
                                        </Stack>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                                <TableFooter>
                                  <TableRow>
                                    <TableCell colSpan={8} sx={{ p: 1 }}>
                                      <Stack spacing={1}>
                                        <ButtonGroup>
                                          <Button
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                              push({
                                                id: 0,
                                                componentId: 0,
                                                code: "",
                                                description: "",
                                                quantity: 0,
                                                pricePerPackaginQuantity: 0,
                                                totalPrice: 0,
                                              })
                                            }
                                            startIcon={<AddOutlined />}
                                          >
                                            Add Line
                                          </Button>
                                        </ButtonGroup>
                                      </Stack>
                                    </TableCell>
                                  </TableRow>
                                </TableFooter>
                              </Table>
                            </Box>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    <Grid item sm={12}>
                      <Divider />
                    </Grid>
                  </>
                )}
                {(mode === "confirmation" || mode === "viewOnly") && (
                  <QuoteSummary
                    values={values}
                    companyName={selectedCompanyName}
                    isCentral={isCentral}
                    unitsOfMeasure={unitsOfMeasure}
                    showPrices={permissions.canModifyQuotePrices()}
                  />
                )}
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: 2.5, px: 4, justifyContent: "space-between" }}>
            <Stack direction="row" alignItems="center"></Stack>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              {mode === "viewOnly" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Close
                </Button>
              )}
              {mode === "edition" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Cancel
                </Button>
              )}
              {/* {mode === "edition" && values?.statusName !== "Draft" && (
                <Button color="secondary" onClick={() => closeModal(false, skipConfirmClose)}>
                  Save as Draft
                </Button>
              )} */}
              {mode === "confirmation" && (
                <Button color="secondary" onClick={() => setMode("edition")}>
                  Back to Edit
                </Button>
              )}
              {action === "edit" && (
                <>
                  <Button
                    onClick={() => handleSubmitAction("saveAsQuoteRequest")}
                    variant="contained"
                    disabled={isSubmitting}
                    endIcon={<Send></Send>}
                  >
                    Request Quote
                  </Button>
                  {permissions.canModifyQuotePrices() && (
                    <Button onClick={() => handleSubmitAction("saveAsQuote")} variant="contained" disabled={isSubmitting} endIcon={<Send></Send>}>
                      Save Quote
                    </Button>
                  )}
                </>
              )}
            </Stack>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default AddQuoteModal;
