import useTranslation from "../../hooks/useTranslation";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import FormulaChip from "../../components/FormulaChip";

// types

// assets
import {
  Box,
  ButtonGroup,
  Fade,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState, useCallback } from "react";

import { GetFormulaType, SelectSeriesType, FormulaListItemType } from "../../AllTypes";
import DisplayTable from "../../components/DisplayTable";
import MainLayout from "../main-layout";
import ShareFormulaModal from "./formulation-helpers/ShareFormulaModal";
import { FormulaEndpoints, SeriesEndpoints } from "../../AllLables";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";
import Stop from "@mui/icons-material/Stop";
import { formatDate } from "../../utils/formatDate";

import { useAppSelector } from "../../redux/hook";
import { FormulaComponentItem } from "../../types/formulation";
import PrintFormulaModal from "./formulation-helpers/PrintFormulaModal";
import ConfirmDeductFromInventory from "./formulation-helpers/ConfirmDeductFromInventory";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { UserTypeRedux } from "../../types/user";
import useStickyState from "../../hooks/useStickyState";
import "./styles.css";
import { Email, MoveDown, Print } from "@mui/icons-material";
import { formatNo } from "../../utils/utility";
import { debounce } from "lodash";

export default function FormulationDispenser() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const predefinedWeights = [
    { id: 1, label: "100 g", weight: 100 },
    { id: 1, label: "500 g", weight: 500 },
    { id: 2, label: "1 kg", weight: 1000 },
  ];

  const [includeMaster, setIncludeMaster] = useState(true);
  const [includeCompany, setIncludeCompany] = useState(true);
  const [includeUser, setIncludeUser] = useState(true);
  const [formulas, setFormulas] = useState<GetFormulaType[]>();
  const [formulaOptions, setFormulaOptions] = useState<FormulaListItemType[]>([]);
  const [isLoadingFormula, setIsLoadingFormula] = useState<boolean>(false);
  const [selectedFormulaId, setSelectedFormulaId] = useState<number | null>(null);
  const [selectedFormulaOption, setSelectedFormulaOption] = useState<FormulaListItemType | null>(null);
  const [isLoading, setisLoading] = useState(false);
  const [weightValue, setweightValue] = useState<number>(100);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [showConfirmDeductFormulaModal, setShowConfirmDeductFormulaModal] = useState<boolean>(false);
  const [showShareFormulaModal, setShowShareFormulaModal] = useState<boolean>(false);
  const [selectedFormula, setSelectedFormula] = useState<GetFormulaType | null>(null);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);

  const [boxColor, setBoxColor] = useState<string | null>(null);

  const [series, setSeries] = useState<SelectSeriesType[]>();
  const [selectedSeries, setSelectedSeries] = useState<SelectSeriesType>();
  const [defaultSeriesId, setDefaultSeriesId] = useStickyState<string | null>(null, "defaultSeriesId");

  const [searchText, setSearchText] = useState<string>("");
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    maxWidth: "900px",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  function setDataFunction() {
    axiosServices
      .get(SeriesEndpoints.getForSelect)
      .then((res) => {
        setSeries(res.data);

        // Set the default series or the first series as selected
        if (!selectedSeries) {
          let ss = res.data[0];
          if (defaultSeriesId) {
            let defaultSeries = res.data.find((d: SelectSeriesType) => d.value === defaultSeriesId);
            if (defaultSeries) {
              ss = defaultSeries;
            }
          }
          setSelectedSeries(ss);
        }
      })
      .catch((err) => {
        alertMessage(t("general.SomethingWentWrong ", "Something went wrong"), "error", err);
      });
  }

  useEffect(() => {
    setDataFunction();
  }, []);

  useEffect(() => {
    if (selectedSeries) {
      setisLoading(true);
      axiosServices
        .get(
          `/api/Formula/GetAll?seriesId=${selectedSeries.value}&includeMaster=${includeMaster}&includeCompany=${includeCompany}&includeUser=${includeUser}&search=${searchText}&limit=50`
        )
        .then((res) => {
          let formulas = res.data;
          setFormulas(formulas);
          let options: FormulaListItemType[] = formulas.map((f: GetFormulaType) => ({
            text: f.name,
            value: f.id,
            selected: false,
          }));
          options.sort((a, b) => {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          });
          setFormulaOptions(options);

          // setSelectedFormulaId(selectedFormulaId || null);
          // let option = options.find((f) => f.value.toString() === selectedFormulaId?.toString());
          // setSelectedFormulaOption(option || null);
        })
        .catch((err) => {
          alertMessage(t("general.SomethingWentWrong ", "Something went wrong"), "error", err);
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  }, [selectedSeries, includeCompany, includeMaster, includeUser, searchText]);

  function setFormulaDetails(formulaId: number) {
    setIsLoadingFormula(true);
    axiosServices
      .get(`/api/Formula/${formulaId}`)
      .then((res) => {
        console.log({ formula: res.data });
        setSelectedFormula(res.data);
        setBoxColor(res.data.rgbColors);
      })
      .catch((err) => {
        alertMessage(t("general.SomethingWentWrong ", "Something went wrong"), "error", err);
      })
      .finally(() => {
        setIsLoadingFormula(false);
      });
  }

  useEffect(() => {
    if (selectedFormulaId) {
      setFormulaDetails(selectedFormulaId);
    } else {
      setSelectedFormula(null);
      setBoxColor(null);
    }
  }, [selectedFormulaId]);

  const columns = useMemo<
    ColumnDef<{
      componentName: string;
      componentDescription: string;
      amount: number;
      amountInGrams: number;
    }>[]
  >(
    () => [
      {
        header: t("formulation.ProductCode", "Product Code"),
        accessorKey: "componentName",
        dataType: "text",
      },
      {
        header: t("formulation.Description", "Description"),
        accessorKey: "componentDescription",
        dataType: "text",
      },
      {
        header: t("formulation.Percentage", "Percentage"),
        accessorKey: "amount",
        dataType: "amount",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.amount, 3, 0)}%</>;
        },
      },
      {
        header: t("formulation.Amount", "Amount"),
        accessorKey: "amountInGrams",
        dataType: "text",
        cell: ({ row }) => {
          return <>{formatNo(row.original?.amountInGrams, 3, 0)} g</>;
        },
      },
    ],
    []
  );

  const columnsForMobile = useMemo<
    ColumnDef<{
      componentName: string;
      componentDescription: string;
      amount: number;
      amountInGrams: number;
    }>[]
  >(
    () => [
      {
        header: t("formulation.Product", "Product"),
        accessorKey: "componentName",
        dataType: "text",
        accessorFn: (originalRow) => {
          return `${originalRow.componentName} - ${originalRow.componentDescription}`;
        },
      },
      {
        header: t("formulation.Amount", "Amount"),
        accessorKey: "amount",
        dataType: "amount",
        cell: ({ row }) => {
          return (
            <>
              {formatNo(row.original?.amount, 3, 0)}% ({formatNo(row.original?.amountInGrams, 3, 0)} g)
            </>
          );
        },
      },
    ],
    []
  );

  function setWeightFromPredefined(data: number) {
    const ele = document.getElementById("weight-input") as HTMLInputElement;
    if (ele) ele.value = data.toString();
    setweightValue(data);
    trackWeight(data);
  }

  function getDisplayFormulaSeriesData() {
    const displayComponents: FormulaComponentItem[] = [];
    if (!selectedFormula) {
      return displayComponents;
    }

    const selectedFormulaComponents: FormulaComponentItem[] = selectedFormula?.components as FormulaComponentItem[];
    let components = JSON.parse(JSON.stringify(selectedFormulaComponents));
    if (components) {
      for (let j = 0; j < components.length; j++) {
        components[j].amountInGrams = ((components[j].amount * weightValue) / 100).toFixed(2);
        displayComponents.push(components[j]);
      }
    }
    return displayComponents?.filter((item) => item.isDeleted === false);
  }

  const trackWeight = useCallback(
    debounce((weight: number) => {
      if (!selectedFormulaId) return;

      axiosServices
        .post("/api/userActionRecord/selectedWeight", {
          formulaId: selectedFormulaId,
          weight: weight,
        })
        .catch((err) => {
          alertMessage("Something went wrong ", "error", err);
        });
    }, 2000),
    [selectedFormulaId]
  );

  function logFormulaChange(formulaId: string) {
    axiosServices
      .post("/api/userActionRecord/selectedFormula", {
        formulaId: formulaId,
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error", err);
      });
  }

  const handleChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newWeight = e.target.value === "" ? 0 : parseInt(e.target.value);
    setweightValue(newWeight);
    trackWeight(newWeight);
  };

  const buttonStyles = {
    whiteSpace: "nowrap",
    minWidth: "max-content",
  };

  return (
    <MainLayout>
      <Box>
        {showPrintModal && (
          <Modal
            open={showPrintModal}
            onClose={() => setShowPrintModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showPrintModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <PrintFormulaModal
                    data={getDisplayFormulaSeriesData() as FormulaComponentItem[]}
                    weight={weightValue}
                    formula={selectedFormula}
                    series={selectedSeries!}
                    showPrintModal={showPrintModal}
                    setShowPrintModal={setShowPrintModal}
                    endpoints={FormulaEndpoints}
                    closeModal={() => setShowPrintModal(false)}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
        {showConfirmDeductFormulaModal && (
          <Modal
            open={showConfirmDeductFormulaModal}
            onClose={() => setShowConfirmDeductFormulaModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showConfirmDeductFormulaModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <ConfirmDeductFromInventory
                    data={getDisplayFormulaSeriesData() as FormulaComponentItem[]}
                    weight={weightValue}
                    formula={selectedFormula}
                    series={selectedSeries!}
                    closeModal={() => setShowConfirmDeductFormulaModal(false)}
                  />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}

        {showShareFormulaModal && (
          <Modal
            open={showShareFormulaModal}
            onClose={() => setShowShareFormulaModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
            sx={{
              mt: "2%",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Fade in={showShareFormulaModal}>
              <Box sx={modalStyle}>
                <Grid container>
                  <ShareFormulaModal weight={weightValue} formulaId={selectedFormulaId || 0} closeModal={() => setShowShareFormulaModal(false)} />
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
      </Box>

      <Box sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2">Formulation</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>

      <Grid item container xs={12}>
        <Grid item container sm={12} spacing={1}>
          <Grid item xs={12} sm>
            <Stack spacing={1}>
              <InputLabel htmlFor="series">{t("formulation.Series", "Series")}</InputLabel>
              {series && (
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combo-box-demo"
                  options={series as SelectSeriesType[]}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disableClearable={true}
                  // defaultValue={selectedSeries}
                  // inputValue={selectedSeries?.value as string}
                  value={selectedSeries as SelectSeriesType}
                  onChange={(_e, newValue) => {
                    setSelectedFormulaId(null);
                    setSelectedFormulaOption(null);
                    // setSelectedComponent(null)
                    setSelectedSeries(newValue as SelectSeriesType);
                  }}
                  // sx={{ width: 300 }}
                  getOptionLabel={(label) => label.text}
                  getOptionKey={(label) => label.value}
                  renderInput={(params: any) => <TextField fullWidth {...params} placeholder="Select Series" />}
                />
              )}
            </Stack>
          </Grid>
          {/* <Grid item container xs={12} sm={8}> */}
          <Grid item>
            <Stack spacing={1}>
              <InputLabel>{t("formulation.IncludeTypes", "Include Types")}</InputLabel>
              <ButtonGroup>
                {/* TODO: create theme for custom color #509bc5 secondary or #95afe8 or #476bba main */}
                {/* TODO: style with no elevation */}
                <Button variant={includeMaster ? "contained" : "outlined"} color="primary" onClick={() => setIncludeMaster(!includeMaster)}>
                  {t("formulation.Master", "Master")}
                </Button>
                <Button variant={includeCompany ? "contained" : "outlined"} color="primary" onClick={() => setIncludeCompany(!includeCompany)}>
                  {t("formulation.Company", "Company")}
                </Button>
                <Button variant={includeUser ? "contained" : "outlined"} color="primary" onClick={() => setIncludeUser(!includeUser)}>
                  {t("formulation.User", "User")}
                </Button>
              </ButtonGroup>
            </Stack>
          </Grid>
          {/* </Grid> */}
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="formula">{t("formulation.Formula", "Formula")}</InputLabel>
              {/* {selectedFormulaOption?.value} */}
              {formulas && (
                <Autocomplete
                  fullWidth
                  id="combo-box-demo1"
                  getOptionLabel={(label) => label.text}
                  options={formulaOptions}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  value={selectedFormulaOption as FormulaListItemType}
                  loading={isLoadingOptions}
                  onInputChange={(_event, newInputValue) => {
                    setSearchText(newInputValue);
                    // loadFormulaOptions(newInputValue);
                  }}
                  disableClearable={true}
                  onChange={(_e, newValue) => {
                    const formulaCmp = formulas?.find((item) => item.id === parseInt(newValue!.value as string));
                    setSelectedFormulaId(parseInt(newValue?.value as string));
                    setSelectedFormulaOption(newValue as FormulaListItemType);
                    setBoxColor(formulaCmp?.rgbColors || null);
                    logFormulaChange(newValue?.value);
                  }}
                  renderOption={(props, option) => {
                    const formulaCmp = formulas?.find((item) => item.id === parseInt(option.value as string));
                    const x: any = props;
                    delete x["key"];
                    return (
                      <Fragment key={`a${option!.value}`}>
                        {formulaCmp && (
                          <Box {...x} sx={{ cursor: "pointer" }} component="li" display="inline">
                            <Stop sx={{ fontSize: "30px", color: `${formulaCmp?.rgbColors}` }} />
                            {option.text}
                            &nbsp;
                            {formulaCmp && (
                              <FormulaChip masterFormula={formulaCmp?.masterFormula} shareAcrossCompany={formulaCmp.shareAcrossCompany} />
                            )}
                          </Box>
                        )}
                      </Fragment>
                    );
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      fullWidth
                      {...params}
                      placeholder={t("formulation.SelectFormula", "Select a formula")}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Stop sx={{ fontSize: "30px", color: `${selectedFormula?.rgbColors}` }} />

                            {selectedFormula && (
                              <FormulaChip
                                masterFormula={selectedFormula?.masterFormula}
                                shareAcrossCompany={selectedFormula.shareAcrossCompany}
                              ></FormulaChip>
                            )}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <>
                            {isLoadingOptions ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    ></TextField>
                  )}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item sm={12} sx={{ pt: 2, pb: 1 }}>
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
              <Tooltip title={t("formulation.PrintFormula", "Print Formula")} placement="top">
                <span>
                  <Button
                    sx={buttonStyles}
                    size="small"
                    variant="contained"
                    onClick={() => setShowPrintModal(true)}
                    disabled={!selectedFormulaOption || isLoadingFormula}
                    startIcon={<Print />}
                  >
                    {t("formulation.Print", "Print")}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={t("formulation.DeductInventory", "Deduct from Inventory")} placement="top">
                <span>
                  <Button
                    sx={buttonStyles}
                    size="small"
                    variant="contained"
                    onClick={() => setShowConfirmDeductFormulaModal(true)}
                    disabled={!selectedFormulaOption || isLoadingFormula}
                    startIcon={<MoveDown />}
                  >
                    {t("formulation.DeductInventory", "Deduct Inventory")}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={t("formulation.ShareByEmail", "Share by Email")} placement="top">
                <span>
                  <Button
                    sx={buttonStyles}
                    size="small"
                    variant="contained"
                    onClick={() => setShowShareFormulaModal(true)}
                    disabled={!selectedFormulaOption || isLoadingFormula}
                    startIcon={<Email />}
                  >
                    {t("formulation.EmailFormula", "Email Formula")}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item container sm={12} spacing={1}>
          {(formulas || series) && !isLoading && !isLoadingFormula ? (
            <>
              <Grid item xs={12} sm={9}>
                <DisplayTable
                  columns={isMobile ? columnsForMobile : columns}
                  showPagination={false}
                  defaultSorting={[{ id: "amount", desc: true }]}
                  data={getDisplayFormulaSeriesData()}
                  showAddBtn={false}
                />
                <Grid container>
                  <Grid item>
                    <Box
                      sx={{
                        mt: "20px",
                        boxShadow: "0px 1px 5px black",
                        backgroundColor: boxColor,
                        height: "100px",
                        width: "150px",
                        borderRadius: "5px",
                      }}
                    ></Box>
                  </Grid>
                  <Grid item sx={{ p: 3 }}>
                    {selectedFormula && (
                      <Stack direction="column" spacing={1}>
                        <span>
                          <FormulaChip
                            masterFormula={selectedFormula?.masterFormula}
                            shareAcrossCompany={selectedFormula.shareAcrossCompany}
                            longText
                          ></FormulaChip>
                        </span>

                        {selectedFormula?.masterFormula === false && (
                          // isAdmin() && // TODO: uncomment this to avoid showing when is a company user
                          <Typography variant="body2">
                            {t("formulation.Company", "Company")}: {selectedFormula.companyName}
                          </Typography>
                        )}
                        {selectedFormula?.masterFormula === false && selectedFormula?.shareAcrossCompany === false && (
                          <Typography variant="body2">
                            {t("formulation.Location", "Location")}: {selectedFormula.locationName}
                          </Typography>
                        )}
                        <Typography variant="body2">{t("formulation.References", "References")}:</Typography>
                        <ul
                          style={{
                            margin: 0,
                            paddingLeft: 20,
                          }}
                        >
                          {selectedFormula?.description?.split("\n").map((r, index) => (
                            <li key={index}>
                              <Typography variant="body2">{r}</Typography>
                            </li>
                          ))}
                        </ul>
                        <Typography variant="body2">
                          {t("formulation.createdBy", "Created by")} <i>{selectedFormula?.createdByFullName}</i> {t("general.on", "on")}{" "}
                          <i>{formatDate(selectedFormula?.dateCreated || "", "MMMM d, yyyy")}</i>
                          <br />
                          {selectedFormula?.updatedBy && (
                            <span>
                              {t("formulation.lastUpdatedBy", "Last updated by")} <i>{selectedFormula?.updatedByFullName}</i> {t("general.on", "on")}{" "}
                              <i>{formatDate(selectedFormula?.lastUpdated || "", "MMMM d, yyyy")}</i>
                            </span>
                          )}
                        </Typography>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack spacing={1}>
                  <FormHelperText sx={{ fontSize: "15px" }} id="outlined-weight-helper-text">
                    {t("formulation.Weight", "Weight")}
                  </FormHelperText>
                  <OutlinedInput
                    id="weight-input"
                    type="number"
                    defaultValue={100}
                    onChange={handleChangeWeight}
                    endAdornment={<InputAdornment position="end">g</InputAdornment>}
                    aria-describedby="outlined-weight-text"
                    sx={{ fontSize: 20 }}
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />

                  <Grid item xs={12} sm={12}>
                    <ButtonGroup variant="contained" color="primary" fullWidth>
                      {predefinedWeights.length > 0 &&
                        predefinedWeights.map((value, index) => (
                          <Button onClick={() => setWeightFromPredefined(value.weight)} key={index} className="weight-button">
                            {value.label}
                          </Button>
                        ))}
                    </ButtonGroup>
                  </Grid>
                </Stack>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Box sx={{ p: 5 }}>
                <Stack direction="row" justifyContent="center">
                  <CircularWithPath />
                </Stack>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MainLayout>
  );
}
