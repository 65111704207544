import React from "react";
import Chip from "@mui/material/Chip";
import { PurchaseOrderType } from "../AllTypes";
import { Tooltip } from "@mui/material";

interface PurchaseOrderStatusChipProps {
  statusName?: string;
  isCentral?: boolean;
}

const PurchaseOrderStatusChip: React.FC<PurchaseOrderStatusChipProps> = ({ statusName, isCentral }) => {
  let backgroundColor = "gray";
  let label = statusName;
  let description = "";

  if (statusName?.toLowerCase() === "submitted") {
    backgroundColor = "blue";
    description = "PO has been submitted and is awaiting approval from the Purchasing Agent.";
  } else if (statusName?.toLowerCase() === "approved") {
    backgroundColor = "orange";
    label = isCentral ? "Pending" : "PO Sent";
    description = "PO has been approved and sent to Image Technology and is awaiting confirmation.";
  } else if (statusName?.toLowerCase() === "rejected") {
    backgroundColor = "red";
    description = "PO has been rejected by the Purchasing Agent. Must be modified to be submitted for approval.";
  } else if (statusName?.toLowerCase() === "confirmed") {
    backgroundColor = "green";
    description = "PO has been confirmed by Image Technology.";
  } else if (statusName?.toLowerCase() === "cancelled") {
    backgroundColor = "indianred";
    description = "PO has been cancelled.";
  }

  return (
    <Tooltip title={description} placement="top">
      <Chip size="small" color="primary" sx={{ backgroundColor }} label={label}></Chip>
    </Tooltip>
  );
};

export default PurchaseOrderStatusChip;
