// import React from 'react';
import { Route, Link, Routes, useMatch } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

import StockLevels from "./StockLevels";
import InventoryTransactions from "./InventoryTransactions";
import StockEntries from "./StockEntries";
import ComponentCosts from "./ComponentCosts";

// assets
import { Box, Grid } from "@mui/material";
import React from "react";

import MainLayout from "../main-layout";

export default function Inventory() {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <Grid item container xs={12} spacing={1}>
        <Grid item sm={12}>
          <div>
            <Routes>
              <Route path="stockLevels" element={<StockLevels />} />
              <Route path="stockEntries" element={<StockEntries />} />
              <Route path="componentCosts" element={<ComponentCosts />} />
              <Route path="inventoryTransactions" element={<InventoryTransactions />} />
              <Route path="/" element={<div>{t("inventory.selectPage", "Please select a page.")}</div>} />
            </Routes>
          </div>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
