import { useEffect, useState, Dispatch, SetStateAction } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik, FormikProvider, useFormik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Stack, Tooltip, Typography, Switch, Select, MenuItem, Chip, Checkbox, ListItemText } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { ComponentSubcategoryType } from "../../AllTypes";
import { alertMessage } from "../pages-helpers/AlertMessage";
import axiosServices from "../../utils/axios";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  componentSubcategoryId: number;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddComponentSubcategoryModal({ isEditing, setIsEditing, componentSubcategoryId, toggleRefreshData, closeModal }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);

  useEffect(() => {
    if (componentSubcategoryId) {
      setLoading(true);
      axiosServices
        .get(`/api/componentSubcategory/${componentSubcategoryId}`)
        .then((res) => {
          let values = res.data;
          setValues(values);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("general.NameRequired", "Name is required")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const initialValues = {
    id: 0,
    name: "",
    isActive: true,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let component = {
          ...values,
        };

        axiosServices
          .post("/api/componentSubcategory", component)
          .then(() => {
            alertMessage(t("componentSubcategory.ComponentSubcategoryUpdatedSuccessfully", "Component subcategory updated successfully"), "success");
            handleAlertClose();
          })
          .catch((err) => {
            alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error", err);
            setSubmitting(false);
          });
      } catch (error) {
        alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, setValues, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  if (loading) {
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
          <DialogTitle>
            <Typography variant="h4" component="span">
              {tempIsEditing
                ? t("componentSubcategory.EditComponentSubcategory", "Edit Component Subcategory")
                : t("componentSubcategory.AddComponentSubcategory", "Add Component Subcategory")}
            </Typography>
          </DialogTitle>
          <Tooltip title={t("general.Close", "Close")}>
            <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <DialogContent sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">{t("general.Name", "Name")}</InputLabel>
                    <TextField
                      fullWidth
                      id="name"
                      placeholder={t("general.Name", "Name")}
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <FormControlLabel
                      control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />}
                      label={t("general.active", "Active")}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button color="secondary" onClick={closeModal}>
            {t("general.Cancel", "Cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("general.Save", "Save")}
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
