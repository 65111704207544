import { useEffect, useState, Dispatch, SetStateAction } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project imports
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { CompanyType, EndpointsType } from "../../../AllTypes";
import { insert, updateUsingPut } from "../../../api/generalRoute";
import { alertMessage } from "../../pages-helpers/AlertMessage";

// Import the useTranslation hook
import useTranslation from "../../../hooks/useTranslation";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  editingData: CompanyType;
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddCompanyModal({ isEditing, setIsEditing, editingData, endpoints, toggleRefreshData, closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);

  // Inside the component, add:
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(false);
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("company.nameRequired", "Name is required")),
    shortName: Yup.string().max(255).required(t("company.shortNameRequired", "Short Name is required")),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, t("company.invalidEmail", "Email Invalid"))
      .required(t("company.emailRequired", "Email is required"))
      .max(40),
    phone: Yup.string().matches(/^[0-9]{10}$/, t("company.phoneFormat", "Phone no. must be of exactly 10 digits")),
    city: Yup.string().required(t("company.cityRequired", "City is required")),
    state: Yup.string().required(t("company.stateRequired", "State is required")),
    country: Yup.string().required(t("company.countryRequired", "Country is required")),
    zip: Yup.string().required(t("company.zipRequired", "Zip Code is required")),
    address: Yup.string().required(t("company.addressRequired", "Address is required")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const companyInitialValues = {
    name: tempIsEditing && editingData ? editingData.name : "",
    shortName: tempIsEditing && editingData ? editingData.shortName : "",
    email: tempIsEditing && editingData ? editingData.email : "",
    phone: tempIsEditing && editingData ? editingData.phone : "",
    city: tempIsEditing && editingData ? editingData.city : "",
    state: tempIsEditing && editingData ? editingData.state : "",
    country: tempIsEditing && editingData ? editingData.country : "",
    zip: tempIsEditing && editingData ? editingData.zip : "",
    address: tempIsEditing && editingData ? editingData.address : "",
    address2: tempIsEditing && editingData ? editingData.address2 : "",
    isActive: tempIsEditing && editingData ? editingData.isActive : true,
  };

  const formik = useFormik({
    initialValues: companyInitialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values: CompanyType, { setSubmitting }) => {
      try {
        if (values.isActive === "true") values.isActive = true;
        if (values.isActive === "false") values.isActive = false;

        if (tempIsEditing && editingData) {
          updateUsingPut(endpoints, editingData.id?.toString(), values)
            .then(() => {
              alertMessage(t("company.updateSuccess", "Company updated successfully"), "success");
              handleAlertClose();
            })
            .catch((err) => {
              alertMessage(t("general.error", "Something went wrong"), "error", err);
              setSubmitting(false);
            });
        } else {
          insert(endpoints, values)
            .then(() => {
              alertMessage(t("company.addSuccess", "Company added successfully"), "success");
              handleAlertClose();
              setSubmitting(false);
            })
            .catch((err) => {
              alertMessage(t("general.error", "Something went wrong"), "error", err);
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage(t("general.error", "Something went wrong"), "error");
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Box sx={{ maxHeight: "89vh", width: "85vw" }}>
      <FormikProvider value={formik}>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography fontSize="25px" fontWeight="bold">
                {tempIsEditing ? t("company.editTitle", "Edit Company") : t("company.addTitle", "Add Company")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <TextField
                        fullWidth
                        id="name"
                        placeholder="Enter Name"
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="shortName">Short Name</InputLabel>
                      <TextField
                        fullWidth
                        id="shortName"
                        placeholder="Short Name"
                        {...getFieldProps("shortName")}
                        error={Boolean(touched.shortName && errors.shortName)}
                        helperText={touched.shortName && errors.shortName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        type="email"
                        placeholder="Enter Email"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <TextField
                        fullWidth
                        id="phone"
                        placeholder="Enter Phone"
                        {...getFieldProps("phone")}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address">Address Line 1</InputLabel>
                      <TextField
                        fullWidth
                        id="address"
                        multiline
                        rows={2}
                        placeholder="Enter Address"
                        {...getFieldProps("address")}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                      <TextField
                        fullWidth
                        id="address2"
                        multiline
                        rows={2}
                        placeholder="Enter Address (Optional)"
                        {...getFieldProps("address2")}
                        error={Boolean(touched.address2 && errors.address2)}
                        helperText={touched.address2 && errors.address2}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <TextField
                        fullWidth
                        id="city"
                        placeholder="Enter City"
                        {...getFieldProps("city")}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <TextField
                        fullWidth
                        id="state"
                        placeholder="Enter State"
                        {...getFieldProps("state")}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="country">Country</InputLabel>
                      <TextField
                        fullWidth
                        id="country"
                        placeholder="Enter Country"
                        {...getFieldProps("country")}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="zip">Zip Code</InputLabel>
                      <TextField
                        fullWidth
                        id="zip"
                        placeholder="Enter Zip Code"
                        {...getFieldProps("zip")}
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>{}</Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                      <Stack direction="row" alignItems="center">
                        <InputLabel>Status :</InputLabel>
                        <RadioGroup {...getFieldProps("isActive")} aria-label="type" name="isActive" row>
                          <Box sx={{ fontSize: "20px", ml: 2 }}>
                            <FormControlLabel value="true" control={<Radio />} label="Active" />
                            <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                          </Box>
                        </RadioGroup>
                      </Stack>
                      <Box>
                        <Button color="secondary" onClick={closeModal}>
                          {t("general.cancel", "Cancel")}
                        </Button>
                        <Button type="submit" variant="contained" disabled={isSubmitting}>
                          {t("general.save", "Save")}
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* <Divider /> */}
          {/* <DialogActions sx={{ p: 2.5, pt: 0 }}> */}
          {/* <Grid item> */}
          {/* <Stack direction="row"> */}

          {/* </Stack> */}
          {/* </Grid> */}
          {/* </DialogActions> */}
        </Form>
        {/* </LocalizationProvider> */}
      </FormikProvider>
    </Box>
  );
}
