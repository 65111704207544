import { useEffect, useState, Dispatch, SetStateAction } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik, FormikProvider, useFormik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Stack, Tooltip, Typography, Switch, Select, MenuItem, Chip, Checkbox, ListItemText } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { ProductCategoryType, ProductSubcategoryType, ProductType, UnitOfMeasureType } from "../../AllTypes";
import { alertMessage } from "../pages-helpers/AlertMessage";
import axiosServices from "../../utils/axios";
import axios from "axios";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  productId: number;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddProductModal({ isEditing, setIsEditing, productId, toggleRefreshData, closeModal }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategoryType[]>([]);
  const [productSubcategories, setProductSubcategories] = useState<ProductSubcategoryType[]>([]);

  useEffect(() => {
    setLoading(true);

    if (productId) {
      axiosServices
        .get(`/api/product/${productId}`)
        .then((res) => {
          let values = res.data;
          values.unitsOfMeasure = values.unitsOfMeasure.map((unit: any) => unit.unitOfMeasureId);

          setValues(values);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error", err);
        });
    }

    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axiosServices
      .get("/api/productCategory")
      .then((res) => {
        setProductCategories(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });

    axiosServices
      .get("/api/productSubcategory")
      .then((res) => {
        setProductSubcategories(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  const ItemSchema = Yup.object().shape({
    code: Yup.string().max(255).required(t("formulation.ProductCodeRequired", "Product Code is required")),
    description: Yup.string().max(255).required(t("formulation.DescriptionRequired", "Product Description is required")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const initialValues = {
    id: 0,
    code: "",
    description: "",
    categoryId: null,
    subcategoryId: null,
    defaultUnitOfMeasureId: null,
    isActive: true,
    unitsOfMeasure: [] as number[],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let product = {
          ...values,
          unitsOfMeasure: Array.from(new Set(values.unitsOfMeasure)).map((id) => ({ unitOfMeasureId: id })),
        };

        if (values.defaultUnitOfMeasureId === 0) {
          product.defaultUnitOfMeasureId = null;
        }

        if (values.categoryId === 0) {
          product.categoryId = null;
        }
        if (values.subcategoryId === 0) {
          product.subcategoryId = null;
        }

        axiosServices
          .post("/api/product", product)
          .then(() => {
            alertMessage(t("formulation.ProductUpdatedSuccessfully", "Product updated successfully"), "success");
            handleAlertClose();
          })
          .catch((err) => {
            alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error", err);
            setSubmitting(false);
          });
      } catch (error) {
        alertMessage(t("general.SomethingWentWrong", "Something went wrong"), "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, setValues, handleSubmit, isSubmitting, getFieldProps, values } = formik;

  if (loading) {
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
          <DialogTitle>
            <Typography variant="h4" component="span">
              {tempIsEditing ? t("product.EditProduct", "Edit Product") : t("product.AddProduct", "Add Product")}
            </Typography>
          </DialogTitle>
          <Tooltip title={t("general.Close", "Close")}>
            <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <DialogContent sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="code">{t("formulation.ProductCode", "Product Code")}</InputLabel>
                    <TextField
                      fullWidth
                      id="code"
                      placeholder={t("formulation.ProductCode", "Product Code")}
                      {...getFieldProps("code")}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="description">{t("formulation.Description", "Description")}</InputLabel>
                    <TextField
                      fullWidth
                      id="description"
                      placeholder={t("formulation.Description", "Description")}
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="category">{t("product.Category", "Category")}</InputLabel>
                    <Select fullWidth id="category" placeholder={t("product.Category", "Category")} {...getFieldProps("categoryId")}>
                      <MenuItem key="-1" value={0}>
                        {t("general.none", "None")}
                      </MenuItem>
                      {productCategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="subcategory">{t("product.Subcategory", "Subcategory")}</InputLabel>
                    <Select fullWidth id="subcategory" placeholder={t("product.Subcategory", "Subcategory")} {...getFieldProps("subcategoryId")}>
                      <MenuItem key="-1" value={0}>
                        {t("general.none", "None")}
                      </MenuItem>
                      {productSubcategories.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="unitsOfMeasure">{t("inventory.unitsOfMeasure", "Units of Measure")}</InputLabel>
                    <Select
                      fullWidth
                      id="unitsOfMeasure"
                      multiple
                      placeholder={t("inventory.unitOfMeasureDefault", "Unit of Measure (default)")}
                      {...getFieldProps("unitsOfMeasure")}
                      value={values.unitsOfMeasure}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {unitsOfMeasure
                            .filter((unit) => selected.indexOf(unit.id) > -1)
                            .map((unit) => (
                              <Chip key={unit.id} label={unit.name} />
                            ))}
                        </Box>
                      )}
                    >
                      {unitsOfMeasure.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          <Checkbox checked={values.unitsOfMeasure.indexOf(unit.id) > -1} />
                          <ListItemText primary={unit.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="unitOfMeasure">{t("inventory.unitOfMeasureDefault", "Unit of Measure (Default)")}</InputLabel>
                    <Select
                      fullWidth
                      id="defaultUnitOfMeasureId"
                      placeholder={t("inventory.unitOfMeasureDefault", "Unit of Measure (default)")}
                      {...getFieldProps("defaultUnitOfMeasureId")}
                    >
                      <MenuItem key="-1" value={0}>
                        {t("general.none", "None")}
                      </MenuItem>
                      {unitsOfMeasure.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <FormControlLabel
                      control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />}
                      label={t("general.active", "Active")}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2.5 }}>
          <Button color="secondary" onClick={closeModal}>
            {t("general.Cancel", "Cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("general.Save", "Save")}
          </Button>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
