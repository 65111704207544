// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { formatDate } from "../../utils/formatDate";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { UserProfile } from "../../types/auth";
import { FormulaCreationType, InventoryTransactionType, LocationType, SelectCompanyType } from "../../AllTypes";
import MainLayout from "../main-layout";
import { useNavigate } from "react-router-dom";
import { ArrowBack, ArrowLeft, Info } from "@mui/icons-material";
import ViewFormulaCreationRecordModal from "./ViewFormulaCreationRecordModal";
import useTranslation from "../../hooks/useTranslation";

// TODO: formatNo on separate file
function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

export default function FormulaCreationLog() {
  const { user: currentUser } = useAuth();
  const [transactions, setTransactions] = useState<InventoryTransactionType>();
  const [loadingStock, setLoadingStock] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [formulaCreationRecord, setFormulaCreationRecord] = useState<FormulaCreationType>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error");
      });
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    setLoadingStock(true);
    axiosServices
      .get(`/api/formula/formulaCreationLog?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`)
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error");
      })
      .finally(() => {
        setLoadingStock(false);
      });
  }, [selectedCompanyId, selectedLocationId]);

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  const columns = useMemo<ColumnDef<FormulaCreationType>[]>(
    () => [
      {
        header: t("formulation.formula", "Formula"),
        accessorKey: "formulaName",
        dataType: "text",
      },
      {
        header: t("formulation.dateCreated", "Date Created"),
        accessorKey: "dateCreated",
        dataType: "date",
        cell: ({ row: { original } }) => {
          return formatDate(original?.dateCreated || "", "PPpp");
        },
      },
      {
        header: t("formulation.createdBy", "Created By"),
        accessorKey: "createdByFullName",
        dataType: "string",
      },
      {
        header: t("formulation.weightInGrams", "Weight (g)"),
        accessorKey: "weightInGrams",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{formatNo(row.original?.weightInGrams)}</>;
        },
      },
      {
        header: t("formulation.recycledFrom", "Recycled From"),
        accessorKey: "recycledFromName",
        dataType: "text",
      },
      {
        header: t("formulation.deductedFromInventory", "Deducted from Inventory"),
        accessorKey: "deductedFromInventory",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{row.original?.deductedFromInventory && t("general.deducted", "Deducted")}</>;
        },
      },
      {
        header: t("general.printed", "Printed"),
        accessorKey: "printed",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{row.original?.printed && t("general.printed", "Printed")}</>;
        },
      },
      {
        header: t("general.emailed", "Emailed"),
        accessorKey: "emailed",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{row.original?.emailed && t("general.emailedTo", "Emailed ({{sharedTo}})", { sharedTo: row.original.sharedTo })}</>;
        },
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={t("general.viewDetails", "View Details")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFormulaCreationRecord(row.original);
                    setOpenModal(true);
                  }}
                >
                  <Info />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    [t]
  );

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">{t("general.company", "Company")}</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">Company</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">{t("general.location", "Location")}</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">Location</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography variant="h2">{t("formulation.formulaCreationLog", "Formula Creation Log")}</Typography>
            </Box>

            <Button size="small" onClick={() => navigate("/formulation")} color="inherit" startIcon={<ArrowLeft />}>
              {t("general.back", "Back")}
            </Button>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <CompanyInput></CompanyInput>
        </Grid>
        <Grid item sm={6}>
          <LocationInput></LocationInput>
        </Grid>

        <Grid item sm={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("general.search", "Search...")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        {transactions && (
          <Grid item sm={12}>
            <DisplayTable
              columns={columns}
              data={transactions}
              defaultSorting={[{ id: "dateCreated", desc: true }]}
              search={search}
              showAddBtn={true}
            />
          </Grid>
        )}
      </Grid>
      {loadingStock && !transactions && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loadingStock && !transactions && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">
            {t("formulation.selectLocationToViewTransactions", "Select a location to view inventory transactions.")}
          </Typography>
        </Box>
      )}

      <Box>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={openModal}>
            <Box sx={modalStyle}>
              <Grid container>
                <ViewFormulaCreationRecordModal
                  closeModal={() => {
                    setOpenModal(false);
                  }}
                  formulaCreationRecord={formulaCreationRecord}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </MainLayout>
  );
}
