// TODO: refactor structure
export const SeriesEndpoints = {
  get: "/api/Series",
  getForSelect: "/api/Series/GetAllSeries",
  update: (id: string) => `/api/Series/${id}`,
  delete: (id: string) => `/api/Series/${id}`,
};

export const InkEndpoints = {
  get: "/api/Component",
  getForSelect: "/api/Component/GetAllComponents",
  update: (id: string) => `/api/Component/${id}`,
  delete: (id: string) => `/api/Component/${id}`,
};

// TODO: refator all calls
export const CompanyEndpoints = {
  get: "/api/Company",
  getForSelect: "/api/Company/GetAllCompanies",
  getCompanyInfo: (id: string) => `/api/Company/getCompanyInfo?id=${id}`,
  update: (id: string) => `/api/Company/${id}`,
  delete: (id: string) => `/api/Company/Id?companyId=${id}`,
};

export const LocationEndpoints = {
  get: "/api/Location",
  getByCompanyId: (id: number) => `/api/Location/GetLocationsByCompanyId?CompanyId=${id}`,
  update: (id: string) => `/api/Location/${id}`,
  delete: (id: string) => `/api/Location/${id}`,
};

export const FormulaEndpoints = {
  get: "/api/Formula",
  getFormulaComponent: (id: number) => `/api/Formula/api/FormulaComponent/SeriesId?SeriesId=${id}`,
  getById: (id: string) => `/api/Formula/${id}`,
  update: (id: string) => `/api/Formula/${id}`,
  delete: (id: string) => `/api/Formula/${id}`,
};

export const AccountEndpoints = {
  get: "/api/Account",
  getUserInfo: `/api/Account/getUserInfo`,
  update: (id: string) => `/api/Account/${id}`,
  delete: (id: string) => `/api/Account/${id}`,
};

export const LoginEndpoints = {
  login: "/login",
};

export const ResetPasswordEndpoints = {
  changePassword: "/api/login/changepassword",
};

export const VerifyTokenEndpoints = {
  query: (data: string) => `/api/login/VerifyToken?token=${data}`,
};

export const ForgotPasswordEndpoints = {
  query: (data: string) => `/api/login/ForgotPasswordAsync?email=${data}`,
};
