import { useEffect, useState } from "react";
import useConfig from "../../../hooks/useConfig";
import { ThemeMode } from "../../../config";
import { Box, Fade, Grid, IconButton, Modal, Tooltip } from "@mui/material";
import { FeedbackOutlined } from "@mui/icons-material";
import { Backdrop } from "@mui/material";
import AddFeedbackModal from "./AddFeedbackModal";
import useTranslation from "../../../hooks/useTranslation";

const UserFeedback = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <>
      <Box>
        <Tooltip title={t("userFeedback.giveFeedback", "Give Feedback")}>
          <IconButton onClick={() => setOpen(true)}>
            <FeedbackOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddFeedbackModal closeModal={() => setOpen(false)} />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
};

export default UserFeedback;
