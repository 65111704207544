import { Box, Breadcrumbs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import MainCard from "../../components/MainCard";
import { Theme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "../../config";
import { getRoleAccessMenu } from "../../utils/userPermissions";
import { useAppSelector } from "../../redux/hook";
import { UserTypeRedux } from "../../types/user";
import useTranslation from "../../hooks/useTranslation";

const drawerWidth = DRAWER_WIDTH;
const appBarHeight = APP_BAR_HEIGHT;
export const ChangingContent = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  marginTop: `${appBarHeight - 10}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0),
  },
}));

const MainLayout = ({ children }: { children: ReactNode }) => {
  const path = useLocation().pathname.trim();
  const [isAuth, setisAuth] = useState(false);
  const routes: string[] = path.split("/").filter(Boolean);
  const user: UserTypeRedux = useAppSelector(({ user }) => user.userinfo as UserTypeRedux);
  const authMenu: any = getRoleAccessMenu(user?.userLevelName);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  // const data = localStorage.getItem('serviceToken')
  // useEffect(() => {
  //   if (!data) {
  //     navigate('/login');
  //     alertMessage("Unauthorized user", "error");
  //   }
  // }, [data, navigate])
  useEffect(() => {
    // TODO: refactor!!
    if (routes[0] === "ink-usage-calculator") {
      routes[0] = "inkcal";
    }
    let hasAccess = false;
    if (routes.length === 1) {
      if (authMenu[routes[0]]?.access) {
        hasAccess = true;
        setisAuth(true);
      }
    }
    if (routes.length >= 2 && authMenu[routes[0]]?.access && authMenu[routes[0]][routes[1]]?.access) {
      setisAuth(true);
      hasAccess = true;
    }

    if (!hasAccess) {
      console.log("redirected to 404");
      navigate("/404");
      return;
    }
  }, []);

  const pathname = useLocation().pathname;
  const open = true;

  function camelCaseToWords(str: string) {
    // {path.replace(path.charAt(0), path.charAt(0).toUpperCase())}
    return str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());
  }

  function kebabCaseToCapitalizedWords(str: string) {
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function getSectionName(name: string) {
    return kebabCaseToCapitalizedWords(camelCaseToWords(name));
  }

  return (
    <>
      {/* {isAuth? */}
      <ChangingContent open={open}>
        <MainCard shadow={(theme: Theme) => theme.customShadows.z1} content={false} sx={{ "& .MuiCardHeader-root": { p: 1.75 } }}>
          <Box
            sx={(theme) => ({
              // overflowX: "hidden",
              // overflowY: "scroll",
              pl: "5%",
              pt: "1.5%",
              pr: "5%",
              pb: "3%",
              width: "100%",
              minHeight: "85vh",
              [theme.breakpoints.down("sm")]: {
                pl: "3%",
                pt: "2.5%",
                pr: "3%",
              },
            })}
          >
            {!isMobile && (
              <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                {pathname.split("/").map((path, index) => {
                  return (
                    <Typography key={index} color="inherit">
                      {getSectionName(path)}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
            )}
            {children}
          </Box>
        </MainCard>
      </ChangingContent>
      {/* :<>{children}</>
  } */}
    </>
  );
};

export default MainLayout;
