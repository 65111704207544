import React, { MouseEvent, useEffect, useRef } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";
import { ColumnDef } from "@tanstack/react-table";
import IconButton from "../../components/@extended/IconButton";
import { Backdrop, Box, Chip, Fade, Grid, Modal, Stack, Typography, Button, TextField, InputAdornment, Checkbox } from "@mui/material";
import { useMemo, useState } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AddComponentModal from "./AddComponentModal";
import { ComponentType } from "../../AllTypes";
import DisplayTable, { DisplayTableHandle } from "../../components/DisplayTable";
import MainLayout from "../main-layout";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { InkEndpoints } from "../../AllLables";
import axiosServices from "../../utils/axios";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import useTranslation from "../../hooks/useTranslation";
import SetComponentsUnitsOfMeasureModal from "./SetComponentsUnitsOfMeasureModal";
import { set } from "date-fns";
import AtlasModal from "../../components/AtlasModal";

export default function ComponentList() {
  const { t } = useTranslation();
  const endpoints = InkEndpoints;
  const [search, setSearch] = useState("");
  const [components, setComponents] = useState<ComponentType[]>();
  const [open, setOpen] = useState(false);
  const [openConfigUnitsModal, setOpenConfigUnitsModal] = useState(false);
  const [selectedComponentIds, setSelectedComponentIds] = useState<number[]>([]);
  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const emptyState = { name: "", description: "", density: 0, isActive: true };
  const [selectedItem, setSelectedItem] = useState<ComponentType>(emptyState);
  const [itemDelete, setItemDelete] = useState<ComponentType>();
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const displayTable = useRef<DisplayTableHandle>(null);

  function afterDelete() {
    setDeleteAlert(false);
    setTimeout(setDataFunction, 500);
  }

  function setDataFunction() {
    axiosServices
      .get("/api/component?includeInactive=true")
      .then((res) => {
        setComponents(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error");
      });
  }

  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData]);

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  const columns = useMemo<ColumnDef<ComponentType>[]>(
    () => [
      {
        // select all checkbox
        header: ({ table }) => {
          return (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onChange={(e) => {
                table.toggleAllRowsSelected(e.target.checked);
              }}
            />
          );
        },

        accessorKey: "selected",
        dataType: "text",
        cell: ({ row }) => {
          // Select the row with a MUI checkbox
          return <Checkbox checked={row.getIsSelected()} onChange={(e) => row.toggleSelected(e.target.checked)} />;
        },
      },
      {
        header: t("general.name", "Name"),
        accessorKey: "name",
        dataType: "text",
      },
      {
        header: t("general.description", "Description"),
        accessorKey: "description",
        dataType: "text",
      },
      {
        header: t("ink.category", "Category"),
        accessorKey: "categoryName",
        dataType: "text",
      },
      {
        header: t("ink.subcategory", "Subcategory"),
        accessorKey: "subcategoryName",
        dataType: "text",
      },
      {
        header: t("ink.density", "Density (lb/gal)"),
        accessorKey: "density",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: t("general.status", "Status"),
        accessorKey: "isActive",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: (cell) => {
          switch (cell.getValue()) {
            case false:
              return <Chip color="error" label={t("general.inactive", "Inactive")} size="small" variant="light" />;
            case true:
              return <Chip color="success" label={t("general.active", "Active")} size="small" variant="light" />;
            default:
              return <Chip color="info" label={t("general.pending", "Pending")} size="small" variant="light" />;
          }
        },
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t("general.edit", "Edit")}>
                <IconButton
                  color="primary"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setSelectedItem(row.original as ComponentType);
                    setIsEditing(true);
                    handleOpen();
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("general.delete", "Delete")}>
                <IconButton
                  color="error"
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setDeleteAlert(true);
                    setItemDelete(row.original as ComponentType);
                  }}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    [t]
  );

  function handleOpenAndEditing() {
    setSelectedItem(emptyState);
    setIsEditing(false);
    handleOpen();
  }

  return (
    <MainLayout>
      <AtlasModal open={open} onClose={() => setOpen(false)} modalStyle={modalStyle}>
        <AddComponentModal
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          componentId={selectedItem?.id || 0}
          endpoints={endpoints}
          toggleRefreshData={toggleRefreshData}
          closeModal={() => setOpen(false)}
        />
      </AtlasModal>

      <AtlasModal open={openConfigUnitsModal} onClose={() => setOpenConfigUnitsModal(false)} modalStyle={modalStyle}>
        <SetComponentsUnitsOfMeasureModal componentIds={selectedComponentIds} closeModal={() => setOpenConfigUnitsModal(false)} />
      </AtlasModal>

      <Box sx={{ mb: 5 }}>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h2">{t("ink.components", "Components")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={6} alignItems="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAndEditing}>
                {t("ink.addComponent", "Add Component")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {!components && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {components && (
        <Stack direction="column" spacing={1}>
          <TextField
            variant="outlined"
            value={search}
            sx={{ maxWidth: "250px" }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("general.search", "Search...")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Stack direction="row">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                const selectedRows = displayTable.current?.getSelectedRows();
                const selectedIds = selectedRows?.map((item: { id: number }) => item.id);

                if (!selectedIds || selectedIds.length === 0) {
                  alertMessage("Please select at least one component to set units of measure", "info");
                  return;
                }
                setSelectedComponentIds(selectedIds);
                setOpenConfigUnitsModal(true);
              }}
            >
              Set Units of Measure
            </Button>
          </Stack>
          <DisplayTable
            columns={columns}
            data={components}
            search={search}
            modalToggler={handleOpenAndEditing}
            showAddBtn={true}
            ref={displayTable}
          />
        </Stack>
      )}
      <AlertItemDelete
        endpoints={InkEndpoints}
        deleteId={itemDelete?.id?.toString()}
        title={t("ink.component", "Component")}
        deleteName={itemDelete?.name || ""}
        open={deleteAlert}
        handleClose={afterDelete}
      />
    </MainLayout>
  );
}
