import React, { ReactNode } from "react";
import { Modal, Backdrop, Fade, Box, SxProps, Theme } from "@mui/material";
import { Grid } from "@mui/material";

interface AtlasModalProps {
  open: boolean;
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  children: ReactNode;
  modalStyle?: SxProps<Theme>;
  wrapperModalStyle?: SxProps<Theme>;
}

const defaultModalStyle = {
  position: "absolute" as "absolute",
  display: "flex",
  width: "auto",
  height: "auto",
  alignContent: "center",
  justifyContent: "center",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "auto" as "auto",
};

const defaultWrapperModalStyle = {
  mt: "5vh",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
};

const AtlasModal: React.FC<AtlasModalProps> = ({ open, onClose = () => {}, children, modalStyle, wrapperModalStyle }) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{ ...defaultWrapperModalStyle, ...wrapperModalStyle }}
      >
        <Fade in={open}>
          <Box sx={{ ...defaultModalStyle, ...modalStyle }}>
            <Grid container>{children}</Grid>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AtlasModal;
