import { useEffect } from "react";
import MainLayout from "../main-layout";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/formulation");
  }, []);
  return <MainLayout>{t("general.home", "Home")}</MainLayout>;
};

export default Home;
