import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableFooter,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import {
  LocationType,
  QuoteLineType,
  UnitOfMeasureType,
  ProductType,
  ComponentType,
  CompanyType,
  SelectCompanyType,
  QuoteType,
} from "../../AllTypes";
import HighlightedText from "../../components/HighlightedText";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { useUserPermissions } from "../../utils/userPermissions";
import { AddOutlined, Check, EmailOutlined, Redo, Save, Send, Warning } from "@mui/icons-material";
import { formatDate } from "../../utils/formatDate";
import QuoteSummary from "./QuoteSummary";
import useTranslation from "../../hooks/useTranslation";
import { totalmem } from "os";
import useAuth from "hooks/useAuth";

interface Props {
  closeModal: (modified?: boolean, skipConfirmClose?: boolean) => void;
  quoteId?: number | null;
  cloneQuoteId?: number | null;
  companyId?: number | null;
  isCentral: boolean;
}

interface ProductComponentOption {
  value: string;
  text: string;
  description: string;
  defaultUnitOfMeasureId?: string;
  type: "component" | "product";
  unitOfMeasureIds?: [number];
}

const SendQuoteModal: React.FC<Props> = ({ closeModal, quoteId, cloneQuoteId, companyId: defaultCompanyId, isCentral }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [action, setAction] = useState<"view" | "edit">("view");
  const [quote, setQuote] = useState<QuoteType>();
  const [mode, setMode] = useState<"confirmation" | "edition" | "viewOnly" | null>("viewOnly");
  const [title, setTitle] = useState("Add Quote");

  const permissions = useUserPermissions();
  const { user: currentUser } = useAuth();

  useEffect(() => {
    setLoading(true);
    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const initialValues = {
    emailAddresses: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      emailAddresses: Yup.string().required("Email addresses are required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log("submitting");

      // validate email addresses
      const emailAddresses = values.emailAddresses.split(",").map((email: string) => email.trim());
      if (emailAddresses.length === 0) {
        alertMessage("Email addresses are required", "error");
        setSubmitting(false);
        return;
      }

      const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };

      const allValid = emailAddresses.every((email: string) => isValidEmail(email));
      if (!allValid) {
        alertMessage("Invalid email addresses", "error");
        setSubmitting(false);
        return;
      }

      axiosServices
        .post(`api/quote/send/${quoteId}`, { emailAddresses })
        .then((res) => {
          alertMessage("Quote sent successfully", "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
        })
        .finally(() => {
          setSubmitting(false);
          // setConfirmationMode(false);
        });
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setValues, setFieldValue } = formik;

  useEffect(() => {
    if (!quoteId && !cloneQuoteId) {
      return;
    }

    if (quoteId && cloneQuoteId) {
      alertMessage("Invalid parameters", "error");
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/quote/${quoteId || cloneQuoteId}`)
      .then((res) => {
        let quote = res.data;
        quote.lines = quote.lines.map((l: any) => {
          return {
            ...l,
            component: l.componentId
              ? { value: l.componentId.toString(), text: l.componentName, description: l.componentDescription }
              : l.productId
              ? { value: l.productId.toString(), text: l.productCode, description: l.productDescription }
              : { value: 0, text: "", description: "" },
            unitOfMeasureIds: [
              ...new Set(
                l.componentId
                  ? l.componentUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
                  : l.productUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
              ),
            ],
          };
        });

        if (cloneQuoteId) {
          quote.quoteNumber = "";
          quote.id = 0;
          quote.lines = quote.lines.map((l: QuoteLineType) => {
            l.id = 0;
            return l;
          });
        }

        setQuote(quote);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [quoteId, cloneQuoteId]);

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        maxWidth: "1200px",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                Send Quote
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal(false)} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ py: 0, px: 0 }}>
            <Grid container spacing={2} sx={{ py: 2, px: 4 }}>
              <Grid item sm={12}>
                <Stack direction="column" spacing={2}>
                  <Typography>Send the following quote to:</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Enter email addresses separated by commas"
                      {...getFieldProps("emailAddresses")}
                      error={!!errors.emailAddresses && touched.emailAddresses}
                      helperText={errors.emailAddresses}
                    />
                    <Button variant="contained" color="primary" type="submit">
                      Send
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
          </DialogContent>
          <DialogContent sx={{ py: 0, px: 0 }}>
            <Stack direction="row" spacing={0}>
              <Grid container spacing={2} sx={{ py: 2, px: 4 }}>
                {(mode === "confirmation" || mode === "viewOnly") && quote && (
                  <QuoteSummary
                    values={quote}
                    companyName={selectedCompanyName}
                    isCentral={isCentral}
                    unitsOfMeasure={unitsOfMeasure}
                    showPrices={true}
                  />
                )}
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: 2.5, px: 4, justifyContent: "space-between" }}>
            <Stack direction="row" alignItems="center"></Stack>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              {mode === "viewOnly" && (
                <Button color="secondary" onClick={() => closeModal(false)}>
                  Close
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default SendQuoteModal;
