import { useEffect, useRef, useState } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";

// project import
import IconButton from "../../components/@extended/IconButton";
import Transitions from "../../components/@extended/Transitions";

import { ThemeMode } from "../../config";
import useConfig from "../../hooks/useConfig";

// assets
import TranslationOutlined from "@ant-design/icons/TranslationOutlined";

// types
import { I18n } from "../../types/config";
import { SelectSeriesType } from "../../AllTypes";
import useStickyState from "../../hooks/useStickyState";
import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { Button } from "@mui/material";
// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

export default function DefaultSeriesSelector() {
  const { t } = useTranslation();
  const [series, setSeries] = useState<SelectSeriesType[]>();
  const [selectedSeries, setSelectedSeries] = useState<SelectSeriesType | null>();
  const [defaultSeriesId, setDefaultSeriesId] = useStickyState<string | null>(null, "defaultSeriesId");

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const { mode, i18n, onChangeLocalization } = useConfig();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (lang: I18n) => {
    onChangeLocalization(lang);
    setOpen(false);
  };

  const iconBackColorOpen = mode === ThemeMode.DARK ? "background.default" : "grey.100";

  useEffect(() => {
    axiosServices
      .get("/api/Series/GetAllSeries")
      .then((res) => {
        setSeries(res.data);

        // Set the default series or the first series as selected
        if (!selectedSeries) {
          let ss = res.data[0];
          if (defaultSeriesId) {
            let defaultSeries = res.data.find((d: SelectSeriesType) => d.value === defaultSeriesId);
            if (defaultSeries) {
              ss = defaultSeries;
            }
          }
          setSelectedSeries(ss);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong ", "error", err);
      });
  }, []);

  useEffect(() => {
    let s = series?.find((x) => x.value === defaultSeriesId);
    setSelectedSeries(s || null);
  }, [defaultSeriesId]);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        color="secondary"
        variant="light"
        sx={{ color: "text.primary", bgcolor: open ? iconBackColorOpen : "transparent" }}
        aria-label={t("series.openLocalization", "open localization")}
        ref={anchorRef}
        aria-controls={open ? "localization-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {selectedSeries?.text}
      </Button>
      <Popper
        placement={matchesXs ? "bottom-start" : "bottom"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: "offset", options: { offset: [matchesXs ? 0 : 0, 9] } }] }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? "top-right" : "top"} in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: "100%",
                    minWidth: 200,
                    maxWidth: { xs: 250, md: 290 },
                    bgcolor: "background.paper",
                    borderRadius: 0.5,
                  }}
                >
                  {series?.map((s) => (
                    <ListItemButton
                      selected={i18n === "en"}
                      onClick={() => {
                        setDefaultSeriesId(s.value);
                        window.location.reload();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="text.primary">{s.text}</Typography>
                            {/* <Typography variant="caption" color="text.secondary" sx={{ ml: "8px" }}>
                              (UK)
                            </Typography> */}
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}
