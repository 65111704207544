// material-ui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// project import
import { PopupTransition } from "../../components/@extended/Transitions";

// assets
import DeleteFilled from "@ant-design/icons/DeleteFilled";

// types
import Avatar from "../../components/@extended/Avatar";
import { Info } from "@mui/icons-material";

interface Props {
  title: string;
  confirmMessage: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  icon?: React.ReactNode;
  confirmButtonText?: string;
}

// ==============================|| ITEM - DELETE ||============================== //

export default function ConfirmAction({
  title,
  confirmMessage,
  open,
  onClose,
  onConfirm,
  color = "info",
  icon = <Info></Info>,
  confirmButtonText = "Confirm",
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
      TransitionComponent={PopupTransition}
      maxWidth="xs"
      aria-labelledby="column-delete-title"
      aria-describedby="column-delete-description"
      sx={{
        zIndex: 10000,
      }}
    >
      <DialogContent sx={{ mt: 2, my: 1 }}>
        <Stack alignItems="center" spacing={3.5}>
          <Avatar color={color} sx={{ width: 72, height: 72, fontSize: "1.75rem" }}>
            {icon}
          </Avatar>
          <Stack spacing={2}>
            <Typography variant="h4" align="center">
              {title}
            </Typography>
            <Typography align="center">{confirmMessage}</Typography>
          </Stack>

          <Stack direction="row" spacing={2} sx={{ width: 1 }}>
            <Button fullWidth onClick={onClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <Button
              fullWidth
              color={color}
              variant="contained"
              onClick={() => {
                onConfirm();
              }}
              autoFocus
            >
              {confirmButtonText}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
