import AuthWrapper from "../../../sections/auth/AuthWrapper";
import ForgotPasswordForm from "./ForgotPasswordForm";

// ================================|| FORGOT PASSWORD ||================================ //

export default function ForgotPassword() {
  return (
    <AuthWrapper>
      <ForgotPasswordForm />
    </AuthWrapper>
  );
}
