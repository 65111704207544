// import {  useState} from "react";
import { Box, Button, DialogContent, DialogTitle, Divider, Grid, InputLabel, Stack, TextField, Tooltip, Typography } from "@mui/material";

import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { useState } from "react";
import axiosServices from "../../../utils/axios";
import { alertMessage } from "../../pages-helpers/AlertMessage";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import useTranslation from "../../../hooks/useTranslation";

interface Props {
  closeModal: () => void;
  formulaId: number;
  weight: number;
}

export default function ShareFormulaModal({ closeModal, formulaId, weight }: Props) {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, t("validation.emailInvalid", "Email Invalid"))
      .required(t("validation.emailRequired", "Email is required"))
      .max(40),
  });

  return (
    <Box sx={{ maxHeight: "90vh", width: "450px", maxWidth: "90vw" }} mx={{ width: "40vw" }}>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" fontWeight="bold" component="span">
            {t("formula.shareFormula", "Share Formula")}
          </Typography>
        </DialogTitle>

        <Tooltip title={t("general.close", "Close")}>
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider />
      <DialogContent>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            axiosServices
              .post(`/api/formula/${formulaId}/shareByEmail?weight=${weight}&email=${values.email}`)
              .then(() => {
                alertMessage(t("formula.emailSent", "Email sent"), "success");
                closeModal();
              })
              .catch((err) => {
                alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ touched, errors, getFieldProps, isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email-login">{t("general.emailAddress", "Email Address")}</InputLabel>
                    <TextField
                      id="email-login"
                      type="email"
                      {...getFieldProps("email")}
                      placeholder={t("general.enterEmailAddress", "Enter email address")}
                      fullWidth
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2} sx={{ width: 1, p: 4 }}>
                    <Button fullWidth onClick={closeModal} color="secondary" variant="outlined">
                      {t("general.cancel", "Cancel")}
                    </Button>
                    <Button type="submit" fullWidth color="primary" variant="contained" disabled={isSubmitting} autoFocus>
                      {t("general.share", "Share")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      {/* <DialogActions sx={{ p: 2.5 }}></DialogActions> */}
    </Box>
  );
}
