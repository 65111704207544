// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  TextField,
  InputAdornment,
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { formatDate } from "../../utils/formatDate";

import SearchOutlined from "@ant-design/icons/SearchOutlined";

import React, { Fragment, useEffect, useMemo, useState } from "react";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { LocationType, SelectCompanyType, PurchaseOrderType } from "../../AllTypes";
import AddPurchaseOrderModal from "./AddPurchaseOrderModal";
import MainLayout from "../main-layout";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Cancel, CancelOutlined, CopyAllOutlined, DescriptionOutlined, Warning } from "@mui/icons-material";
import ConfirmAction from "../pages-helpers/ConfirmAction";
import { useUserPermissions } from "../../utils/userPermissions";
import PurchaseOrderStatusChip from "../../components/PurchaseOrderStatusChip";
import AtlasModal from "../../components/AtlasModal";
import useTranslation from "../../hooks/useTranslation";

export default function PurchaseOrders() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [refreshData, setRefreshData] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderType>();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState<number | null>(null);
  const [clonePurchaseOrderId, setClonePurchaseOrderId] = useState<number | null>(null);
  const [search, setSearch] = useState("");
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [confirmCancelId, setConfirmCancelId] = useState<number | null>(null);
  const [canCancel, setCanCancel] = useState<boolean>(true);

  const permissions = useUserPermissions();

  useEffect(() => {
    setCanCancel(permissions?.canCancelPurchaseOrders());
  }, [permissions]);

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      });
  }, []);

  let toggleRefreshData = () => setRefreshData(!refreshData);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !permissions.hasMinLevel("Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }

    // Set default location if user cannot modify it
    if (!permissions.hasMinLevel("CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/purchaseOrder?locationId=${selectedLocationId}`)
      .then((res) => {
        setPurchaseOrders(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedLocationId, refreshData]);

  const columns = useMemo<ColumnDef<PurchaseOrderType>[]>(
    () => [
      {
        header: t("purchaseOrder.poNumber", "PO Number"),
        accessorKey: "purchaseOrderNumber",
        dataType: "text",
      },
      {
        header: t("general.status", "Status"),
        accessorKey: "statusDisplayName",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return (
            <>
              <PurchaseOrderStatusChip statusName={row.original.statusDisplayName} isCentral={false}></PurchaseOrderStatusChip>
            </>
          );
        },
      },
      {
        header: t("purchaseOrder.requestDate", "Request Date"),
        accessorKey: "requestDate",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return formatDate(row.original.requestDate, "PP");
        },
      },
      {
        header: t("general.location", "Location"),
        accessorKey: "locationName",
        dataType: "text",
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip
                title={
                  ["Draft", "Rejected"].includes(row.original.statusName || "")
                    ? t("purchaseOrder.editPurchaseOrder", "Edit Purchase Order")
                    : t("purchaseOrder.viewPurchaseOrder", "View Purchase Order")
                }
              >
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddModal(row.original.id);
                  }}
                >
                  {["Draft", "Rejected"].includes(row.original.statusName || "") ? <EditOutlined /> : <DescriptionOutlined />}
                </IconButton>
              </Tooltip>
              <Tooltip title={t("purchaseOrder.clonePurchaseOrder", "Clone Purchase Order")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddModal(row.original.id, true);
                  }}
                >
                  <CopyAllOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("purchaseOrder.cancelPurchaseOrder", "Cancel Purchase Order")}>
                <IconButton
                  color="error"
                  disabled={!canCancel}
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmCancelId(row.original.id);
                    setShowConfirmAction(true);
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    []
  );

  function openAddModal(purchaseOrderId?: number | null, clone: boolean = false) {
    if (clone) {
      setClonePurchaseOrderId(purchaseOrderId || null);
      setPurchaseOrderId(null);
    } else {
      setPurchaseOrderId(purchaseOrderId || null);
      setClonePurchaseOrderId(null);
    }
    setOpenModal(true);
  }

  function CompanyInput() {
    return permissions.hasMinLevel("CustomerServiceAgent") ? (
      <>
        <InputLabel htmlFor="companyId">{t("general.company", "Company")}</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">{t("general.company", "Company")}</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return permissions.hasMinLevel("CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">{t("general.location", "Location")}</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">{t("general.location", "Location")}</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  function confirmCloseModal() {
    setShowConfirmCloseModal(true);
  }
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  return (
    <MainLayout>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{t("purchaseOrder.title", "Purchase Orders")}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CompanyInput></CompanyInput>
        </Grid>
        <Grid item sm={6} xs={12}>
          <LocationInput></LocationInput>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item sm={12}>
          <Stack direction="row" spacing={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={!selectedLocationId}
              onClick={(e) => {
                e.stopPropagation();
                openAddModal(null);
              }}
              startIcon={<PlusOutlined />}
            >
              {t("purchaseOrder.addPurchaseOrder", "Add Purchase Order")}
            </Button>
          </Stack>
        </Grid>
        {purchaseOrders && (
          <Grid item sm={12}>
            <DisplayTable
              columns={columns}
              data={purchaseOrders}
              search={search}
              showAddBtn={true}
              defaultSorting={[{ id: "requestDate", desc: true }]}
            />
          </Grid>
        )}
      </Grid>
      {loading && !purchaseOrders && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loading && !purchaseOrders && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">{t("purchaseOrder.selectLocationToView", "Select a location to view purchase orders.")}</Typography>
        </Box>
      )}

      <AtlasModal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            confirmCloseModal();
          }
        }}
      >
        <AddPurchaseOrderModal
          closeModal={(modified?: boolean, skipConfirmClose?: boolean) => {
            if (modified) {
              toggleRefreshData();
              setOpenModal(false);
            } else if (skipConfirmClose) {
              setOpenModal(false);
            } else {
              confirmCloseModal();
            }
          }}
          purchaseOrderId={purchaseOrderId}
          clonePurchaseOrderId={clonePurchaseOrderId}
          locationId={parseInt(selectedLocationId) || 0}
          isCentral={false}
        />
      </AtlasModal>
      {/* <Box>
        <Modal
          open={openModal}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              confirmCloseModal();
            }
          }}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={openModal}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddPurchaseOrderModal
                  closeModal={(modified?: boolean, skipConfirmClose?: boolean) => {
                    if (modified) {
                      toggleRefreshData();
                      setOpenModal(false);
                    } else if (skipConfirmClose) {
                      setOpenModal(false);
                    } else {
                      confirmCloseModal();
                    }
                  }}
                  purchaseOrderId={purchaseOrderId}
                  clonePurchaseOrderId={clonePurchaseOrderId}
                  locationId={parseInt(selectedLocationId) || 0}
                  isCentral={false}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box> */}

      <ConfirmAction
        title={t("purchaseOrder.cancelPurchaseOrder", "Cancel Purchase Order")}
        confirmMessage={t("purchaseOrder.confirmCancelMessage", "Are you sure you want to cancel this purchase order?")}
        open={showConfirmAction}
        onConfirm={() => {
          axiosServices
            .post(`/api/purchaseOrder/${confirmCancelId}/cancel`)
            .then(() => {
              alertMessage(t("purchaseOrder.cancelSuccess", "Purchase order cancelled successfully."), "success");
              setShowConfirmAction(false);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
            });
        }}
        onClose={() => setShowConfirmAction(false)}
        icon={<Cancel></Cancel>}
        color="error"
      />
      <ConfirmAction
        title={t("general.confirmClose", "Confirm Close")}
        confirmMessage={t("purchaseOrder.confirmClose", "Are you sure you want discard changes and close?")}
        open={showConfirmCloseModal}
        onConfirm={() => {
          setOpenModal(false);
          setShowConfirmCloseModal(false);
        }}
        onClose={() => setShowConfirmCloseModal(false)}
        icon={<Warning></Warning>}
        color="warning"
        confirmButtonText={t("purchaseOrder.discardChanges", "Discard Changes")}
      />
    </MainLayout>
  );
}
