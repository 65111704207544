import React, { useState } from "react";
import {
  Stack,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import MainLayout from "../main-layout";
import useTranslation from "../../hooks/useTranslation";

// Fully populated meshData array with the provided data
const meshData = [
  { meshCountInch: 38, meshCountCm: 15, weave: "PW", threadDiameter: 200, fabricThickness: 365, tag: "" },
  { meshCountInch: 54, meshCountCm: 21, weave: "PW", threadDiameter: 160, fabricThickness: 275, tag: "" },
  { meshCountInch: 61, meshCountCm: 24, weave: "PW", threadDiameter: 120, fabricThickness: 216, tag: "" },
  { meshCountInch: 61, meshCountCm: 24, weave: "PW", threadDiameter: 145, fabricThickness: 245, tag: "" },
  { meshCountInch: 74, meshCountCm: 29, weave: "PW", threadDiameter: 120, fabricThickness: 218, tag: "" },
  { meshCountInch: 74, meshCountCm: 29, weave: "PW", threadDiameter: 145, fabricThickness: 240, tag: "" },
  { meshCountInch: 81, meshCountCm: 32, weave: "PW", threadDiameter: 70, fabricThickness: 110, tag: "" },
  { meshCountInch: 86, meshCountCm: 34, weave: "PW", threadDiameter: 100, fabricThickness: 173, tag: "" },
  { meshCountInch: 110, meshCountCm: 43, weave: "PW", threadDiameter: 80, fabricThickness: 132, tag: "" },
  { meshCountInch: 125, meshCountCm: 49, weave: "PW", threadDiameter: 70, fabricThickness: 116, tag: "" },
  { meshCountInch: 140, meshCountCm: 55, weave: "PW", threadDiameter: 64, fabricThickness: 105, tag: "" },
  { meshCountInch: 158, meshCountCm: 62, weave: "PW", threadDiameter: 64, fabricThickness: 106, tag: "" },
  { meshCountInch: 180, meshCountCm: 71, weave: "PW", threadDiameter: 55, fabricThickness: 91, tag: "" },
  { meshCountInch: 196, meshCountCm: 77, weave: "PW", threadDiameter: 55, fabricThickness: 90, tag: "" },
  { meshCountInch: 230, meshCountCm: 90, weave: "PW", threadDiameter: 40, fabricThickness: 62, tag: "" },
  { meshCountInch: 230, meshCountCm: 90, weave: "PW", threadDiameter: 48, fabricThickness: 81, tag: "" },
  { meshCountInch: 255, meshCountCm: 100, weave: "PW", threadDiameter: 40, fabricThickness: 64, tag: "" },
  { meshCountInch: 280, meshCountCm: 110, weave: "PW", threadDiameter: 34, fabricThickness: 56, tag: "" },
  { meshCountInch: 305, meshCountCm: 120, weave: "PW", threadDiameter: 34, fabricThickness: 54, tag: "" },
  { meshCountInch: 305, meshCountCm: 120, weave: "PW", threadDiameter: 40, fabricThickness: 67, tag: "" },
  { meshCountInch: 330, meshCountCm: 130, weave: "PW", threadDiameter: 34, fabricThickness: 55, tag: "" },
  { meshCountInch: 355, meshCountCm: 140, weave: "PW", threadDiameter: 34, fabricThickness: 56, tag: "" },
  { meshCountInch: 81, meshCountCm: 32, weave: "PW", threadDiameter: 70, fabricThickness: 110, tag: "thin thread" },
  { meshCountInch: 110, meshCountCm: 43, weave: "PW", threadDiameter: 64, fabricThickness: 100, tag: "thin thread" },
  { meshCountInch: 122, meshCountCm: 48, weave: "PW", threadDiameter: 55, fabricThickness: 90, tag: "thin thread" },
  { meshCountInch: 140, meshCountCm: 55, weave: "PW", threadDiameter: 48, fabricThickness: 78, tag: "thin thread" },
  { meshCountInch: 157, meshCountCm: 62, weave: "PW", threadDiameter: 48, fabricThickness: 77, tag: "thin thread" },
  { meshCountInch: 198, meshCountCm: 78, weave: "PW", threadDiameter: 48, fabricThickness: 65, tag: "thin thread" },
  { meshCountInch: 230, meshCountCm: 120, weave: "PW", threadDiameter: 40, fabricThickness: 62, tag: "thin thread" },
  { meshCountInch: 305, meshCountCm: 120, weave: "PW", threadDiameter: 31, fabricThickness: 48, tag: "thin thread" },
];

const InkUsageCalculator2 = () => {
  const { t } = useTranslation();
  const [selectedMeshIndex, setSelectedMeshIndex] = useState(0);
  const [threadDiameter, setThreadDiameter] = useState(0);
  const [fabricThickness, setFabricThickness] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [coverage, setCoverage] = useState(0);
  const [numberOfPrints, setNumberOfPrints] = useState(1);
  const [pricePerGallon, setPricePerGallon] = useState(0);
  const [unit, setUnit] = useState("cm"); // 'cm' or 'in'
  const [volumeUnit, setVolumeUnit] = useState<"gallons" | "quarts" | "pints">("gallons"); // 'gallons', 'quarts', 'pints'

  const handleMeshSelection = (index: number) => {
    const selectedData = meshData[index];
    setSelectedMeshIndex(index);
    setThreadDiameter(selectedData?.threadDiameter || 0);
    setFabricThickness(selectedData?.fabricThickness || 0);
  };

  const convertToCm = (value: number) => (unit === "in" ? value * 2.54 : value);
  const convertToIn = (value: number) => (unit === "cm" ? value / 2.54 : value);

  const handleUnitChange = (checked: boolean) => {
    setUnit(checked ? "in" : "cm");
    if (checked) {
      setLength(convertToIn(length));
      setWidth(convertToIn(width));
    } else {
      setLength(convertToCm(length));
      setWidth(convertToCm(width));
    }
  };

  // in gallons per X
  const gallonsPer = {
    gallons: 1,
    quarts: 1 / 4,
    pints: 1 / 8,
  };

  // in cm3 per X
  const volumeConversionFactors = {
    gallons: 3785,
    quarts: 3785 / 4,
    pints: 3785 / 8,
  };

  const getInkDepositHeightMicrons = () => {
    const meshCount = meshData[selectedMeshIndex].meshCountInch;
    if (meshCount <= 42) {
      return 1.82 * threadDiameter * Math.pow(1 - meshCount * threadDiameter * 0.0001, 2);
    }
    if (meshCount <= 139) {
      return 0.285 * fabricThickness;
    }
    return 0.35 * fabricThickness;
  };

  function changeVolumeUnit(newVolumeUnit: string) {
    if (newVolumeUnit === "gallons" || newVolumeUnit === "quarts" || newVolumeUnit === "pints") {
      setVolumeUnit(newVolumeUnit);
      // } else {
      //     console.error("Invalid unit");
    }
  }

  function getTotalInkVolume() {
    const inkDepositHeightCm = getInkDepositHeightMicrons() / 10000;
    const convertedLength = convertToCm(length);
    const convertedWidth = convertToCm(width);
    const coverageArea = (convertedWidth * convertedLength * coverage) / 100;
    const inkVolumePerPrintCm3 = inkDepositHeightCm * coverageArea;
    const totalInkVolumeCm3 = inkVolumePerPrintCm3 * numberOfPrints;

    // Convert total ink volume based on the selected unit
    return totalInkVolumeCm3 / volumeConversionFactors[volumeUnit];
  }

  const getTotalInkCost = () => {
    const totalInkVolumeWithOverage = getTotalInkVolume() * 1.05;
    return totalInkVolumeWithOverage * pricePerGallon * gallonsPer[volumeUnit];
    // return totalInkVolumeWithOverage * pricePerGallon;
  };

  return (
    <MainLayout>
      <Stack spacing={3} sx={{ width: "100%" }}>
        <h1>{t("inkUsageCalculator.title", "Ink Usage Calculator")}</h1>
        <h3>{t("inkUsageCalculator.screenMeshSpecifications", "Screen Mesh Specifications")}</h3>
        <Stack direction="row" spacing={1}>
          <FormControl fullWidth>
            <InputLabel id="mesh-count-select-label">{t("inkUsageCalculator.meshCount", "Mesh Count")}</InputLabel>
            <Select
              labelId="mesh-count-select-label"
              value={selectedMeshIndex}
              label={t("inkUsageCalculator.meshCount", "Mesh Count")}
              onChange={(e) => handleMeshSelection(+e.target.value)}
            >
              {meshData.map((mesh, index) => (
                <MenuItem key={index} value={index}>
                  {`${mesh.meshCountInch} ${t("general.inch", "inch")} / ${mesh.meshCountCm} ${t("general.cm", "cm")} ${
                    mesh.tag ? `- ${mesh.tag}` : ""
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label={t("inkUsageCalculator.threadDiameter", "Thread Diameter (Microns)")}
            type="number"
            fullWidth
            value={threadDiameter}
            onChange={(e) => setThreadDiameter(+e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">μm</InputAdornment>,
            }}
          />
          <TextField
            label={t("inkUsageCalculator.fabricThickness", "Fabric Thickness (Microns)")}
            type="number"
            fullWidth
            value={fabricThickness}
            onChange={(e) => setFabricThickness(+e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">μm</InputAdornment>,
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="flex-end" justifyContent="space-between">
          <h3>{t("inkUsageCalculator.printSizeAndCoverage", "Print Size and Coverage")}</h3>
          <FormControlLabel
            labelPlacement="start"
            control={<Switch size="small" checked={unit === "in"} onChange={(e) => handleUnitChange(e.target.checked)} />}
            label={t("inkUsageCalculator.switchTo", "Switch to {{unit}}", {
              unit: unit === "cm" ? t("general.inches", "inches") : t("general.centimeters", "centimeters"),
            })}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            label={t("general.length", "Length")}
            type="number"
            fullWidth
            value={length}
            onChange={(e) => setLength(+e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
          />
          <TextField
            label={t("general.width", "Width")}
            type="number"
            fullWidth
            value={width}
            onChange={(e) => setWidth(+e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
          />
          <TextField
            label={t("general.coverage", "Coverage")}
            type="number"
            fullWidth
            value={coverage}
            onChange={(event) => setCoverage(+event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Stack>
        <h3>{t("inkUsageCalculator.jobSpecifications", "Job Specifications")}</h3>
        <Stack direction="row" spacing={1}>
          <TextField
            label={t("inkUsageCalculator.numberOfPrints", "Number of Prints")}
            type="number"
            fullWidth
            value={numberOfPrints}
            onChange={(e) => setNumberOfPrints(+e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="volume-unit-label">{t("inkUsageCalculator.volumeUnit", "Volume Unit")}</InputLabel>
            <Select
              labelId="volume-unit-label"
              fullWidth
              value={volumeUnit}
              label={t("inkUsageCalculator.volumeUnit", "Volume Unit")}
              onChange={(e) => changeVolumeUnit(e.target.value)}
            >
              <MenuItem value="gallons">{t("inkUsageCalculator.gallons", "Gallons")}</MenuItem>
              <MenuItem value="quarts">{t("inkUsageCalculator.quarts", "Quarts")}</MenuItem>
              <MenuItem value="pints">{t("inkUsageCalculator.pints", "Pints")}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t("inkUsageCalculator.pricePerGallon", "Price Per Gallon")}
            type="number"
            fullWidth
            value={pricePerGallon}
            onChange={(e) => setPricePerGallon(+e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">$</InputAdornment>,
            }}
          />
        </Stack>
        <Divider></Divider>
        <h3>{t("general.results", "Results")}</h3>
        <Stack direction="row" spacing={2} sx={{ mt: "4px !important" }}>
          <Paper sx={{ p: "12px 16px", textAlign: "center", width: "100%" }}>
            <Typography variant="h6">{t("inkUsageCalculator.estimatedInkDeposit", "Estimated Ink Deposit")}</Typography>
            <Typography variant="h4">
              {getInkDepositHeightMicrons().toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })} μm
            </Typography>
          </Paper>
          <Paper sx={{ p: "12px 16px", textAlign: "center", width: "100%" }}>
            <Typography variant="h6">{t("inkUsageCalculator.volumeOfInkNeeded", "Volume of Ink Needed")}</Typography>
            <Typography variant="h4">
              {getTotalInkVolume().toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}
              {t(`inkUsageCalculator.${volumeUnit}`, volumeUnit)}
            </Typography>
          </Paper>
          <Paper sx={{ p: "12px 16px", textAlign: "center", width: "100%" }}>
            <Typography variant="h6">{t("inkUsageCalculator.totalCostOfInk", "Total Cost of Ink")}</Typography>
            <Typography variant="h4">${getTotalInkCost().toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
          </Paper>
        </Stack>
      </Stack>
    </MainLayout>
  );
};

export default InkUsageCalculator2;
