import { useEffect, useState, Dispatch, SetStateAction } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik, FormikProvider, useFormik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import {
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
  Switch,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  ListItemText,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { ComponentType, ProductType, UnitOfMeasureType } from "../../AllTypes";
import { alertMessage } from "../pages-helpers/AlertMessage";
import axiosServices from "../../utils/axios";
import axios from "axios";
import ScrollX from "../../components/ScrollX";

interface Props {
  componentIds: number[];
  closeModal: () => void;
}

export default function SetComponentsUnitsOfMeasureModal({ componentIds, closeModal }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (!componentIds) return;

      try {
        const [componentsRes, unitsOfMeasureRes] = await Promise.all([
          axiosServices.get(`/api/component?${componentIds.map((id) => `ids=${id}`).join("&")}&includeUnitsOfMeasure=true`),
          axiosServices.get("/api/unitOfMeasure"),
        ]);

        let components = componentsRes.data.map((component: ComponentType) => {
          return {
            ...component,
            unitsOfMeasure: unitsOfMeasureRes.data.map((unit: UnitOfMeasureType) => {
              let uom = component.unitsOfMeasure?.find((uom) => uom.unitOfMeasureId === unit.id);
              return uom ? { ...uom, name: unit.name, checked: true } : { unitOfMeasureId: unit.id, name: unit.name, checked: false };
            }),
          };
        });
        const unitsOfMeasure = unitsOfMeasureRes.data.map((unit: UnitOfMeasureType) => ({ ...unit, checked: false }));

        setValues({ components, unitsOfMeasure });
      } catch (error) {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [componentIds]);

  const validationSchema = Yup.object().shape({});

  const handleAlertClose = () => {
    closeModal();
  };

  const initialValues: {
    components: {
      id?: number;
      name: string;
      description?: string;
      unitsOfMeasure?: [
        {
          id: number;
          productId: number;
          unitOfMeasureId: number;
          checked: boolean;
          name: string;
        }
      ];
    }[];
    unitsOfMeasure: {
      id: number;
      name: string;
      checked: boolean;
    }[];
  } = {
    components: [],
    unitsOfMeasure: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      const components = values.components.map((component) => {
        return {
          ...component,
          unitsOfMeasure: component.unitsOfMeasure?.filter((uom) => uom.checked),
        };
      });

      try {
        axiosServices
          .post("/api/component/unitsOfMeasure", components)
          .then(() => {
            alertMessage(t("setComponentsUnitsOfMeasure.updateSuccess", "Components updated successfully"), "success");
            handleAlertClose();
          })
          .catch((err) => {
            alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
            setSubmitting(false);
          });
      } catch (error) {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, setValues, handleSubmit, isSubmitting, getFieldProps, values, handleChange } = formik;

  if (loading) {
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        maxWidth: "900px",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {t("setComponentsUnitsOfMeasure.title", "Set Units of Measure")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  {t("setComponentsUnitsOfMeasure.instructions", "For each component, select all units of measure that apply.")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ScrollX>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("general.component", "Component")}</TableCell>
                          {values.unitsOfMeasure.map((unit, ix) => (
                            <TableCell key={unit.id} align="center">
                              {unit.name}
                              <br />
                              <Tooltip title={unit.checked ? t("general.selectNone", "Select none") : t("general.selectAll", "Select all")}>
                                <span>
                                  <Checkbox
                                    {...getFieldProps(`unitsOfMeasure.${ix}.checked`)}
                                    size="large"
                                    checked={unit.checked}
                                    onChange={(e) => {
                                      handleChange(e);

                                      let newValues = { ...values };
                                      newValues.unitsOfMeasure[ix].checked = e.target.checked;
                                      newValues.components.forEach((component) => {
                                        component.unitsOfMeasure?.forEach((uom) => {
                                          if (uom.unitOfMeasureId === unit.id) {
                                            uom.checked = e.target.checked;
                                          }
                                        });
                                      });
                                      setValues(newValues);
                                    }}
                                  />
                                </span>
                              </Tooltip>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.components.map((component, cIx) => (
                          <TableRow key={component.id}>
                            <TableCell>
                              {component.name} - {component.description}
                            </TableCell>
                            {component.unitsOfMeasure?.map((unit, uIx) => (
                              <TableCell key={unit.unitOfMeasureId} align="center">
                                <Checkbox {...getFieldProps(`components.${cIx}.unitsOfMeasure.${uIx}.checked`)} checked={unit.checked} />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ScrollX>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={closeModal}>
              {t("general.cancel", "Cancel")}
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("general.save", "Save")}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
}
