import { Link } from "react-router-dom";
import useTranslation from "../../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// project import
import AnimateButton from "../../../components/@extended/AnimateButton";
import AuthWrapper from "../../../sections/auth/AuthWrapper";

// ================================|| UNAUTHORIZED MESSAGE ||================================ //

export default function UnauthorizedMessage() {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">{t("unauthorized.title", "Unauthorized Access")}</Typography>
            <Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
              {t("unauthorized.message", "You are not authorized to access this page. Please Login.")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AnimateButton>
            <Button component={Link} to={"/login"} disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
              {t("general.signIn", "Sign in")}
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
