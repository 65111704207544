// import useSWR, { mutate } from "swr";
// import { useEffect, useMemo, useState } from "react";

// utils
import axiosServices from "../utils/axios";
import { CompanyType, EndpointsType, ComponentType, LocationType, SeriesType, UserType } from "../AllTypes";
import { alertMessage } from "../pages/pages-helpers/AlertMessage";

// TODO: refactor: use generics instead of custom type
type CustomType = SeriesType | CompanyType | LocationType | UserType | ComponentType;

export async function insert<T>(endpoints: EndpointsType, newData: T) {
  // to update local state based on key
  const response = await axiosServices.post(endpoints.get || "", newData);
  console.log(response?.data);
  return response;
}

export async function update<T>(endpoints: EndpointsType, id: string | undefined, updatedData: T) {
  if (!id) throw new Error("Id is required");
  if (!endpoints.update) {
    throw new Error("no update endpoint");
  }
  const response = await axiosServices.post(endpoints.update(id), updatedData);

  console.log(response?.data);
  return response;
}

export async function updateUsingPut(endpoints: EndpointsType, id: string | undefined, updatedData: CustomType) {
  if (!id) {
    alertMessage("Id is required", "error");
    return 0;
  }
  if (typeof endpoints.update !== "function") {
    alertMessage("Invalid endpoint update function", "error");
    return 0;
  }
  const response = await axiosServices.put(endpoints.update(id), updatedData);
  console.log(response?.data);
  return response;
}

export async function deleteById(endpoints: EndpointsType, id: any) {
  if (!id) {
    alertMessage("Id is required", "error");
    return 0;
  }
  if (typeof endpoints.delete !== "function") {
    alertMessage("Invalid endpoint delete function", "error");
    return 0;
  }

  const response = await axiosServices.delete(endpoints.delete(id));
  console.log(response);
}
