import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CloseOutlined } from "@ant-design/icons";
import useTranslation from "../../hooks/useTranslation";

import { GetFormulaType, InventoryTransactionType } from "../../AllTypes";
import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { UserProfile } from "../../types/auth";
import useAuth from "../../hooks/useAuth";

const GRAMS_PER_LB = 453.59237;

interface Props {
  closeModal: (modified?: boolean) => void;
  type: "addition" | "removal" | "adjustment";
  transaction: InventoryTransactionType;
}

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

export default function AddInventoryTransactionModal({ closeModal, type, transaction }: Props) {
  const { user: currentUser } = useAuth();
  const [formula, setFormula] = useState<GetFormulaType>();
  const { t } = useTranslation();

  let initialValues = {
    quantity: 0,
    cost: 0,
    batchNumber: "",
    references: "",
    internalPartNumber: "",
    notes: "",
    type,
  };

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  const ItemSchema = Yup.object().shape({
    quantity: Yup.number()
      .required(t("inventory.quantityRequired", "Quantity is required"))
      .when("type", {
        is: "addition",
        then: (schema) => schema.positive(t("inventory.quantityPositive", "Quantity must be a positive number.")),
      })
      .when("type", {
        is: "removal",
        then: (schema) => schema.positive(t("inventory.quantityPositive", "Quantity must be a positive number.")),
      }),
    cost: Yup.number().when("type", {
      is: "addition",
      then: (schema) => schema.required(t("inventory.costRequired", "Cost is required")),
    }),
  });

  function getQuantityInGrams(quantity: number) {
    if (formula?.density) {
      return `${formatNo(quantity * formula.density * GRAMS_PER_LB, 2)}g`;
    }
    return "0g";
  }

  useEffect(() => {
    if (!transaction?.formulaId) {
      return;
    }

    axiosServices
      .get(`/api/formula/${transaction.formulaId}`)
      .then((res) => {
        setFormula(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      });
  }, [transaction]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      transaction.notes = values.notes;
      transaction.quantity = values.quantity;

      var types = [
        { value: 1, name: "addition" },
        { value: 2, name: "removal" },
        { value: 3, name: "adjustment" },
      ];
      var typeId = types.find((x) => x.name === type)?.value || 0;

      transaction.type = typeId;

      if (type === "addition") {
        transaction.costPerGal = values.cost;
        transaction.batchNumber = values.batchNumber;
        transaction.references = values.references;
        transaction.internalPartNumber = values.internalPartNumber;
      }
      if (type === "removal" || type === "adjustment") {
        transaction.costPerGal = null;
        transaction.batchNumber = "";
        transaction.references = "";
        transaction.internalPartNumber = "";
      }

      axiosServices
        .post(`/api/inventory/inventoryTransactions`, transaction)
        .then((res) => {
          alertMessage(t("inventory.inventoryModifiedSuccessfully", "Inventory modified successfully"), "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, handleChange } = formik;

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {type === "addition" && t("inventory.addStock", "Add Stock")}
                {type === "removal" && t("inventory.reduceStock", "Reduce Stock")}
                {type === "adjustment" && t("inventory.adjustStock", "Adjust Stock")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal()} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} sx={{ pt: "0 !important" }}>
                    {transaction.componentId && (
                      <Stack spacing={1}>
                        <InputLabel htmlFor="quantity">{t("inventory.quantity", "Quantity")}</InputLabel>
                        <Typography>{transaction.componentName}</Typography>
                      </Stack>
                    )}
                    {transaction.formulaId && (
                      <Stack spacing={1}>
                        <InputLabel htmlFor="quantity">{t("inventory.quantity", "Quantity")}</InputLabel>
                        <Typography>{transaction.formulaName}</Typography>
                      </Stack>
                    )}
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="quantity">{t("inventory.quantity", "Quantity")}</InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          placeholder={t("inventory.quantity", "Quantity")}
                          {...getFieldProps("quantity")}
                          error={Boolean(touched.quantity && errors.quantity)}
                          helperText={touched.quantity && errors.quantity}
                        />
                        {transaction.formulaId && (
                          <Typography variant="subtitle2" color="gray">
                            {getQuantityInGrams(values.quantity)}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    {type === "addition" && hasMinLevel(currentUser, "CompanyAdmin") && (
                      <Grid item xs>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="cost">{t("inventory.costPerGal", "Cost per Gal")}</InputLabel>
                          <TextField
                            fullWidth
                            type="number"
                            placeholder={t("inventory.cost", "Cost")}
                            {...getFieldProps("cost")}
                            error={Boolean(touched.cost && errors.cost)}
                            helperText={touched.cost && errors.cost}
                          />
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  {type === "addition" && (
                    <>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="notes">{t("inventory.batchNumber", "Batch Number")} </InputLabel>
                          <TextField fullWidth multiline placeholder={t("inventory.batchNumber", "Batch Number")} {...getFieldProps("batchNumber")} />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="notes">{t("inventory.references", "References")} </InputLabel>
                          <TextField fullWidth multiline placeholder={t("inventory.references", "References")} {...getFieldProps("references")} />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="notes">{t("inventory.internalPartNumber", "Internal Part Number")} </InputLabel>
                          <TextField
                            fullWidth
                            multiline
                            placeholder={t("inventory.internalPartNumber", "Internal Part Number")}
                            {...getFieldProps("internalPartNumber")}
                          />
                        </Stack>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="notes">{t("inventory.notes", "Notes")} </InputLabel>
                      <TextField fullWidth multiline placeholder={t("inventory.notes", "Notes")} {...getFieldProps("notes")} />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={() => closeModal()}>
              {t("general.cancel", "Cancel")}
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("general.save", "Save")}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
}
