import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { Stack, Tooltip, Typography } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { FormulaCreationComponentType, FormulaCreationType } from "../../AllTypes";
import { ColumnDef } from "@tanstack/react-table";
import DisplayTable from "../../components/DisplayTable";
import { formatDate } from "../../utils/formatDate";
import useTranslation from "../../hooks/useTranslation";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

interface Props {
  closeModal: () => void;
  formulaCreationRecord?: FormulaCreationType;
}

export default function ViewFormulaCreationRecordModal({ closeModal, formulaCreationRecord }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const columns = useMemo<ColumnDef<FormulaCreationComponentType>[]>(
    () => [
      {
        header: t("formulation.component", "Component"),
        accessorKey: "componentName",
        dataType: "text",
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? originalRow.formulaName : originalRow.componentName;
        },
      },
      {
        header: t("formulation.weightInGrams", "Weight (g)"),
        accessorKey: "weightInGrams",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{formatNo(row.original?.weightInGrams)}</>;
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Box>
      <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
        <DialogTitle>
          <Typography variant="h4" component="span">
            {t("formulation.formulaCreationRecord", "Formula Creation Record")}
          </Typography>
        </DialogTitle>
        <Tooltip title={t("general.close", "Close")}>
          <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
            <CloseOutlined />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider orientation="horizontal" flexItem />
      <DialogContent sx={{ width: "100%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.formula", "Formula")}:</InputLabel>
                <span>{formulaCreationRecord?.formulaName}</span>
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.createdBy", "Created By")}:&nbsp;</InputLabel>
                {formulaCreationRecord?.createdByFullName}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.dateCreated", "Date Created")}:&nbsp;</InputLabel>
                {formatDate(formulaCreationRecord?.dateCreated || "", "PPp")}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.weight", "Weight")}:&nbsp;</InputLabel>
                {formatNo(formulaCreationRecord?.weightInGrams || 0)}g
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.recycledFrom", "Recycled From")}:&nbsp;</InputLabel>
                {formulaCreationRecord?.recycledFromName || t("general.none", "None")}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("formulation.deductedFromInventory", "Deducted from Inventory")}:&nbsp;</InputLabel>
                {formulaCreationRecord?.deductedFromInventory ? t("general.yes", "Yes") : t("general.no", "No")}
              </Stack>
              <Stack direction="row" spacing={1}>
                <InputLabel>{t("general.printed", "Printed")}:&nbsp;</InputLabel>
                {formulaCreationRecord?.printed ? t("general.yes", "Yes") : t("general.no", "No")}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {formulaCreationRecord?.components && (
              <Grid item sm={12}>
                <DisplayTable columns={columns} data={formulaCreationRecord?.components} showAddBtn={true} showPagination={false} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 2.5 }}>
        <Button color="secondary" onClick={closeModal}>
          {t("general.close", "Close")}
        </Button>
      </DialogActions>
    </Box>
  );
}
