import { FormattedMessage, useIntl } from "react-intl";
import { ReactElement } from "react";

// Extend to accept values for interpolation
// export const T = (key: string, defaultMessage: string, values?: Record<string, any>): ReactElement => {
//   return <FormattedMessage id={key} defaultMessage={defaultMessage} values={values} />;
// };

// // Custom hook to use intl
// const useTranslation = () => useIntl();

// export const TS = (key: string, defaultMessage: string, values?: Record<string, any>): string => {
//   const intl = useTranslation();
//   return intl.formatMessage({ id: key, defaultMessage }, values);
// };

// export default T;

const useTranslation = () => {
  const intl = useIntl();

  const tx = (key: string, defaultMessage: string, values?: Record<string, any>): ReactElement => (
    <FormattedMessage id={key} defaultMessage={defaultMessage} values={values} />
  );

  const t = (key: string, defaultMessage: string, values?: Record<string, any>): string => {
    return intl.formatMessage({ id: key, defaultMessage }, values);
    // .replace(/{([^{}]+)}/g, (_: any, key: string) => values?.[key] || `{${key}}`);
  };

  return { tx, t };
};

export default useTranslation;
