// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Divider,
  Button,
  FormHelperText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { formatDate } from "../../utils/formatDate";

import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { UserProfile } from "../../types/auth";
import { InventoryTransactionType, LocationType, SelectCompanyType } from "../../AllTypes";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import DatePicker from "@mui/lab/DatePicker";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import MainLayout from "../main-layout";
import { FormattedMessage } from "react-intl";
import useTranslation from "../../hooks/useTranslation";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from "chart.js";
import { Pie, Line } from "react-chartjs-2";
import DisplayTable from "../../components/DisplayTable";
import { Info, InfoOutlined } from "@mui/icons-material";
import AtlasModal from "../../components/AtlasModal";
import UserActionDetailsModal from "./UserActionDetailsModal";
// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

type SelectedFormulaActionRecordType = {
  date: string;
  formulaName: string;
  formulaId: number;
  count: number;
};

type SelectedWeightActionRecordType = {
  date: string;
  weight: string;
  count: number;
};

type SelectedStockRemovalActionRecordType = {
  date: string;
  componentName: string;
  componentId: number;
  quantity: number;
};
export default function UserActions() {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();
  const [selectedFormulaActions, setSelectedFormulaActions] = useState<SelectedFormulaActionRecordType[]>();
  const [selectedWeightActions, setSelectedWeightActions] = useState<SelectedWeightActionRecordType[]>();
  const [selectedStockRemovalActions, setSelectedStockRemovalActions] = useState<SelectedStockRemovalActionRecordType[]>();
  const [loadingFormulaActions, setLoadingFormulaActions] = useState(false);
  const [loadingWeightActions, setLoadingWeightActions] = useState(false);
  const [loadingStockRemovalActions, setLoadingStockRemovalActions] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("0");
  const [selectedLocationId, setSelectedLocationId] = useState("0");
  const [selectedDateStart, setSelectedDateStart] = useState(dayjs().subtract(6, "day"));
  const [selectedDateEnd, setSelectedDateEnd] = useState(dayjs());
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userActionDetails, setUserActionDetails] = useState<{
    dateStart: string;
    dateEnd: string;
    companyId: string;
    locationId: string;
    formulaId?: number;
    componentId?: number;
    weightRange?: string;
  }>();
  const [userActionType, setUserActionType] = useState<"formula" | "weight" | "stockRemoval">("formula");
  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    console.log("selectedCompanyId", selectedCompanyId);
    if (selectedCompanyId === "0") {
      setLocations([]);
      setSelectedLocationId("0");
      return;
    }

    if (!selectedCompanyId) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [selectedCompanyId]);

  // useEffect(() => {
  //   if (!selectedLocationId) {
  //     return;
  //   }
  // }, [selectedLocationId]);

  function performSearch() {
    setSearched(true);

    var urlParams = `x=1`;
    if (selectedCompanyId !== "0") {
      urlParams += `&companyId=${selectedCompanyId}`;
    }
    if (selectedLocationId !== "0") {
      urlParams += `&locationId=${selectedLocationId}`;
    }
    if (selectedDateStart) {
      urlParams += `&startDate=${selectedDateStart.format("YYYY-MM-DD")}`;
    }
    if (selectedDateEnd) {
      urlParams += `&endDate=${selectedDateEnd.format("YYYY-MM-DD")}`;
    }

    setLoadingFormulaActions(true);
    axiosServices
      .get(`/api/userActionRecord/GetSelectedFormulaActions?${urlParams}`)
      .then((res) => {
        setSelectedFormulaActions(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoadingFormulaActions(false);
      });

    setLoadingWeightActions(true);
    axiosServices
      .get(`/api/userActionRecord/GetSelectedWeightActions?${urlParams}`)
      .then((res) => {
        setSelectedWeightActions(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoadingWeightActions(false);
      });

    setLoadingStockRemovalActions(true);
    axiosServices
      .get(`/api/userActionRecord/GetStockRemovalActions?${urlParams}`)
      .then((res) => {
        setSelectedStockRemovalActions(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoadingStockRemovalActions(false);
      });
  }

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  let costCols: ColumnDef<InventoryTransactionType>[] = [];
  if (hasMinLevel(currentUser, "CompanyAdmin")) {
    costCols.push({
      header: "Cost (per Gal)",
      accessorKey: "costPerGal",
      meta: {
        className: "cell-center",
      },
      cell: ({ row }) => {
        if (Number.isFinite(row.original?.costPerGal)) {
          return <>${formatNo(row.original?.costPerGal || 0, 2, 2)}</>;
        } else {
          return "";
        }
      },
    });
  }

  const selectedFormulaActionsColumns = useMemo<ColumnDef<SelectedFormulaActionRecordType>[]>(
    () => [
      // {
      //   header: "Date",
      //   accessorKey: "date",
      //   dataType: "date",
      //   cell: ({ row: { original } }) => {
      //     return formatDate(original?.date || "", "PP");
      //   },
      // },
      {
        header: "Formula",
        accessorKey: "formulaName",
        dataType: "text",
      },
      {
        header: "Series",
        accessorKey: "seriesName",
        dataType: "text",
      },
      {
        header: "Views",
        accessorKey: "count",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Typography variant="body1">{formatNo(row.original?.count || 0, 1)}</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  console.log("selectedDateStart", selectedDateStart.format("YYYY-MM-DD"));

                  setUserActionDetails({
                    dateStart: selectedDateStart.format("YYYY-MM-DD"),
                    dateEnd: selectedDateEnd.format("YYYY-MM-DD"),
                    companyId: selectedCompanyId,
                    locationId: selectedLocationId,
                    formulaId: row.original?.formulaId,
                  });
                  setUserActionType("formula");
                  setOpenModal(true);
                }}
              >
                <InfoOutlined />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [selectedDateStart, selectedDateEnd, selectedCompanyId, selectedLocationId]
  );

  const selectedWeightActionsColumns = useMemo<ColumnDef<SelectedWeightActionRecordType>[]>(
    () => [
      // {
      //   header: "Date",
      //   accessorKey: "date",
      //   dataType: "date",
      //   cell: ({ row: { original } }) => {
      //     return formatDate(original?.date || "", "PP");
      //   },
      // },
      {
        header: "Weight",
        accessorKey: "weight",
        dataType: "text",
      },
      {
        header: "Count",
        accessorKey: "count",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Typography variant="body1">{formatNo(row.original?.count || 0, 1)}</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  console.log("selectedDateStart", selectedDateStart.format("YYYY-MM-DD"));

                  setUserActionDetails({
                    dateStart: selectedDateStart.format("YYYY-MM-DD"),
                    dateEnd: selectedDateEnd.format("YYYY-MM-DD"),
                    companyId: selectedCompanyId,
                    locationId: selectedLocationId,
                    weightRange: row.original?.weight,
                  });
                  setUserActionType("weight");
                  setOpenModal(true);
                }}
              >
                <InfoOutlined />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [selectedDateStart, selectedDateEnd, selectedCompanyId, selectedLocationId]
  );

  const selectedStockRemovalActionsColumns = useMemo<ColumnDef<SelectedStockRemovalActionRecordType>[]>(
    () => [
      // {
      //   header: "Date",
      //   accessorKey: "date",
      //   dataType: "date",
      //   cell: ({ row: { original } }) => {
      //     return formatDate(original?.date || "", "PP");
      //   },
      // },
      {
        header: "Component",
        accessorKey: "componentName",
        dataType: "text",
      },
      {
        header: "Amount (grams)",
        accessorKey: "quantity",
        dataType: "number",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Typography variant="body1">{formatNo(row.original?.quantity || 0, 1)}</Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  console.log("selectedDateStart", selectedDateStart.format("YYYY-MM-DD"));

                  setUserActionDetails({
                    dateStart: selectedDateStart.format("YYYY-MM-DD"),
                    dateEnd: selectedDateEnd.format("YYYY-MM-DD"),
                    companyId: selectedCompanyId,
                    locationId: selectedLocationId,
                    componentId: row.original?.componentId,
                  });
                  setUserActionType("stockRemoval");
                  setOpenModal(true);
                }}
              >
                <InfoOutlined />
              </IconButton>
            </Stack>
          );
        },
      },
    ],
    [selectedDateStart, selectedDateEnd, selectedCompanyId, selectedLocationId]
  );

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">Company</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          <MenuItem key={0} value={"0"}>
            All companies
          </MenuItem>
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">Company</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">Location</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          <MenuItem key={0} value={"0"}>
            All locations
          </MenuItem>
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">Location</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  // Add these functions to prepare data for pie charts
  const prepareFormulaChartData = () => {
    const labels = selectedFormulaActions?.map((item) => item.formulaName) || [];
    const data = selectedFormulaActions?.map((item) => item.count) || [];
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
        },
      ],
    };
  };

  const prepareWeightChartData = () => {
    const labels = selectedWeightActions?.map((item) => item.weight) || [];
    const data = selectedWeightActions?.map((item) => item.count) || [];
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
        },
      ],
    };
  };

  const prepareStockRemovalChartData = () => {
    const labels = selectedStockRemovalActions?.map((item) => item.componentName) || [];
    const data = selectedStockRemovalActions?.map((item) => item.quantity) || [];
    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"],
        },
      ],
    };
  };

  return (
    <MainLayout>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              {/* <h1>
                <FormattedMessage id="app.title" defaultMessage="Welcome to My App" />
                {t("app.title", "Welcome to My App")}
              </h1>

              <Typography variant="h2">User Actions</Typography> */}
              <Typography variant="h2">
                {t("userActions.title", "User Actions")}
                {/* <FormattedMessage id="userActions.title" defaultMessage="User Actions" /> */}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <CompanyInput></CompanyInput>
          {searched && !selectedCompanyId && <FormHelperText error>Select a company</FormHelperText>}
        </Grid>
        <Grid item sm={6}>
          <LocationInput></LocationInput>
          {searched && !selectedLocationId && <FormHelperText error>Select a location</FormHelperText>}
        </Grid>
        <Grid item sm={3}>
          <Stack direction="column" spacing={1}>
            <InputLabel htmlFor="dateStart">From:</InputLabel>
            <DatePicker value={selectedDateStart} onChange={(e) => setSelectedDateStart(e)} format="MMM D, YYYY"></DatePicker>
          </Stack>
        </Grid>
        <Grid item sm={3}>
          <Stack direction="column" spacing={1}>
            <InputLabel htmlFor="dateEnd">To:</InputLabel>
            <DatePicker value={selectedDateEnd} onChange={(e) => setSelectedDateEnd(e)} format="MMM D, YYYY"></DatePicker>
          </Stack>
        </Grid>

        <Grid item sm={6}>
          <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" sx={{ pt: 3 }}>
            <Button onClick={performSearch} variant="contained">
              Search
            </Button>
          </Stack>
        </Grid>

        <Grid item sm={12} sx={{ py: 2 }}>
          <Divider></Divider>
        </Grid>

        {!loadingFormulaActions && selectedFormulaActions && (
          <Grid item sm={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Formula Views</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item sm={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ maxWidth: "300px", maxHeight: "300px", textAlign: "center" }}>
                      <Pie data={prepareFormulaChartData()} />
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined></SearchOutlined>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <DisplayTable
                      columns={selectedFormulaActionsColumns}
                      data={selectedFormulaActions}
                      defaultSorting={[{ id: "dateCreated", desc: true }]}
                      search={search}
                      showAddBtn={false}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {loadingFormulaActions && (
          <Grid item sm={12}>
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          </Grid>
        )}

        {!loadingWeightActions && selectedWeightActions && (
          <Grid item sm={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Weight Actions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item sm={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ maxWidth: "300px", maxHeight: "300px", textAlign: "center" }}>
                      <Pie data={prepareWeightChartData()} />
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined></SearchOutlined>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <DisplayTable
                      columns={selectedWeightActionsColumns}
                      data={selectedWeightActions}
                      defaultSorting={[{ id: "dateCreated", desc: true }]}
                      search={search}
                      showAddBtn={false}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {loadingWeightActions && (
          <Grid item sm={12}>
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          </Grid>
        )}

        {!loadingStockRemovalActions && selectedStockRemovalActions && (
          <Grid item sm={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Estimated Component Deductions</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item sm={6} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ maxWidth: "300px", maxHeight: "300px", textAlign: "center" }}>
                      <Pie data={prepareStockRemovalChartData()} />
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined></SearchOutlined>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <DisplayTable
                      columns={selectedStockRemovalActionsColumns}
                      data={selectedStockRemovalActions}
                      defaultSorting={[{ id: "dateCreated", desc: true }]}
                      search={search}
                      showAddBtn={false}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {loadingStockRemovalActions && (
          <Grid item sm={12}>
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          </Grid>
        )}
      </Grid>
      {/* {!loadingStock && !selectedFormulaActions && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">Select a location to view inventory selectedFormulaActions.</Typography>
        </Box>
      )} */}
      <AtlasModal open={openModal} onClose={() => setOpenModal(false)}>
        <UserActionDetailsModal closeModal={() => setOpenModal(false)} userActionType={userActionType} userActionDetails={userActionDetails} />
      </AtlasModal>
    </MainLayout>
  );
}
