import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputLabel,
  TextField,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Autocomplete,
  FormHelperText,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  ButtonGroup,
  CircularProgress,
} from "@mui/material";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CloseOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import {
  SelectInkType,
  LocationType,
  InventoryTransactionType,
  FormulaListItemType,
  GetFormulaType,
  ComponentCostType,
  FormulaCostType,
} from "../../AllTypes";
import "./AddMultipleInventoryTransactionModal.css";
import { UserProfile } from "../../types/auth";
import useAuth from "../../hooks/useAuth";
import useStickyState from "../../hooks/useStickyState";
import HighlightedText from "../../components/HighlightedText";
import useTranslation from "../../hooks/useTranslation";

const GRAMS_PER_LB = 453.59237;

interface Props {
  closeModal: (modified?: boolean) => void;
  type: "addition" | "removal" | "adjustment";
  locationId: number;
}

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

const AddInventoryTransactionModal: React.FC<Props> = ({ closeModal, type, locationId }) => {
  // const [open, setOpen] = useState(false);
  // const [options, setOptions] = useState<SelectInkType[]>([]);
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [loadingFormulas, setLoadingFormulas] = useState(false);
  const { t } = useTranslation();

  // Simulate API call with a delay
  const fetchComponents = (inputValue: string) => {
    setLoadingComponents(true);

    axiosServices
      .get(`/api/componentCost/GetAll?companyId=${location?.companyId}&search=${inputValue}&limit=30`)
      .then((res) => {
        setComponentCosts(res.data);
        let options = res.data.map(
          (cc: ComponentCostType) =>
            ({
              text: cc.componentName,
              value: cc.componentId.toString(),
              description: cc.componentDescription,
              selected: false,
            } as SelectInkType)
        );
        setComponents(options);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      })
      .finally(() => {
        setLoadingComponents(false);
      });
  };

  // Simulate API call with a delay
  const fetchFormulas = (inputValue: string) => {
    setLoadingFormulas(true);

    axiosServices
      .get(`/api/Formula/GetAll?companyId=${location?.companyId}&includeDensities=true&seriesId=${defaultSeriesId}&search=${inputValue}&limit=30`)
      .then((res) => {
        let formulas = res.data;
        let options: FormulaListItemType[] = formulas.map((f: GetFormulaType) => ({
          text: f.name,
          value: f.id,
          selected: false,
        }));
        options.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
        setFormulaItems(options);
        setFormulas(formulas);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      })
      .finally(() => {
        setLoadingFormulas(false);
      });
  };

  // Handle input change to trigger the fetch
  const handleComponentInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value.length > 0) {
      fetchComponents(value);
    } else {
      setComponents([]);
    }
  };

  // Handle input change to trigger the fetch
  const handleFormulaInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value.length > 0) {
      fetchFormulas(value);
    } else {
      setFormulas([]);
    }
  };

  const { user: currentUser } = useAuth();
  const [components, setComponents] = useState<SelectInkType[]>();
  const [componentCosts, setComponentCosts] = useState<ComponentCostType[]>();
  const [selectedComponents, setSelectedComponents] = useState<SelectInkType[]>([]);
  const [formulaItems, setFormulaItems] = useState<FormulaListItemType[]>();
  const [formulas, setFormulas] = useState<GetFormulaType[]>();
  const [formulaCosts, setFormulaCosts] = useState<FormulaCostType[]>();
  const [selectedFormulas, setSelectedFormulas] = useState<FormulaListItemType[]>([]);
  const [location, setLocation] = useState<LocationType>();
  const [repeated, setRepeated] = useState<boolean[]>();
  const [modifyComponents, setModifyComponents] = useState<boolean>(true);
  const [defaultSeriesId] = useStickyState<string | null>(null, "defaultSeriesId");

  const initialValues = {
    notes: "",
    transactions: [
      {
        id: 0,
        componentId: 0,
        formulaId: 0,
        quantity: 0,
        costPerGal: 0,
        batchNumber: "",
        references: "",
        internalPartNumber: "",
        notes: "",
        type: 1, // addition
        locationId,
      } as InventoryTransactionType,
    ],
  };

  function getQuantityInGrams(transaction: InventoryTransactionType) {
    let formula = formulas?.find((f) => f.id === transaction.formulaId);
    if (formula?.density) {
      return `${formatNo(transaction.quantity * formula.density * GRAMS_PER_LB, 2)}g`;
    }
    return "0g";
  }

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  useEffect(() => {}, []);

  useEffect(() => {
    axiosServices
      .get(`/api/Location/${locationId}`)
      .then((res) => {
        setLocation(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      });
  }, [locationId]);

  useEffect(() => {
    if (!location) {
      return;
    }

    setSelectedComponents([]);
    fetchComponents("");
    // axiosServices
    //   .get(`/api/componentCost/GetAll?companyId=${location.companyId}`)
    //   .then((res) => {
    //     setComponentCosts(res.data);
    //     let options = res.data.map(
    //       (cc: ComponentCostType) =>
    //         ({
    //           text: cc.componentName,
    //           value: cc.componentId.toString(),
    //           description: cc.componentDescription,
    //           selected: false,
    //         } as SelectInkType)
    //     );
    //     setComponents(options);
    //   })
    //   .catch((err) => {
    //     alertMessage("Something went wrong", "error", err);
    //   });

    axiosServices
      .get(`/api/formula/GetCosts?companyId=${location.companyId}&seriesId=${defaultSeriesId}`)
      .then((res) => {
        setFormulaCosts(res.data);
      })
      .catch((err) => {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
      });

    setSelectedFormulas([]);
    fetchFormulas("");
  }, [location]);

  function getDefaultComponentCost(componentId: string) {
    let componentCost = componentCosts?.find((c) => c.componentId === +componentId);
    return componentCost?.costPerGal || 0;
  }

  function getDefaultFormulaCost(formulaId: string) {
    let formulaCost = formulaCosts?.find((c) => c.formulaId === +formulaId);
    let defaultCost = formulaCost?.costPerGal || 0;
    return Math.round((defaultCost + Number.EPSILON) * 100) / 100;
  }

  const ItemSchema = Yup.object().shape({
    quantity: Yup.number()
      .required(t("inventory.quantityRequired", "Quantity is required"))
      .test("is-positive", t("inventory.quantityPositive", "Quantity must be a positive number."), (value) =>
        type === "addition" || type === "removal" ? value > 0 : true
      ),
    costPerGal: Yup.number().when("type", {
      is: "addition",
      then: (schema) =>
        schema.positive(t("inventory.costPositive", "Cost must be a positive number.")).required(t("inventory.costRequired", "Cost is required")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      transactions: Yup.array().of(ItemSchema),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (type === "removal" || type === "adjustment") {
        if (repeated?.some((r) => r)) {
          setSubmitting(false);
          return;
        }
      }

      var types = [
        { value: 1, name: "addition" },
        { value: 2, name: "removal" },
        { value: 3, name: "adjustment" },
      ];
      var typeId = types.find((x) => x.name === type)?.value || 0;

      const transactions = values.transactions.map((transaction) => ({
        ...transaction,
        type: typeId,
        notes: values.notes,
        quantity: transaction.quantity,
        componentId: modifyComponents ? transaction.componentId : null,
        formulaId: !modifyComponents ? transaction.formulaId : null,
        costPerGal: type === "addition" ? transaction.costPerGal : null,
        batchNumber: type === "addition" ? transaction.batchNumber : "",
        references: type === "addition" ? transaction.references : "",
        internalPartNumber: type === "addition" ? transaction.internalPartNumber : "",
      }));

      axiosServices
        .post(`/api/inventory/inventoryTransactions/bulk`, transactions)
        .then((res) => {
          alertMessage(t("inventory.inventoryModifiedSuccessfully", "Inventory modified successfully"), "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  // function getHelperText();

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setFieldValue } = formik;

  useEffect(() => {
    if (type === "removal" || type === "adjustment") {
      if (modifyComponents) {
        var repeatedComponents = values.transactions.map((t) => values.transactions.some((t2) => t2.componentId === t.componentId && t !== t2));
        setRepeated(repeatedComponents);
      } else {
        var repeatedFormulas = values.transactions.map((t) => values.transactions.some((t2) => t2.formulaId === t.formulaId && t !== t2));
        setRepeated(repeatedFormulas);
      }
    }
  }, [values.transactions]);

  return (
    <Box
      sx={(theme) => ({
        maxHeight: "89vh",
        width: "85vw",
        [theme.breakpoints.only("xs")]: {
          width: "100vw",
        },
      })}
    >
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {type === "addition" && t("inventory.addStock", "Add Stock")}
                {type === "removal" && t("inventory.reduceStock", "Reduce Stock")}
                {type === "adjustment" && t("inventory.adjustStock", "Adjust Stock")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={() => closeModal()} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent>
            {/* <Grid container spacing={1} sx={{ pb: 3 }}>
              <Grid item>
                <Stack direction="column">
                  <InputLabel htmlFor="companyLabel">Company</InputLabel>
                  <OutlinedInput fullWidth id="companyLabel" value={location?.companyName} name="companyLabel" readOnly={true} />
                </Stack>
              </Grid>
              <Grid item>
                <Stack direction="column">
                  <InputLabel htmlFor="locationLabel">Location</InputLabel>
                  <OutlinedInput fullWidth id="locationLabel" value={location?.name} name="locationLabel" readOnly={true} />
                </Stack>
              </Grid>
            </Grid> */}
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column" spacing={0} sx={{ pb: 3 }}>
                <Stack direction="row" spacing={1}>
                  <InputLabel>Company:&nbsp;</InputLabel>
                  {location?.companyName}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <InputLabel>Location:&nbsp;</InputLabel>
                  {location?.name}
                </Stack>
              </Stack>
              <div>
                <ButtonGroup size="small">
                  <Button onClick={() => setModifyComponents(true)} variant={modifyComponents ? "contained" : "outlined"} color="primary">
                    <Typography variant="body1" align="center">
                      Components
                    </Typography>
                  </Button>
                  <Button onClick={() => setModifyComponents(false)} variant={!modifyComponents ? "contained" : "outlined"} color="primary">
                    <Typography variant="h6" align="center">
                      Formulas
                    </Typography>
                  </Button>
                </ButtonGroup>
              </div>
            </Stack>
            <FieldArray name="transactions">
              {({ push, remove }) => (
                <>
                  <Box
                    sx={{
                      overflowX: "auto",
                      border: "1px solid #eee",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {modifyComponents && (
                            <TableCell sx={{ verticalAlign: "top" }}>
                              <InputLabel>Component</InputLabel>
                            </TableCell>
                          )}
                          {!modifyComponents && (
                            <TableCell sx={{ verticalAlign: "top" }}>
                              <InputLabel>Formula</InputLabel>
                            </TableCell>
                          )}
                          <TableCell sx={{ verticalAlign: "top" }}>
                            <Stack spacing={1}>
                              <InputLabel>Quantity (Gal)</InputLabel>
                            </Stack>
                          </TableCell>
                          {type === "addition" && hasMinLevel(currentUser, "CompanyAdmin") && (
                            <TableCell sx={{ verticalAlign: "top" }}>
                              <Stack spacing={1}>
                                <InputLabel>Cost per Gal</InputLabel>
                              </Stack>
                            </TableCell>
                          )}
                          {type === "addition" && (
                            <>
                              <TableCell sx={{ verticalAlign: "top" }}>
                                <Stack spacing={1}>
                                  <InputLabel>Batch Number</InputLabel>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ verticalAlign: "top" }}>
                                <Stack spacing={1}>
                                  <InputLabel>References</InputLabel>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ verticalAlign: "top" }}>
                                <Stack spacing={1}>
                                  <InputLabel>Internal Part Number</InputLabel>
                                </Stack>
                              </TableCell>
                            </>
                          )}
                          <TableCell sx={{ verticalAlign: "top" }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.transactions.map((transaction, index) => (
                          // <Grid container spacing={1} key={index}>
                          //   <Grid item xs={12}>
                          //     <Grid container spacing={1}>
                          <TableRow key={index}>
                            {modifyComponents && (
                              <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                {components && (
                                  <Autocomplete
                                    size="small"
                                    // open={open}
                                    // onOpen={() => setOpen(true)}
                                    // onClose={() => setOpen(false)}
                                    fullWidth
                                    sx={{ minWidth: "220px" }}
                                    id={`transactions.${index}.componentId`}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    getOptionLabel={(label) => `${label.text} - ${label.description}`}
                                    options={components}
                                    loading={loadingComponents}
                                    disableClearable={false}
                                    value={selectedComponents[index]}
                                    onInputChange={handleComponentInputChange}
                                    onChange={(_e, newValue) => {
                                      setFieldValue(`transactions.${index}.componentId`, newValue?.value);
                                      setFieldValue(`selectedComponents.${index}`, newValue);
                                      if (newValue?.value) {
                                        let cost = getDefaultComponentCost(newValue?.value);
                                        setFieldValue(`transactions.${index}.costPerGal`, cost);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        id={`componentId-${index}`}
                                        placeholder={t("inventory.selectComponent", "Select Component")}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {loadingComponents ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option, state) => (
                                      <li {...props} style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                                        <HighlightedText
                                          text={`${option.text} - ${option.description}`}
                                          highlight={state.inputValue}
                                        ></HighlightedText>
                                      </li>
                                    )}
                                  />
                                )}
                                {/* <HighlightedText text="ABC" highlight="A"></HighlightedText>
                                <br></br>
                                <HighlightedText text="ABC A" highlight="A"></HighlightedText>
                                <br></br>
                                <HighlightedText text="X ABC - ABC" highlight="AB"></HighlightedText> */}
                                {/* {components && (
                                  <Autocomplete
                                    fullWidth
                                    sx={{ minWidth: "200px" }}
                                    disablePortal
                                    id={`transactions.${index}.componentId`}
                                    options={components}
                                    isOptionEqualToValue={(option, value) => option.value === value.value || option.value === components?.[0].value}
                                    disableClearable={true}
                                    value={selectedComponents[index]}
                                    onChange={(_e, newValue) => {
                                      setFieldValue(`transactions.${index}.componentId`, newValue?.value);
                                      setFieldValue(`selectedComponents.${index}`, newValue);
                                      let cost = getDefaultComponentCost(newValue?.value);
                                      setFieldValue(`transactions.${index}.costPerGal`, cost);
                                    }}
                                    getOptionLabel={(label) => `${label.text} - ${label.description}`}
                                    renderInput={(params: any) => (
                                      <TextField id={`componentId-${index}`} fullWidth {...params} placeholder={`Select Component`} />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props}>
                                        {option.text} - {option.description}
                                      </li>
                                    )}
                                  />
                                )} */}
                                {touched?.transactions?.[index]?.componentId && !values?.transactions?.[index]?.componentId && (
                                  <FormHelperText error>Select Component</FormHelperText>
                                )}
                                {repeated?.[index] && <FormHelperText error>There must be only one line per component.</FormHelperText>}
                              </TableCell>
                            )}
                            {!modifyComponents && (
                              <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                {formulaItems && (
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    sx={{ minWidth: "220px" }}
                                    disablePortal
                                    id={`transactions.${index}.formulaId`}
                                    options={formulaItems}
                                    loading={loadingFormulas}
                                    isOptionEqualToValue={(option, value) => option.value === value.value || option.value === formulaItems?.[0].value}
                                    getOptionLabel={(label) => label.text || ""}
                                    getOptionKey={(option) => option.value}
                                    disableClearable={false}
                                    value={selectedFormulas[index]}
                                    onInputChange={handleFormulaInputChange}
                                    onChange={(_e, newValue) => {
                                      setFieldValue(`transactions.${index}.formulaId`, newValue?.value);
                                      setFieldValue(`selectedFormulas.${index}`, newValue);
                                      if (newValue?.value) {
                                        let cost = getDefaultFormulaCost(newValue?.value);
                                        setFieldValue(`transactions.${index}.costPerGal`, cost);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        id={`formulaId-${index}`}
                                        placeholder={t("inventory.selectFormula", "Select Formula")}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {loadingFormulas ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option, state) => (
                                      <li {...props}>
                                        <HighlightedText text={option.text} highlight={state.inputValue}></HighlightedText>
                                      </li>
                                    )}
                                  />
                                )}
                                {touched?.transactions?.[index]?.formulaId && !values?.transactions?.[index]?.formulaId && (
                                  <FormHelperText error>Select Formula</FormHelperText>
                                )}
                                {repeated?.[index] && <FormHelperText error>There must be only one line per formula.</FormHelperText>}
                              </TableCell>
                            )}
                            <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                              <Stack spacing={0}>
                                <TextField
                                  size="small"
                                  fullWidth
                                  type="number"
                                  sx={{ maxWidth: "120px" }}
                                  placeholder={t("inventory.quantity", "Quantity")}
                                  {...getFieldProps(`transactions.${index}.quantity`)}
                                  // error={Boolean(touched.transactions?.[index]?.quantity && <ErrorMessage name={`transactions.${index}.quantity`} />)}
                                  helperText={<ErrorMessage name={`transactions.${index}.quantity`} component="div" className="error" />}
                                />
                                {!modifyComponents && (
                                  <Typography variant="subtitle2" color="gray">
                                    {getQuantityInGrams(transaction)}
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>
                            {type === "addition" && hasMinLevel(currentUser, "CompanyAdmin") && (
                              <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                <Stack spacing={1}>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    type="number"
                                    sx={{ maxWidth: "120px" }}
                                    placeholder={t("inventory.cost", "Cost")}
                                    {...getFieldProps(`transactions.${index}.costPerGal`)}
                                    // error={Boolean(touched.transactions?.[index]?.costPerGal && <ErrorMessage name={`transactions.${index}.costPerGal`} />)}
                                    helperText={<ErrorMessage name={`transactions.${index}.costPerGal`} component="div" className="error" />}
                                    // error={Boolean(touched.transactions?.[index]?.costPerGal && errors.transactions?.[index]?.costPerGal)}
                                    // helperText={touched.transactions?.[index]?.costPerGal && errors.transactions?.[index]?.costPerGal}
                                  />
                                </Stack>
                              </TableCell>
                            )}
                            {type === "addition" && (
                              <>
                                <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                  <Stack spacing={1}>
                                    <TextField
                                      size="small"
                                      fullWidth
                                      placeholder={t("inventory.batchNumber", "Batch Number")}
                                      {...getFieldProps(`transactions.${index}.batchNumber`)}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                  <Stack spacing={1}>
                                    <TextField
                                      size="small"
                                      fullWidth
                                      placeholder={t("inventory.references", "References")}
                                      {...getFieldProps(`transactions.${index}.references`)}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                                  <Stack spacing={1}>
                                    <TextField
                                      size="small"
                                      fullWidth
                                      placeholder={t("inventory.internalPartNumber", "Internal Part Number")}
                                      {...getFieldProps(`transactions.${index}.internalPartNumber`)}
                                    />
                                  </Stack>
                                </TableCell>
                              </>
                            )}
                            <TableCell sx={{ verticalAlign: "top", pt: 1.5, pb: 1 }}>
                              <Stack direction="row" spacing={1}>
                                <IconButton color="error" onClick={() => remove(index)}>
                                  <DeleteOutlined />
                                </IconButton>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          // <Grid item xs={12}>
                          //   <Divider />
                          // </Grid>
                          //     </Grid>
                          //   </Grid>
                          // </Grid>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <Stack spacing={1}>
                    <IconButton color="primary" onClick={() => push(initialValues.transactions[0])}>
                      <PlusOutlined />
                    </IconButton>

                    <Stack spacing={1}>
                      <InputLabel>Notes</InputLabel>
                      <TextField sx={{ maxWidth: "300px" }} multiline placeholder="Notes" {...getFieldProps(`notes`)} />
                    </Stack>
                  </Stack>
                </>
              )}
            </FieldArray>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={() => closeModal()}>
              {t("general.cancel", "Cancel")}
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("general.save", "Save")}
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default AddInventoryTransactionModal;
