import { useEffect, useState, Dispatch, SetStateAction } from "react";
import useTranslation from "../../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// project imports
import CircularWithPath from "../../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography, Switch } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { EndpointsType, SeriesType } from "../../../AllTypes";
import { insert, update } from "../../../api/generalRoute";
import { alertMessage } from "../../pages-helpers/AlertMessage";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  editingData: SeriesType;
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddSeriesModal({ isEditing, setIsEditing, editingData, endpoints, toggleRefreshData, closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(false);
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("series.nameRequired", "Name is required")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const seriesInitialValues = {
    name: tempIsEditing && editingData ? editingData.name : "",
    isActive: tempIsEditing && editingData ? editingData.isActive : true,
  };

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Formik
      initialValues={seriesInitialValues}
      validationSchema={ItemSchema}
      enableReinitialize={false}
      onSubmit={async (values: SeriesType, { setSubmitting }) => {
        try {
          if (tempIsEditing && editingData) {
            update(endpoints, editingData.id?.toString(), values)
              .then(() => {
                alertMessage(t("series.updateSuccess", "Series updated successfully"), "success");
                handleAlertClose();
              })
              .catch((err) => {
                alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
              });
          } else {
            insert(endpoints, values)
              .then(() => {
                alertMessage(t("series.addSuccess", "Series added successfully"), "success");
                handleAlertClose();
                setSubmitting(false);
              })
              .catch((err) => {
                alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
              });
          }
        } catch (error) {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, getFieldProps, values }) => (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1, width: "50vw" }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {tempIsEditing ? t("series.editSeries", "Edit Series") : t("series.addSeries", "Add Series")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">{t("general.name", "Name")}</InputLabel>
                      <TextField
                        fullWidth
                        id="name"
                        placeholder={t("series.namePlaceholder", "Enter Name")}
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <FormControlLabel
                        control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />}
                        label={t("general.active", "Active")}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={closeModal}>
              {t("general.cancel", "Cancel")}
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("general.save", "Save")}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
