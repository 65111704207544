// material-ui
import Tooltip from "@mui/material";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  Backdrop,
  Box,
  Fade,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { MoveUp, MoveDown } from "@mui/icons-material";

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { formatDate } from "../../utils/formatDate";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { UserProfile } from "../../types/auth";
import { LocationType, SelectCompanyType, StockEntryType, InventoryTransactionType, InventoryTransactionTypeType } from "../../AllTypes";
import AddInventoryTransactionModal from "./AddInventoryTransactionModal";
import useTranslation from "../../hooks/useTranslation";

export default function StockEntries() {
  const { user: currentUser } = useAuth();
  const [refreshData, setRefreshData] = useState(false);
  const [stockEntries, setStockEntries] = useState<StockEntryType>();
  const [loadingStock, setLoadingStock] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [openModifyStockModal, setOpenModifyStockModal] = useState(false);
  const [transactionType, setTransactionType] = useState<"addition" | "removal" | "adjustment">("addition");
  const [inventoryTransaction, setInventoryTransaction] = useState<InventoryTransactionType>({} as InventoryTransactionType);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  // TODO: move it to its own file
  function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
    return formatter.format(pct);
  }

  const modalStyle = {
    position: "absolute",
    display: "flex",
    width: "auto",
    maxWidth: "500px",
    height: "auto",
    alignContent: "center",
    justifyContent: "center",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflowY: "auto",
  };

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  let toggleRefreshData = () => setRefreshData(!refreshData);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    setLoadingStock(true);
    axiosServices
      .get(`/api/inventory/stockEntries?locationId=${selectedLocationId}`)
      .then((res) => {
        setStockEntries(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoadingStock(false);
      });
  }, [selectedLocationId, refreshData]);

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  let costCols: ColumnDef<StockEntryType>[] = [];
  if (hasMinLevel(currentUser, "CompanyAdmin")) {
    costCols.push({
      header: "Cost (per Gal)",
      accessorKey: "costPerGal",
      meta: {
        className: "cell-center",
      },
      cell: ({ row }) => {
        return <>${formatNo(row.original?.costPerGal, 2, 2)}</>;
      },
    });
  }

  const columns = useMemo<ColumnDef<StockEntryType>[]>(
    () => [
      {
        header: t("inventory.name", "Name"),
        accessorKey: "name",
        dataType: "text",
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? originalRow.formulaName : originalRow.componentName;
        },
      },
      {
        header: t("inventory.type", "Type"),
        accessorKey: "description",
        dataType: "text",
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? "Formula" : "Component";
        },
      },
      {
        header: t("inventory.quantityInGal", "Quantity (Gal)"),
        accessorKey: "quantityInGal",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{formatNo(row.original?.quantityInGal)}</>;
        },
      },
      ...costCols,
      {
        header: t("inventory.batchNumber", "Batch Number"),
        accessorKey: "batchNumber",
        dataType: "text",
      },
      {
        header: t("inventory.references", "References"),
        accessorKey: "references",
        dataType: "text",
      },
      {
        header: t("inventory.internalPartNumber", "Internal Part Number"),
        accessorKey: "internalPartNumber",
        dataType: "text",
      },
      {
        header: t("inventory.dateCreated", "Date Created"),
        accessorKey: "dateCreated",
        dataType: "date",
        cell: ({ row: { original } }) => {
          return formatDate(original.dateCreated, "PPp");
        },
      },
      // {
      //   header: 'Actions',
      //   id: 'actions',
      //   disableSortBy: true,
      //   cell: ({ row }) => {
      //     return (
      //       <Stack direction="row" alignItems="center" justifyContent="center">
      //         <Tooltip title="Add Inventory">
      //           <IconButton
      //             color="primary"
      //             onClick={(e) => {
      //               e.stopPropagation();
      //               openModal('addition', row.original);
      //             }}
      //           >
      //             <MoveUp />
      //           </IconButton>
      //         </Tooltip>
      //         <Tooltip title="Reduce Inventory">
      //           <IconButton
      //             color="error"
      //             onClick={(e) => {
      //               e.stopPropagation();
      //               openModal('reduction', row.original);
      //             }}
      //           >
      //             <MoveDown />
      //           </IconButton>
      //         </Tooltip>
      //       </Stack>
      //     );
      //   },
      //   meta: {
      //     className: 'cell-center'
      //   }
      // },
    ],
    []
  );

  function openModal(type: InventoryTransactionTypeType, level: StockEntryType) {
    setTransactionType(type);

    setInventoryTransaction({
      // id: 0,
      formulaId: level.formulaId,
      formulaName: level.formulaName,
      componentId: level.componentId,
      componentName: level.componentName,
      // quantityChangedInGal: 0,
      // costPerGal: 0,
      locationId: level.locationId,
    } as InventoryTransactionType);
    setOpenModifyStockModal(true);
  }

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">{t("general.company", "Company")}</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">{t("general.company", "Company")}</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">{t("general.location", "Location")}</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">{t("general.location", "Location")}</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{t("inventory.stockEntries", "Stock Entries")}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <CompanyInput></CompanyInput>
        </Grid>
        <Grid item sm={6} xs={12}>
          <LocationInput></LocationInput>
        </Grid>

        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("general.search", "Search...")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        {stockEntries && (
          <Grid item sm={12}>
            <DisplayTable columns={columns} data={stockEntries} search={search} showAddBtn={true} />
          </Grid>
        )}
      </Grid>
      {loadingStock && !stockEntries && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loadingStock && !stockEntries && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">{t("inventory.selectLocationToViewStockEntries", "Select a location to view stock entries.")}</Typography>
        </Box>
      )}

      <Box>
        <Modal
          open={openModifyStockModal}
          onClose={() => setOpenModifyStockModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          sx={{
            mt: "5%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Fade in={openModifyStockModal}>
            <Box sx={modalStyle}>
              <Grid container>
                <AddInventoryTransactionModal
                  closeModal={(modified) => {
                    toggleRefreshData();
                    setOpenModifyStockModal(false);
                  }}
                  type={transactionType}
                  transaction={inventoryTransaction}
                />
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
}
