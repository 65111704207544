// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Divider,
  Button,
  FormHelperText,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { formatDate } from "../../utils/formatDate";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { UserProfile } from "../../types/auth";
import { InventoryTransactionType, LocationType, SelectCompanyType } from "../../AllTypes";
// import * as Yup from "yup";
// import { useFormik } from "formik";
// import DatePicker from "@mui/lab/DatePicker";
// import { DatePicker } from "@mui/x-date-pickers";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import useTranslation from "../../hooks/useTranslation";

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

export default function InventoryTransactions() {
  const { user: currentUser } = useAuth();
  const [transactions, setTransactions] = useState<InventoryTransactionType>();
  const [loadingStock, setLoadingStock] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [selectedDateStart, setSelectedDateStart] = useState(dayjs());
  const [selectedDateEnd, setSelectedDateEnd] = useState(dayjs());
  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState(false);
  const { t } = useTranslation();

  // const formik = useFormik({
  //   initialValues: {},
  //   validationSchema: Yup.object().shape({
  //     companyId: Yup.string().required("Company is required"),
  //     locationId: Yup.string().required("Location is required"),
  //   }),
  //   enableReinitialize: false,
  //   onSubmit: (values, { setSubmitting }) => {},
  // });
  // const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, handleChange } = formik;

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // Set default company if user cannot modify it
    if (currentUser && !hasMinLevel(currentUser, "Admin")) {
      setSelectedCompanyId(currentUser?.companyId?.toString() || "");
    }

    // Set default location if user cannot modify it
    if (!hasMinLevel(currentUser, "CompanyAdmin")) {
      setSelectedLocationId(currentUser?.locationId?.toString() || "");
    }
  }, [currentUser]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    axiosServices
      .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
      .then((res) => {
        let locations = res.data;
        setLocations(locations);

        // Default select if there is only one location
        if (locations?.length === 1) {
          setSelectedLocationId(locations[0].id);
        }
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      });
  }, [selectedCompanyId]);

  // useEffect(() => {
  //   if (!selectedLocationId) {
  //     return;
  //   }
  // }, [selectedLocationId]);

  function performSearch() {
    setSearched(true);
    if (!selectedLocationId) {
      return;
    }

    setLoadingStock(true);
    let url = `/api/inventory/inventoryTransactions?locationId=${selectedLocationId}`;
    if (selectedDateStart) {
      url += `&startDate=${selectedDateStart.format("YYYY-MM-DD")}`;
    }
    if (selectedDateEnd) {
      url += `&endDate=${selectedDateEnd.format("YYYY-MM-DD")}`;
    }
    axiosServices
      .get(url)
      .then((res) => {
        setTransactions(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoadingStock(false);
      });
  }

  // TODO: move to helper class
  function hasMinLevel(user: UserProfile | null | undefined, userLevelName: string): Boolean {
    if (!user) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(user.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  let costCols: ColumnDef<InventoryTransactionType>[] = [];
  if (hasMinLevel(currentUser, "CompanyAdmin")) {
    costCols.push({
      header: "Cost (per Gal)",
      accessorKey: "costPerGal",
      meta: {
        className: "cell-center",
      },
      cell: ({ row }) => {
        if (Number.isFinite(row.original?.costPerGal)) {
          return <>${formatNo(row.original?.costPerGal || 0, 2, 2)}</>;
        } else {
          return "";
        }
      },
    });
  }

  const columns = useMemo<ColumnDef<InventoryTransactionType>[]>(
    () => [
      {
        header: t("inventory.name", "Name"),
        accessorKey: "name",
        dataType: "text",
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? originalRow.formulaName : originalRow.componentName;
        },
      },
      {
        header: t("inventory.itemType", "Item Type"),
        accessorKey: "formulaId",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        accessorFn: (originalRow) => {
          return originalRow.formulaId ? t("inventory.formula", "Formula") : t("inventory.component", "Component");
        },
      },
      {
        header: t("inventory.transactionType", "Transaction Type"),
        accessorKey: "typeDisplayName",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
      },
      {
        header: t("inventory.quantityInGal", "Quantity (Gal)"),
        accessorKey: "quantity",
        dataType: "amount",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return <>{formatNo(row.original?.quantity)}</>;
        },
      },
      ...costCols,
      {
        header: t("inventory.batchNumber", "Batch Number"),
        accessorKey: "batchNumber",
        dataType: "text",
      },
      {
        header: t("inventory.references", "References"),
        accessorKey: "references",
        dataType: "text",
      },
      {
        header: t("inventory.internalPartNumber", "Internal Part Number"),
        accessorKey: "internalPartNumber",
        dataType: "text",
      },
      {
        header: t("inventory.notes", "Notes"),
        accessorKey: "notes",
        dataType: "text",
      },
      {
        header: t("inventory.createdBy", "Created By"),
        accessorKey: "createdByFullName",
        dataType: "text",
      },
      {
        header: t("inventory.dateCreated", "Date Created"),
        accessorKey: "dateCreated",
        dataType: "date",
        cell: ({ row: { original } }) => {
          return formatDate(original?.dateCreated || "", "PPp");
        },
      },
    ],
    [t]
  );

  function CompanyInput() {
    return hasMinLevel(currentUser, "Admin") ? (
      <>
        <InputLabel htmlFor="companyId">{t("general.company", "Company")}</InputLabel>
        <Select id="companyId" fullWidth input={<OutlinedInput />} value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)}>
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {companies &&
            companies?.map((item, index) => (
              <MenuItem key={index} value={parseInt(item.value)}>
                {item.text}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="companyLabel">{t("general.company", "Company")}</InputLabel>
        <OutlinedInput fullWidth id="companyLabel" value={currentUser?.companyName} name="companyLabel" readOnly={true} />
      </>
    );
  }

  function LocationInput() {
    return hasMinLevel(currentUser, "CompanyAdmin") ? (
      <>
        <InputLabel htmlFor="locationId">{t("general.location", "Location")}</InputLabel>
        <Select
          id="locationId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedLocationId}
          onChange={(e) => setSelectedLocationId(e.target.value)}
        >
          {!locations && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          {locations &&
            locations?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </>
    ) : (
      <>
        <InputLabel htmlFor="locationLabel">{t("general.location", "Location")}</InputLabel>
        <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
      </>
    );
  }

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{t("inventory.inventoryTransactions", "Inventory Transactions")}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        <Grid item sm={6}>
          <CompanyInput></CompanyInput>
          {searched && !selectedCompanyId && <FormHelperText error>{t("inventory.selectCompany", "Select a company")}</FormHelperText>}
        </Grid>
        <Grid item sm={6}>
          <LocationInput></LocationInput>
          {searched && !selectedLocationId && <FormHelperText error>{t("inventory.selectLocation", "Select a location")}</FormHelperText>}
        </Grid>
        <Grid item sm={3}>
          <Stack direction="column" spacing={1}>
            <InputLabel htmlFor="dateStart">{t("general.from", "From")}:</InputLabel>
            <DatePicker value={selectedDateStart} onChange={(e) => setSelectedDateStart(e)}></DatePicker>
          </Stack>
        </Grid>
        <Grid item sm={3}>
          <Stack direction="column" spacing={1}>
            <InputLabel htmlFor="dateEnd">{t("general.to", "To")}:</InputLabel>
            <DatePicker value={selectedDateEnd} onChange={(e) => setSelectedDateEnd(e)}></DatePicker>
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" sx={{ pt: 3 }}>
            <Button onClick={performSearch} variant="contained">
              {t("general.search", "Search")}
            </Button>
          </Stack>
        </Grid>

        <Grid item sm={12} sx={{ py: 2 }}>
          <Divider></Divider>
        </Grid>

        <Grid item sm={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("general.search", "Search...")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        {transactions && (
          <Grid item sm={12}>
            <DisplayTable
              columns={columns}
              data={transactions}
              defaultSorting={[{ id: "dateCreated", desc: true }]}
              search={search}
              showAddBtn={true}
              getRowStyle={(row) => {
                console.log({ row });
                const backgroundColor =
                  row.original.typeName.toLowerCase() === "removal" ? "#FFDDDD" : row.original.typeName.toLowerCase() === "addition" ? "#DDFFDD" : "";
                return { backgroundColor };
              }}
            />
          </Grid>
        )}
      </Grid>
      {loadingStock && !transactions && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loadingStock && !transactions && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">
            {t("inventory.selectLocationToViewInventoryTransactions", "Select a location to view inventory transactions.")}
          </Typography>
        </Box>
      )}
    </>
  );
}
