import { useEffect, useState, Dispatch, SetStateAction } from "react";
import useTranslation from "../../hooks/useTranslation";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import { FormControlLabel, Stack, Tooltip, Typography, Switch, Select, MenuItem } from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { UnitOfMeasureType } from "../../AllTypes";
import { alertMessage } from "../pages-helpers/AlertMessage";
import axiosServices from "../../utils/axios";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  editingData: UnitOfMeasureType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddUnitOfMeasureModal({ isEditing, setIsEditing, editingData, toggleRefreshData, closeModal }: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);

  useEffect(() => {
    setLoading(false);
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("unitOfMeasure.nameRequired", "Name is required")),
    gallonsEquivalence: Yup.number().nullable().typeError(t("unitOfMeasure.gallonsEquivalenceNumber", "Gallons Equivalence must be a number")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const initialValues = {
    id: tempIsEditing && editingData ? editingData.id : 0,
    name: tempIsEditing && editingData ? editingData.name : "",
    gallonsEquivalence: tempIsEditing && editingData ? editingData.gallonsEquivalence : null,
    isActive: tempIsEditing && editingData ? editingData.isActive : true,
  };

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ItemSchema}
      enableReinitialize={false}
      onSubmit={async (values: UnitOfMeasureType, { setSubmitting }) => {
        try {
          // values.isActive = (values.isActive === 'true'||values.isActive === true) ? true : false;
          axiosServices
            .post("/api/unitOfMeasure", values)
            .then(() => {
              alertMessage("Unit of Measure updated successfully", "success");
              handleAlertClose();
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        } catch (error) {
          alertMessage(`Something went wrong`, "error");
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, touched, handleSubmit, isSubmitting, getFieldProps, values }) => (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <DialogTitle>
              <Typography variant="h4" component="span">
                {tempIsEditing ? t("unitOfMeasure.editTitle", "Edit Unit of Measure") : t("unitOfMeasure.addTitle", "Add Unit of Measure")}
              </Typography>
            </DialogTitle>
            <Tooltip title={t("general.close", "Close")}>
              <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
                <CloseOutlined />
              </IconButton>
            </Tooltip>
          </Stack>
          <Divider />
          <DialogContent sx={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">{t("unitOfMeasure.name", "Name")}</InputLabel>
                      <TextField
                        fullWidth
                        id="name"
                        placeholder={t("unitOfMeasure.namePlaceholder", "Enter name")}
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="gallonsEquivalence">{t("unitOfMeasure.gallonsEquivalence", "Gallons Equivalence")}</InputLabel>
                      <TextField
                        fullWidth
                        type="number"
                        id="gallonsEquivalence"
                        placeholder={t("unitOfMeasure.gallonsEquivalencePlaceholder", "Enter gallons equivalence")}
                        {...getFieldProps("gallonsEquivalence")}
                        error={Boolean(touched.gallonsEquivalence && errors.gallonsEquivalence)}
                        helperText={touched.gallonsEquivalence && errors.gallonsEquivalence}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" alignItems="center" spacing={3}>
                      <FormControlLabel
                        control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />}
                        label={t("general.active", "Active")}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2.5 }}>
            <Button color="secondary" onClick={closeModal}>
              {t("general.cancel", "Cancel")}
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("general.save", "Save")}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}
