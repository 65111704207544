import { useEffect, useState, Dispatch, SetStateAction } from "react";

// material-ui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { Form, Formik, FormikProvider, useFormik } from "formik";

// project imports
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";

// types
import {
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  Switch,
  Select,
  Chip,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { CloseOutlined } from "@ant-design/icons";
import { IconButton } from "@mui/material";
import { EndpointsType, ComponentType, UnitOfMeasureType, ComponentCategoryType, ComponentSubcategoryType } from "../../AllTypes";
import { insert, update } from "../../api/generalRoute";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { ListItemText } from "@mui/material";
import axiosServices from "../../utils/axios";
import useTranslation from "../../hooks/useTranslation";

interface Props {
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  componentId: number;
  endpoints: EndpointsType;
  toggleRefreshData: () => void;
  closeModal: () => void;
}

export default function AddComponentModal({ isEditing, setIsEditing, componentId, endpoints, toggleRefreshData, closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [tempIsEditing, setTempIsEditing] = useState<boolean>(isEditing ? true : false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [categories, setCategories] = useState<ComponentCategoryType[]>([]);
  const [componentSubcategories, setComponentSubcategories] = useState<ComponentSubcategoryType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);

    if (componentId) {
      axiosServices
        .get(`/api/component/${componentId}`)
        .then((res) => {
          let values = res.data;
          values.unitsOfMeasure = values.unitsOfMeasure.map((unit: any) => unit.unitOfMeasureId);

          setValues(values);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error", err);
        });
    }

    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });

    axiosServices
      .get("/api/componentCategory")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });

    axiosServices
      .get("/api/componentSubcategory")
      .then((res) => {
        setComponentSubcategories(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("ink.productCodeRequired", "Product Code is required")),
    density: Yup.number()
      .positive(t("ink.densityPositive", "Density must be a positive number."))
      .required(t("ink.densityRequired", "Density is required")),
  });

  const handleAlertClose = () => {
    setIsEditing(false);
    setTempIsEditing(false);
    toggleRefreshData();
    closeModal();
  };

  const initialValues = {
    name: "",
    description: "",
    categoryId: null,
    subcategoryId: null,
    defaultUnitOfMeasureId: null,
    density: 0,
    isActive: true,
    unitsOfMeasure: [] as number[],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        let component = {
          ...values,
          unitsOfMeasure: Array.from(new Set(values.unitsOfMeasure)).map((id) => ({ unitOfMeasureId: id })),
        };

        if (values.defaultUnitOfMeasureId === 0) {
          component.defaultUnitOfMeasureId = null;
        }

        if (values.categoryId === 0) {
          component.categoryId = null;
        }
        if (values.subcategoryId === 0) {
          component.subcategoryId = null;
        }

        // values.isActive = (values.isActive === 'true'||values.isActive === true) ? true : false;
        if (tempIsEditing && componentId) {
          update(endpoints, componentId.toString(), component)
            .then(() => {
              alertMessage(t("ink.updateSuccess", "Component updated successfully"), "success");
              handleAlertClose();
            })
            .catch((err) => {
              alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
              setSubmitting(false);
            });
        } else {
          insert(endpoints, component)
            .then(() => {
              alertMessage(t("ink.addSuccess", "Component added successfully"), "success");
              handleAlertClose();
              setSubmitting(false);
            })
            .catch((err) => {
              alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setValues } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
          <DialogTitle>
            <Typography variant="h4" component="span">
              {tempIsEditing ? t("ink.editComponent", "Edit Component") : t("ink.addComponent", "Add Component")}
            </Typography>
          </DialogTitle>
          <Tooltip title={t("general.close", "Close")}>
            <IconButton color="inherit" name="closeModal" aria-label="close modal" onClick={closeModal} edge="start">
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
        <Divider />
        <DialogContent sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Product Code</InputLabel>
                    <TextField
                      fullWidth
                      id="name"
                      placeholder="Product Code"
                      {...getFieldProps("name")}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="density">Density</InputLabel>
                    <OutlinedInput
                      fullWidth
                      id="density"
                      placeholder="Density"
                      {...getFieldProps("density")}
                      type="number"
                      endAdornment={<InputAdornment position="end">lb/gal</InputAdornment>}
                      error={Boolean(touched.density && errors.density)}
                      // helperText={touched.density && errors.density}
                    />
                    {touched?.density && <FormHelperText error>{errors.density}</FormHelperText>}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="description">Description</InputLabel>
                    <TextField
                      fullWidth
                      id="description"
                      placeholder="Description"
                      {...getFieldProps("description")}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="category">{t("ink.Category", "Category")}</InputLabel>
                    <Select fullWidth id="category" placeholder={t("ink.Category", "Category")} {...getFieldProps("categoryId")}>
                      <MenuItem key="-1" value={0}>
                        {t("general.none", "None")}
                      </MenuItem>
                      {categories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="subcategory">{t("ink.Subcategory", "Subcategory")}</InputLabel>
                    <Select fullWidth id="subcategory" placeholder={t("ink.Subcategory", "Subcategory")} {...getFieldProps("subcategoryId")}>
                      <MenuItem key="-1" value={0}>
                        {t("general.none", "None")}
                      </MenuItem>
                      {componentSubcategories.map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.id}>
                          {subcategory.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="unitsOfMeasure">Units of Measure</InputLabel>
                    <Select
                      fullWidth
                      id="unitsOfMeasure"
                      multiple
                      placeholder="Unit of Measure (default)"
                      {...getFieldProps("unitsOfMeasure")}
                      value={values.unitsOfMeasure}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {unitsOfMeasure
                            .filter((unit) => selected.indexOf(unit.id) > -1)
                            .map((unit) => (
                              <Chip key={unit.id} label={unit.name} />
                            ))}
                        </Box>
                      )}
                    >
                      {/* <MenuItem key="-1" value={0}>
                        None
                      </MenuItem> */}
                      {unitsOfMeasure.map((unit) => (
                        // <MenuItem key={unit.id} value={unit.id}>
                        //   {unit.name}
                        // </MenuItem>
                        <MenuItem key={unit.id} value={unit.id}>
                          <Checkbox checked={values.unitsOfMeasure.indexOf(unit.id) > -1} />
                          <ListItemText primary={unit.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="unitOfMeasure">Unit of Measure (Default)</InputLabel>
                    <Select
                      fullWidth
                      id="defaultUnitOfMeasureId"
                      placeholder="Unit of Measure (default)"
                      {...getFieldProps("defaultUnitOfMeasureId")}
                      // error={Boolean(touched.defaultUnitOfMeasureId && errors.defaultUnitOfMeasureId)}
                      // helperText={touched.defaultUnitOfMeasureId && errors.defaultUnitOfMeasureId}
                      // onChange={handleDefaultUnitOfMeasureChange}
                    >
                      <MenuItem key="-1" value={0}>
                        None
                      </MenuItem>
                      {unitsOfMeasure.map((unit) => (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <FormControlLabel control={<Switch {...getFieldProps("isActive")} checked={values.isActive} />} label="Active" />
                    {/* <InputLabel>Status :</InputLabel>
                                          <RadioGroup {...getFieldProps('isActive')} aria-label="type" name="isActive" row>
                                              <Box>
                                                  <FormControlLabel value='true' control={<Radio />} label="Active" />
                                                  <FormControlLabel value='false' control={<Radio />} label="Inactive" />
                                              </Box>
                                          </RadioGroup> */}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <Divider /> */}
        <DialogActions sx={{ p: 2.5 }}>
          {/* <Grid item> */}
          {/* <Stack direction="row"> */}
          <Button color="secondary" onClick={closeModal}>
            {t("general.cancel", "Cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("general.save", "Save")}
          </Button>
          {/* </Stack> */}
          {/* </Grid> */}
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
